import './App.css';
import Container from 'react-bootstrap/Container';
import { Outlet } from 'react-router-dom';
import GlobalContainer from './components/GlobalContainer';

function App() {
  return (
    <GlobalContainer data-testid="global-container">
      <Container className="pt-5 pb-5 rounded-3 bg-paper border-celeste-primary">
        <Outlet />
      </Container>
    </GlobalContainer>
  );
}

export default App;
