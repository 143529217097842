export class ConfigHelper {
  clerk_publishable_key: string | undefined;

  constructor() {
    this.clerk_publishable_key = process.env.REACT_APP_CLERK_PUBLISHABLE_KEY;
  }

  public getClerkPublicKey(): string {
    return this.clerk_publishable_key!;
  }
}