export function DashboardIcon(): JSX.Element {
    return (
        <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 8.5H8V0.5H0V8.5ZM2 2.5H6V6.5H2V2.5Z" fill="currentColor" />
            <path d="M10 0.5V8.5H18V0.5H10ZM16 6.5H12V2.5H16V6.5Z" fill="currentColor" />
            <path d="M0 18.5H8V10.5H0V18.5ZM2 12.5H6V16.5H2V12.5Z" fill="currentColor" />
            <path d="M15 10.5H13V13.5H10V15.5H13V18.5H15V15.5H18V13.5H15V10.5Z" fill="currentColor" />
        </svg>
    );
}

export function PatientsIcon(): JSX.Element {
    return (
        <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16.6667 0.125H2.08333C0.9375 0.125 0.0104167 1.0625 0.0104167 2.20833L0 16.7917C0 17.9375 0.9375 18.875 2.08333 18.875H16.6667C17.8125 18.875 18.75 17.9375 18.75 16.7917V2.20833C18.75 1.0625 17.8125 0.125 16.6667 0.125ZM16.6667 16.7917H2.08333V2.20833H16.6667V16.7917ZM7.8125 14.7083H10.9375V11.0625H14.5833V7.9375H10.9375V4.29167H7.8125V7.9375H4.16667V11.0625H7.8125V14.7083Z" fill="currentColor" />
        </svg>
    );
}

export function ProfileIcon(): JSX.Element {
    return (
        <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9 2.6375C10.305 2.6375 11.3625 3.695 11.3625 5C11.3625 6.305 10.305 7.3625 9 7.3625C7.695 7.3625 6.6375 6.305 6.6375 5C6.6375 3.695 7.695 2.6375 9 2.6375ZM9 12.7625C12.3413 12.7625 15.8625 14.405 15.8625 15.125V16.3625H2.1375V15.125C2.1375 14.405 5.65875 12.7625 9 12.7625ZM9 0.5C6.51375 0.5 4.5 2.51375 4.5 5C4.5 7.48625 6.51375 9.5 9 9.5C11.4862 9.5 13.5 7.48625 13.5 5C13.5 2.51375 11.4862 0.5 9 0.5ZM9 10.625C5.99625 10.625 0 12.1325 0 15.125V18.5H18V15.125C18 12.1325 12.0037 10.625 9 10.625Z" fill="currentColor" />
        </svg>
    );
}

export function PlusIcon({ classNames }: { classNames?: string[] }): JSX.Element {
    let className = "";
    if (classNames) {
        className = classNames.join(' ');
    }
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
            <path d="M17.3735 8.8097H11.1692V2.60542C11.1692 2.29249 11.0449 1.99239 10.8236 1.77112C10.6024 1.54985 10.3023 1.42554 9.98934 1.42554C9.67641 1.42554 9.37631 1.54985 9.15504 1.77112C8.93377 1.99239 8.80946 2.29249 8.80946 2.60542V8.8097H2.60517C2.29225 8.8097 1.99214 8.93401 1.77087 9.15528C1.5496 9.37655 1.42529 9.67666 1.42529 9.98958C1.42529 10.3025 1.5496 10.6026 1.77087 10.8239C1.99214 11.0452 2.29225 11.1695 2.60517 11.1695H8.80946V17.3737C8.80946 17.6867 8.93377 17.9868 9.15504 18.208C9.37631 18.4293 9.67641 18.5536 9.98934 18.5536C10.3023 18.5536 10.6024 18.4293 10.8236 18.208C11.0449 17.9868 11.1692 17.6867 11.1692 17.3737V11.1695H17.3735C17.6864 11.1695 17.9865 11.0452 18.2078 10.8239C18.4291 10.6026 18.5534 10.3025 18.5534 9.98958C18.5534 9.67666 18.4291 9.37655 18.2078 9.15528C17.9865 8.93401 17.6864 8.8097 17.3735 8.8097Z" fill="currentColor" stroke="white" stroke-width="0.25" />
        </svg>
    );
}

export default {}