import { SignUp, useUser } from "@clerk/clerk-react";
import { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import GlobalContainer from "../components/GlobalContainer";

export default function SignUpPage(): JSX.Element {
  const { isSignedIn } = useUser();
  const navigate = useNavigate();

  useEffect(() => {
    if (isSignedIn) {
      navigate("/d");
    }
  }, [isSignedIn, navigate]);


  return (
    <GlobalContainer>
      <Row>
        <Col className='d-inline-flex justify-content-center mt-4'>
          {!isSignedIn && (
            <SignUp
              path="/signup"
              afterSignUpUrl="/after_signup"
              afterSignInUrl="/d"
            />
          )}
        </Col>
      </Row>
    </GlobalContainer>
  );
}