import { Container, Row } from "react-bootstrap";
import { useRouteError } from "react-router-dom";

type BadError = {
  statusText: string,
  message: string,
}

export default function ErrorPage() {
  const error = useRouteError() as BadError;
  const showErrors = process.env.NODE_ENV === 'development';

  return (
    <Container id="error-page">
      <Row className="p-4">
        <h1>Oops!</h1>
        <p>Sorry, an unexpected error has occurred.</p>
        {showErrors && (
          <p>
            <i>{error.statusText || error.message}</i>
          </p>
        )}
      </Row>
    </Container>
  );
}