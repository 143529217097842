import { PropsWithChildren, useState } from "react";
import { Alert, Button, Col, Container, Form, Row } from "react-bootstrap";
import { useFetch } from "../../helpers/clerk";
import { getBackendUrl } from "../../utils";
import PatientCheckboxInput from "./PatientCheckboxInput";
import PatientSelectInput from "./PatientSelectInput";
import PatientTextInput from "./PatientTextInput";
import PatientMultiCheckboxInput from "./PatientMultiCheckboxInput";
import { FormLists } from "../../helpers/datasets/provider/intake/support/form_lists";
import PatientStringListInput from "./PatientStringListInput";
import { basePatientInformationStateObject, basePatientStateObject } from "../../helpers/datasets/provider/patient_forms/state_manager_helpers";
import { useNavigate } from "react-router-dom";
import { InfoSquare } from "react-bootstrap-icons";

type ColFieldsType = {
    patient?: any,
    patient_information: any,
    valueChangeHandlers: {
        handlePatientEmailValueChange: any,
        handleTextValueChange: any,
        handleBooleanValueChange: any,
        handleNumberValueChange: any,
        handleCheckboxListValueChange: any,
        handleStringListValueChange: any,
        handleSelectValueChange: any,
    },
};

type CategoryWithInformationProps = {
    category: string,
    classNames?: string[]
}

function CategoryWithInformation(props: PropsWithChildren<CategoryWithInformationProps>) {
    let className = ["category"];
    if (props.classNames) {
        className = [...className, ...props.classNames];
    }

    return (
        <Container className={className.join(' ')}>
            <Row>
                <Col className="d-flex justify-content-center align-items-center category-name col-3">
                    {props.category}
                </Col>
                <Col className="col-6">
                    {props.children}
                </Col>
            </Row>
        </Container>
    );
}

const LeftColFields = ({ patient, patient_information, valueChangeHandlers }: ColFieldsType) => {
    const handlePatientEmailValueChange = valueChangeHandlers['handlePatientEmailValueChange'];
    const handleTextValueChange = valueChangeHandlers['handleTextValueChange'];
    const handleBooleanValueChange = valueChangeHandlers['handleBooleanValueChange'];
    const handleNumberValueChange = valueChangeHandlers['handleNumberValueChange'];
    const handleCheckboxListValueChange = valueChangeHandlers['handleCheckboxListValueChange'];
    const handleStringListValueChange = valueChangeHandlers['handleStringListValueChange'];
    const handleSelectValueChange = valueChangeHandlers['handleSelectValueChange'];

    return (
        <>
            <CategoryWithInformation
                category="Personal Information"
            >
                <PatientTextInput
                    label="Name"
                    name="name"
                    value={patient_information["name"]}
                    handleValueChange={handleTextValueChange}
                    required
                />
                <PatientTextInput
                    label="Email"
                    name="email"
                    value={patient["email"]}
                    type="email"
                    placeholder="eg. foo@example.com"
                    handleValueChange={handlePatientEmailValueChange}
                    required
                />
                <PatientSelectInput
                    label="Gender"
                    name="gender"
                    handleValueChange={handleSelectValueChange}
                    value={patient_information["gender"]}
                    required
                >
                    <option value="female">Female</option>
                    <option value="male">Male</option>
                    <option value="nonbinary">Nonbinary</option>
                </PatientSelectInput>
                <PatientSelectInput
                    label="Sex"
                    name="sex"
                    handleValueChange={handleSelectValueChange}
                    value={patient_information["sex"]}
                    required
                >
                    <option value="female">Female</option>
                    <option value="male">Male</option>
                    <option value="intersex">Intersex</option>
                </PatientSelectInput>
                <PatientTextInput
                    label="Date of birth"
                    name="date_of_birth"
                    value={patient_information["date_of_birth"]}
                    handleValueChange={handleTextValueChange}
                    required
                    type="date"
                />
                <PatientTextInput
                    label="Ethnicity"
                    name="ethnicity"
                    value={patient_information["ethnicity"]}
                    handleValueChange={handleTextValueChange}
                    required
                />
                <PatientTextInput
                    label="Height in inches"
                    name="height"
                    type="number"
                    value={patient_information["height"]}
                    handleValueChange={handleNumberValueChange}
                    required
                />
                <PatientTextInput
                    label="Weight in lbs"
                    name="weight"
                    type="number"
                    value={patient_information["weight"]}
                    handleValueChange={handleNumberValueChange}
                    required
                />
                <PatientTextInput
                    label="Age of first menstruation (0-18)"
                    name="first_menstruation"
                    type="number"
                    value={patient_information["first_menstruation"]}
                    handleValueChange={handleNumberValueChange}
                    required
                />
                <PatientCheckboxInput
                    label="Is the patient currently or have ever been sexually active?"
                    name="sexual_activity"
                    value={patient_information["sexual_activity"]}
                    handleValueChange={handleBooleanValueChange}
                />
            </CategoryWithInformation>

            <CategoryWithInformation
                category="Social History"
            >
                <PatientSelectInput
                    label="Select the patient's level of physical activity."
                    name="lifestyle_activity"
                    handleValueChange={handleSelectValueChange}
                    value={patient_information["lifestyle_activity"]}
                    required
                >
                    <option value="sedentary">Sedentary</option>
                    <option value="gentle">Gentle</option>
                    <option value="moderate">Moderate</option>
                    <option value="vigorous">Vigorous</option>
                </PatientSelectInput>
                <PatientSelectInput
                    label="Select what best describes the patient's typical diet."
                    name="lifestyle_diet"
                    handleValueChange={handleSelectValueChange}
                    value={patient_information["lifestyle_diet"]}
                    required
                >
                    <option value="vegan">Vegan</option>
                    <option value="vegetarian">Vegetarian</option>
                    <option value="pescatarian">Pescatarian</option>
                    <option value="omnivore">Omnivore</option>
                </PatientSelectInput>
                <PatientCheckboxInput
                    label="Does the patient use nicotine products? (Vape, cigarette, nicotine patch/gum, etc.)"
                    name="lifestyle_smoking"
                    value={patient_information["lifestyle_smoking"]}
                    handleValueChange={handleBooleanValueChange}
                />
                {patient_information["lifestyle_smoking"] && (
                    <div className={"follow-up-subsection mt-2"}>
                        <div className={"heading"}>Follow up: Smoking</div>
                        <PatientMultiCheckboxInput
                            label="What types of nicotine products does the patient use?"
                            name="lifestyle_smoking_follow_up:products"
                            handleValueChange={handleCheckboxListValueChange}
                            value={patient_information["lifestyle_smoking_follow_up:products"]}
                            choices={FormLists.SmokingProductsEnumList}
                        />
                    </div>
                )}
                {patient_information["lifestyle_smoking_follow_up:products"]
                    && patient_information["lifestyle_smoking_follow_up:products"].includes('cigarette')
                    && (
                        <div className={"follow-up-subsection mt-2"}>
                            <div className={"heading"}>Follow up: Cigarettes</div>
                            <PatientSelectInput
                                label="How many cigarettes do you smoke in one day? Approximately 15 puffs of a vape equals one cigarette."
                                name="lifestyle_smoking_products_cigarettes_follow_up:cigarettes_per_day"
                                handleValueChange={handleSelectValueChange}
                                value={patient_information["lifestyle_smoking_products_cigarettes_follow_up:cigarettes_per_day"]}
                                required
                            >
                                <option value="gt_15_per_day">Greater than 15 per day</option>
                                <option value="lte_15_per_day">Less than or equal to 15 per day</option>
                            </PatientSelectInput>
                        </div>
                    )}
                <PatientMultiCheckboxInput
                    label="Select any of the drugs that the patient takes recreationally."
                    name="rec_drugs_list"
                    handleValueChange={handleCheckboxListValueChange}
                    value={patient_information["rec_drugs_list"]}
                    choices={FormLists.RecreationalDrugsEnumList}
                />
            </CategoryWithInformation>

            <CategoryWithInformation
                category="Pregnancy"
            >
                <PatientCheckboxInput
                    label="Has the patient ever been pregnant?"
                    name="pregnant"
                    value={patient_information["pregnant"]}
                    handleValueChange={handleBooleanValueChange}
                />
                {patient_information["pregnant"] && (
                    <>
                        <PatientCheckboxInput
                            label="Is the patient currently breastfeeding?"
                            name="breastfeeding"
                            value={patient_information["breastfeeding"]}
                            handleValueChange={handleBooleanValueChange}
                        />
                        <PatientTextInput
                            label="If the patient has given birth within the last 6 months, how many days postpartum are they?"
                            name="days_post_partum"
                            type="number"
                            value={patient_information["days_post_partum"]}
                            handleValueChange={handleNumberValueChange}
                            required
                        />
                    </>
                )}
                {patient_information["pregnant"] && (
                    <PatientCheckboxInput
                        label="During any of the patient's pregnancies, did they have high blood pressure?"
                        name="high_bp_pregnancy"
                        value={patient_information["high_bp_pregnancy"]}
                        handleValueChange={handleBooleanValueChange}
                    />
                )}
                <PatientCheckboxInput
                    label="Did the patient have a c-section?"
                    name="c_section"
                    value={patient_information["c_section"]}
                    handleValueChange={handleBooleanValueChange}
                />
                <PatientCheckboxInput
                    label="Is the patient planning on getting pregnant again soon?"
                    name="planning_pregnancy"
                    value={patient_information["planning_pregnancy"]}
                    handleValueChange={handleBooleanValueChange}
                />

                <PatientCheckboxInput
                    label="Has the patient ever had an abortion?"
                    name="previous_abortion"
                    value={patient_information["previous_abortion"]}
                    handleValueChange={handleBooleanValueChange}
                />
                {patient_information['previous_abortion'] && (
                    <>
                        <PatientSelectInput
                            label="In which trimester did the abortion occur?"
                            name="abortion_trimester"
                            handleValueChange={handleSelectValueChange}
                            value={patient_information["abortion_trimester"]}
                            required
                        >
                            <option value="first">First</option>
                            <option value="second">Second</option>
                            <option value="third">Third</option>
                        </PatientSelectInput>
                        <PatientCheckboxInput
                            label="Did the patient experience any symptoms of septic abortion?"
                            name="symptoms_of_septic_abortion"
                            value={patient_information["symptoms_of_septic_abortion"]}
                            handleValueChange={handleBooleanValueChange}
                        />
                    </>
                )}
            </CategoryWithInformation>

            <CategoryWithInformation
                category="History with Birth Control"
            >
                <PatientCheckboxInput
                    label="Has the patient used any form of birth control before?"
                    name="previous_birth_control"
                    value={patient_information["previous_birth_control"]}
                    handleValueChange={handleBooleanValueChange}
                />
                {patient_information["previous_birth_control"] && (
                    <>
                        <PatientMultiCheckboxInput
                            label="Which birth control methods have the patient used?"
                            name="previous_birth_control_methods"
                            handleValueChange={handleCheckboxListValueChange}
                            value={patient_information["previous_birth_control_methods"]}
                            choices={FormLists.BirthControlTypesList}
                        />
                        <PatientTextInput
                            label="How many different types of birth control has the patient tried?"
                            name="previous_birth_control_details"
                            type="number"
                            value={patient_information["previous_birth_control_details"]}
                            handleValueChange={handleNumberValueChange}
                            required
                        />
                    </>
                )}

                <PatientMultiCheckboxInput
                    label="Select any concerns the patient has about birth control"
                    name="birth_control_goals"
                    handleValueChange={handleCheckboxListValueChange}
                    value={patient_information["birth_control_goals"]}
                    choices={FormLists.BirthControlGoalsEnumList}
                />
                {patient_information["birth_control_goals"]
                    && patient_information["birth_control_goals"].includes('menstrual_cramps')
                    && (
                        <>
                            <PatientTextInput
                                label="On a scale of 1 to 10, how severe are the patient's menstrual cramps?"
                                name="menstrual_cramps_severity"
                                type="number"
                                value={patient_information["menstrual_cramps_severity"]}
                                handleValueChange={handleNumberValueChange}
                                required
                            />
                            <PatientCheckboxInput
                                label="Has the patient tried any methods or treatments to alleviate menstrual cramps in the past?"
                                name="menstrual_cramps_past_treatments"
                                value={patient_information["menstrual_cramps_past_treatments"]}
                                handleValueChange={handleBooleanValueChange}
                            />
                            <PatientTextInput
                                label="Are there any specific concerns or challenges the patient faces in managing menstrual cramps?"
                                name="menstrual_cramps_challenges"
                                value={patient_information["menstrual_cramps_challenges"]}
                                handleValueChange={handleTextValueChange}
                                required
                            />
                        </>
                    )}
                {patient_information["birth_control_goals"]
                    && patient_information["birth_control_goals"].includes('acne')
                    && (
                        <>
                            <PatientTextInput
                                label="On a scale of 1 to 10, how severe is the patient's acne?"
                                name="acne_severity"
                                type="number"
                                value={patient_information["acne_severity"]}
                                handleValueChange={handleNumberValueChange}
                                required
                            />
                            <PatientCheckboxInput
                                label="Has the patient tried any acne treatments in the past?"
                                name="acne_past_treatments"
                                value={patient_information["acne_past_treatments"]}
                                handleValueChange={handleBooleanValueChange}
                            />
                            <PatientTextInput
                                label="What specific concerns or challenges are the patient facing in managing acne?"
                                name="acne_challenges"
                                value={patient_information["acne_challenges"]}
                                handleValueChange={handleTextValueChange}
                                required
                            />
                        </>
                    )}
                {patient_information["birth_control_goals"]
                    && patient_information["birth_control_goals"].includes('hormonal_imbalance')
                    && (
                        <>
                            <PatientCheckboxInput
                                label="Has the patient been diagnosed with any hormonal imbalance condition?"
                                name="hormonal_imbalance_condition"
                                value={patient_information["hormonal_imbalance_condition"]}
                                handleValueChange={handleBooleanValueChange}
                            />
                            <PatientCheckboxInput
                                label="Is the patient currently receiving treatment to regulate your hormone levels?"
                                name="hormonal_imbalance_treatment"
                                value={patient_information["hormonal_imbalance_treatment"]}
                                handleValueChange={handleBooleanValueChange}
                            />
                            <PatientMultiCheckboxInput
                                label="Select any concerns or challenges the patient faces in managing hormonal imbalance."
                                name="hormonal_imbalance_challenges"
                                handleValueChange={handleCheckboxListValueChange}
                                value={patient_information["hormonal_imbalance_challenges"]}
                                choices={FormLists.HormonalImbalanceChallengesEnumList}
                            />
                        </>
                    )}

                {patient_information["birth_control_goals"]
                    && patient_information["birth_control_goals"].includes('irregular_periods')
                    && (
                        <>
                            <PatientSelectInput
                                label="Irregular periods severity"
                                name="irregular_periods_severity"
                                handleValueChange={handleSelectValueChange}
                                value={patient_information["irregular_periods_severity"]}
                                required
                            >
                                <option value="regular">Usually regular</option>
                                <option value="off_few_days">Sometimes off by few days</option>
                                <option value="off_few_weeks">Can be off by weeks</option>
                                <option value="completely_unpredictable">Completely unpredictable</option>
                            </PatientSelectInput>
                            <PatientCheckboxInput
                                label="Has the patient consulted a healthcare provider for irregular periods?"
                                name="irregular_periods_consultation"
                                value={patient_information["irregular_periods_consultation"]}
                                handleValueChange={handleBooleanValueChange}
                            />
                            <PatientTextInput
                                label="Are there any specific concerns or challenges the patient faces in managing irregular periods?"
                                name="irregular_periods_challenges"
                                value={patient_information["irregular_periods_challenges"]}
                                handleValueChange={handleTextValueChange}
                                required
                            />
                        </>
                    )}

                <PatientSelectInput
                    label="Select the patient's preferences for birth control."
                    name="birth_control_preferences"
                    handleValueChange={handleSelectValueChange}
                    value={patient_information["birth_control_preferences"]}
                    required
                >
                    <option value="low_maintenance">Low maintenance</option>
                    <option value="daily_regimen">Daily regimen</option>
                    <option value="no_preferences">No preferences</option>
                </PatientSelectInput>

                {patient_information["previous_birth_control_methods"]
                    && patient_information["previous_birth_control_methods"].includes('pill') &&
                    (
                        <div className={"follow-up-subsection mt-2"}>
                            <div className={"heading"}>Pill Usage Information (Enter up to 5)</div>
                            <div className={"subheading-2 mt-2"}>Entry #0</div>
                            <PatientSelectInput
                                label="Start date"
                                name="pill_usage_information:0:brand"
                                handleValueChange={handleSelectValueChange}
                                value={patient_information["pill_usage_information:0:brand"]}
                                required={false}
                                choices={FormLists.PillBrandsEnumList}
                            >
                            </PatientSelectInput>
                            <PatientTextInput
                                label="Start date"
                                name="pill_usage_information:0:start_date"
                                value={patient_information["pill_usage_information:0:start_date"]}
                                handleValueChange={handleTextValueChange}
                                required={false}
                                type="date"
                            />
                            <PatientTextInput
                                label="Stop date (Optional)"
                                name="pill_usage_information:0:stop_date"
                                value={patient_information["pill_usage_information:0:stop_date"]}
                                handleValueChange={handleTextValueChange}
                                required={false}
                                type="date"
                            />
                            <PatientTextInput
                                label="Reason for stopping. (Optional)"
                                name="pill_usage_information:0:stop_reason"
                                value={patient_information["pill_usage_information:0:stop_reason"]}
                                handleValueChange={handleTextValueChange}
                                required={false}
                            />
                            <div className={"subheading-2 mt-2"}>Entry #1</div>
                            <PatientSelectInput
                                label="Start date"
                                name="pill_usage_information:1:brand"
                                handleValueChange={handleSelectValueChange}
                                value={patient_information["pill_usage_information:1:brand"]}
                                required={false}
                                choices={FormLists.PillBrandsEnumList}
                            >
                            </PatientSelectInput>
                            <PatientTextInput
                                label="Start date"
                                name="pill_usage_information:1:start_date"
                                value={patient_information["pill_usage_information:1:start_date"]}
                                handleValueChange={handleTextValueChange}
                                required={false}
                                type="date"
                            />
                            <PatientTextInput
                                label="Stop date (Optional)"
                                name="pill_usage_information:1:stop_date"
                                value={patient_information["pill_usage_information:1:stop_date"]}
                                handleValueChange={handleTextValueChange}
                                required={false}
                                type="date"
                            />
                            <PatientTextInput
                                label="Reason for stopping. (Optional)"
                                name="pill_usage_information:1:stop_reason"
                                value={patient_information["pill_usage_information:1:stop_reason"]}
                                handleValueChange={handleTextValueChange}
                                required={false}
                            />
                            <div className={"subheading-2 mt-2"}>Entry #2</div>
                            <PatientSelectInput
                                label="Start date"
                                name="pill_usage_information:2:brand"
                                handleValueChange={handleSelectValueChange}
                                value={patient_information["pill_usage_information:2:brand"]}
                                required={false}
                                choices={FormLists.PillBrandsEnumList}
                            >
                            </PatientSelectInput>
                            <PatientTextInput
                                label="Start date"
                                name="pill_usage_information:2:start_date"
                                value={patient_information["pill_usage_information:2:start_date"]}
                                handleValueChange={handleTextValueChange}
                                required={false}
                                type="date"
                            />
                            <PatientTextInput
                                label="Stop date (Optional)"
                                name="pill_usage_information:2:stop_date"
                                value={patient_information["pill_usage_information:2:stop_date"]}
                                handleValueChange={handleTextValueChange}
                                required={false}
                                type="date"
                            />
                            <PatientTextInput
                                label="Reason for stopping. (Optional)"
                                name="pill_usage_information:2:stop_reason"
                                value={patient_information["pill_usage_information:2:stop_reason"]}
                                handleValueChange={handleTextValueChange}
                                required={false}
                            />
                            <div className={"subheading-2 mt-2"}>Entry #3</div>
                            <PatientSelectInput
                                label="Start date"
                                name="pill_usage_information:3:brand"
                                handleValueChange={handleSelectValueChange}
                                value={patient_information["pill_usage_information:3:brand"]}
                                required={false}
                                choices={FormLists.PillBrandsEnumList}
                            >
                            </PatientSelectInput>
                            <PatientTextInput
                                label="Start date"
                                name="pill_usage_information:3:start_date"
                                value={patient_information["pill_usage_information:3:start_date"]}
                                handleValueChange={handleTextValueChange}
                                required={false}
                                type="date"
                            />
                            <PatientTextInput
                                label="Stop date (Optional)"
                                name="pill_usage_information:3:stop_date"
                                value={patient_information["pill_usage_information:3:stop_date"]}
                                handleValueChange={handleTextValueChange}
                                required={false}
                                type="date"
                            />
                            <PatientTextInput
                                label="Reason for stopping. (Optional)"
                                name="pill_usage_information:3:stop_reason"
                                value={patient_information["pill_usage_information:3:stop_reason"]}
                                handleValueChange={handleTextValueChange}
                                required={false}
                            />
                            <div className={"subheading-2 mt-2"}>Entry #4</div>
                            <PatientSelectInput
                                label="Start date"
                                name="pill_usage_information:4:brand"
                                handleValueChange={handleSelectValueChange}
                                value={patient_information["pill_usage_information:4:brand"]}
                                required={false}
                                choices={FormLists.PillBrandsEnumList}
                            >
                            </PatientSelectInput>
                            <PatientTextInput
                                label="Start date"
                                name="pill_usage_information:4:start_date"
                                value={patient_information["pill_usage_information:4:start_date"]}
                                handleValueChange={handleTextValueChange}
                                required={false}
                                type="date"
                            />
                            <PatientTextInput
                                label="Stop date (Optional)"
                                name="pill_usage_information:4:stop_date"
                                value={patient_information["pill_usage_information:4:stop_date"]}
                                handleValueChange={handleTextValueChange}
                                required={false}
                                type="date"
                            />
                            <PatientTextInput
                                label="Reason for stopping. (Optional)"
                                name="pill_usage_information:4:stop_reason"
                                value={patient_information["pill_usage_information:4:stop_reason"]}
                                handleValueChange={handleTextValueChange}
                                required={false}
                            />
                        </div>
                    )}

                {patient_information["previous_birth_control_methods"]
                    && patient_information["previous_birth_control_methods"].includes('patch') &&
                    (
                        <div className={"follow-up-subsection mt-2"}>
                            <div className={"heading"}>Patch Usage Information (Enter up to 5)</div>
                            <div className={"subheading-2 mt-2"}>Entry #0</div>
                            <PatientSelectInput
                                label="Start date"
                                name="patch_usage_information:0:brand"
                                handleValueChange={handleSelectValueChange}
                                value={patient_information["patch_usage_information:0:brand"]}
                                required={false}
                                choices={FormLists.PatchBrandsEnumList}
                            >
                            </PatientSelectInput>
                            <PatientTextInput
                                label="Start date"
                                name="patch_usage_information:0:start_date"
                                value={patient_information["patch_usage_information:0:start_date"]}
                                handleValueChange={handleTextValueChange}
                                required={false}
                                type="date"
                            />
                            <PatientTextInput
                                label="Stop date (Optional)"
                                name="patch_usage_information:0:stop_date"
                                value={patient_information["patch_usage_information:0:stop_date"]}
                                handleValueChange={handleTextValueChange}
                                required={false}
                                type="date"
                            />
                            <PatientTextInput
                                label="Reason for stopping. (Optional)"
                                name="patch_usage_information:0:stop_reason"
                                value={patient_information["patch_usage_information:0:stop_reason"]}
                                handleValueChange={handleTextValueChange}
                                required={false}
                            />
                            <div className={"subheading-2 mt-2"}>Entry #1</div>
                            <PatientSelectInput
                                label="Start date"
                                name="patch_usage_information:1:brand"
                                handleValueChange={handleSelectValueChange}
                                value={patient_information["patch_usage_information:1:brand"]}
                                required={false}
                                choices={FormLists.PatchBrandsEnumList}
                            >
                            </PatientSelectInput>
                            <PatientTextInput
                                label="Start date"
                                name="patch_usage_information:1:start_date"
                                value={patient_information["patch_usage_information:1:start_date"]}
                                handleValueChange={handleTextValueChange}
                                required={false}
                                type="date"
                            />
                            <PatientTextInput
                                label="Stop date (Optional)"
                                name="patch_usage_information:1:stop_date"
                                value={patient_information["patch_usage_information:1:stop_date"]}
                                handleValueChange={handleTextValueChange}
                                required={false}
                                type="date"
                            />
                            <PatientTextInput
                                label="Reason for stopping. (Optional)"
                                name="patch_usage_information:1:stop_reason"
                                value={patient_information["patch_usage_information:1:stop_reason"]}
                                handleValueChange={handleTextValueChange}
                                required={false}
                            />
                            <div className={"subheading-2 mt-2"}>Entry #2</div>
                            <PatientSelectInput
                                label="Start date"
                                name="patch_usage_information:2:brand"
                                handleValueChange={handleSelectValueChange}
                                value={patient_information["patch_usage_information:2:brand"]}
                                required={false}
                                choices={FormLists.PatchBrandsEnumList}
                            >
                            </PatientSelectInput>
                            <PatientTextInput
                                label="Start date"
                                name="patch_usage_information:2:start_date"
                                value={patient_information["patch_usage_information:2:start_date"]}
                                handleValueChange={handleTextValueChange}
                                required={false}
                                type="date"
                            />
                            <PatientTextInput
                                label="Stop date (Optional)"
                                name="patch_usage_information:2:stop_date"
                                value={patient_information["patch_usage_information:2:stop_date"]}
                                handleValueChange={handleTextValueChange}
                                required={false}
                                type="date"
                            />
                            <PatientTextInput
                                label="Reason for stopping. (Optional)"
                                name="patch_usage_information:2:stop_reason"
                                value={patient_information["patch_usage_information:2:stop_reason"]}
                                handleValueChange={handleTextValueChange}
                                required={false}
                            />
                            <div className={"subheading-2 mt-2"}>Entry #3</div>
                            <PatientSelectInput
                                label="Start date"
                                name="patch_usage_information:3:brand"
                                handleValueChange={handleSelectValueChange}
                                value={patient_information["patch_usage_information:3:brand"]}
                                required={false}
                                choices={FormLists.PatchBrandsEnumList}
                            >
                            </PatientSelectInput>
                            <PatientTextInput
                                label="Start date"
                                name="patch_usage_information:3:start_date"
                                value={patient_information["patch_usage_information:3:start_date"]}
                                handleValueChange={handleTextValueChange}
                                required={false}
                                type="date"
                            />
                            <PatientTextInput
                                label="Stop date (Optional)"
                                name="patch_usage_information:3:stop_date"
                                value={patient_information["patch_usage_information:3:stop_date"]}
                                handleValueChange={handleTextValueChange}
                                required={false}
                                type="date"
                            />
                            <PatientTextInput
                                label="Reason for stopping. (Optional)"
                                name="patch_usage_information:3:stop_reason"
                                value={patient_information["patch_usage_information:3:stop_reason"]}
                                handleValueChange={handleTextValueChange}
                                required={false}
                            />
                            <div className={"subheading-2 mt-2"}>Entry #4</div>
                            <PatientSelectInput
                                label="Start date"
                                name="patch_usage_information:4:brand"
                                handleValueChange={handleSelectValueChange}
                                value={patient_information["patch_usage_information:4:brand"]}
                                required={false}
                                choices={FormLists.PatchBrandsEnumList}
                            >
                            </PatientSelectInput>
                            <PatientTextInput
                                label="Start date"
                                name="patch_usage_information:4:start_date"
                                value={patient_information["patch_usage_information:4:start_date"]}
                                handleValueChange={handleTextValueChange}
                                required={false}
                                type="date"
                            />
                            <PatientTextInput
                                label="Stop date (Optional)"
                                name="patch_usage_information:4:stop_date"
                                value={patient_information["patch_usage_information:4:stop_date"]}
                                handleValueChange={handleTextValueChange}
                                required={false}
                                type="date"
                            />
                            <PatientTextInput
                                label="Reason for stopping. (Optional)"
                                name="patch_usage_information:4:stop_reason"
                                value={patient_information["patch_usage_information:4:stop_reason"]}
                                handleValueChange={handleTextValueChange}
                                required={false}
                            />
                        </div>
                    )}

                {patient_information["previous_birth_control_methods"]
                    && patient_information["previous_birth_control_methods"].includes('cu_iud') &&
                    (
                        <div className={"follow-up-subsection mt-2"}>
                            <div className={"heading"}>IUD Usage Information (Enter up to 5)</div>
                            <div className={"subheading-2 mt-2"}>Entry #0</div>
                            <PatientSelectInput
                                label="Start date"
                                name="iud_usage_information:0:brand"
                                handleValueChange={handleSelectValueChange}
                                value={patient_information["iud_usage_information:0:brand"]}
                                required={false}
                                choices={FormLists.IudBrandsEnumList}
                            >
                            </PatientSelectInput>
                            <PatientTextInput
                                label="Start date"
                                name="iud_usage_information:0:start_date"
                                value={patient_information["iud_usage_information:0:start_date"]}
                                handleValueChange={handleTextValueChange}
                                required={false}
                                type="date"
                            />
                            <PatientTextInput
                                label="Stop date (Optional)"
                                name="iud_usage_information:0:stop_date"
                                value={patient_information["iud_usage_information:0:stop_date"]}
                                handleValueChange={handleTextValueChange}
                                required={false}
                                type="date"
                            />
                            <PatientTextInput
                                label="Reason for stopping. (Optional)"
                                name="iud_usage_information:0:stop_reason"
                                value={patient_information["iud_usage_information:0:stop_reason"]}
                                handleValueChange={handleTextValueChange}
                                required={false}
                            />
                            <div className={"subheading-2 mt-2"}>Entry #1</div>
                            <PatientSelectInput
                                label="Start date"
                                name="iud_usage_information:1:brand"
                                handleValueChange={handleSelectValueChange}
                                value={patient_information["iud_usage_information:1:brand"]}
                                required={false}
                                choices={FormLists.IudBrandsEnumList}
                            >
                            </PatientSelectInput>
                            <PatientTextInput
                                label="Start date"
                                name="iud_usage_information:1:start_date"
                                value={patient_information["iud_usage_information:1:start_date"]}
                                handleValueChange={handleTextValueChange}
                                required={false}
                                type="date"
                            />
                            <PatientTextInput
                                label="Stop date (Optional)"
                                name="iud_usage_information:1:stop_date"
                                value={patient_information["iud_usage_information:1:stop_date"]}
                                handleValueChange={handleTextValueChange}
                                required={false}
                                type="date"
                            />
                            <PatientTextInput
                                label="Reason for stopping. (Optional)"
                                name="iud_usage_information:1:stop_reason"
                                value={patient_information["iud_usage_information:1:stop_reason"]}
                                handleValueChange={handleTextValueChange}
                                required={false}
                            />
                            <div className={"subheading-2 mt-2"}>Entry #2</div>
                            <PatientSelectInput
                                label="Start date"
                                name="iud_usage_information:2:brand"
                                handleValueChange={handleSelectValueChange}
                                value={patient_information["iud_usage_information:2:brand"]}
                                required={false}
                                choices={FormLists.IudBrandsEnumList}
                            >
                            </PatientSelectInput>
                            <PatientTextInput
                                label="Start date"
                                name="iud_usage_information:2:start_date"
                                value={patient_information["iud_usage_information:2:start_date"]}
                                handleValueChange={handleTextValueChange}
                                required={false}
                                type="date"
                            />
                            <PatientTextInput
                                label="Stop date (Optional)"
                                name="iud_usage_information:2:stop_date"
                                value={patient_information["iud_usage_information:2:stop_date"]}
                                handleValueChange={handleTextValueChange}
                                required={false}
                                type="date"
                            />
                            <PatientTextInput
                                label="Reason for stopping. (Optional)"
                                name="iud_usage_information:2:stop_reason"
                                value={patient_information["iud_usage_information:2:stop_reason"]}
                                handleValueChange={handleTextValueChange}
                                required={false}
                            />
                            <div className={"subheading-2 mt-2"}>Entry #3</div>
                            <PatientSelectInput
                                label="Start date"
                                name="iud_usage_information:3:brand"
                                handleValueChange={handleSelectValueChange}
                                value={patient_information["iud_usage_information:3:brand"]}
                                required={false}
                                choices={FormLists.IudBrandsEnumList}
                            >
                            </PatientSelectInput>
                            <PatientTextInput
                                label="Start date"
                                name="iud_usage_information:3:start_date"
                                value={patient_information["iud_usage_information:3:start_date"]}
                                handleValueChange={handleTextValueChange}
                                required={false}
                                type="date"
                            />
                            <PatientTextInput
                                label="Stop date (Optional)"
                                name="iud_usage_information:3:stop_date"
                                value={patient_information["iud_usage_information:3:stop_date"]}
                                handleValueChange={handleTextValueChange}
                                required={false}
                                type="date"
                            />
                            <PatientTextInput
                                label="Reason for stopping. (Optional)"
                                name="iud_usage_information:3:stop_reason"
                                value={patient_information["iud_usage_information:3:stop_reason"]}
                                handleValueChange={handleTextValueChange}
                                required={false}
                            />
                            <div className={"subheading-2 mt-2"}>Entry #4</div>
                            <PatientSelectInput
                                label="Start date"
                                name="iud_usage_information:4:brand"
                                handleValueChange={handleSelectValueChange}
                                value={patient_information["iud_usage_information:4:brand"]}
                                required={false}
                                choices={FormLists.IudBrandsEnumList}
                            >
                            </PatientSelectInput>
                            <PatientTextInput
                                label="Start date"
                                name="iud_usage_information:4:start_date"
                                value={patient_information["iud_usage_information:4:start_date"]}
                                handleValueChange={handleTextValueChange}
                                required={false}
                                type="date"
                            />
                            <PatientTextInput
                                label="Stop date (Optional)"
                                name="iud_usage_information:4:stop_date"
                                value={patient_information["iud_usage_information:4:stop_date"]}
                                handleValueChange={handleTextValueChange}
                                required={false}
                                type="date"
                            />
                            <PatientTextInput
                                label="Reason for stopping. (Optional)"
                                name="iud_usage_information:4:stop_reason"
                                value={patient_information["iud_usage_information:4:stop_reason"]}
                                handleValueChange={handleTextValueChange}
                                required={false}
                            />
                        </div>
                    )}

                {patient_information["previous_birth_control_methods"]
                    && patient_information["previous_birth_control_methods"].includes('implant') &&
                    (
                        <div className={"follow-up-subsection mt-2"}>
                            <div className={"heading"}>Implant Usage Information (Enter up to 5)</div>
                            <div className={"subheading-2 mt-2"}>Entry #0</div>
                            <PatientSelectInput
                                label="Start date"
                                name="implant_usage_information:0:brand"
                                handleValueChange={handleSelectValueChange}
                                value={patient_information["implant_usage_information:0:brand"]}
                                required={false}
                                choices={FormLists.ImplantBrandsEnumList}
                            >
                            </PatientSelectInput>
                            <PatientTextInput
                                label="Start date"
                                name="implant_usage_information:0:start_date"
                                value={patient_information["implant_usage_information:0:start_date"]}
                                handleValueChange={handleTextValueChange}
                                required={false}
                                type="date"
                            />
                            <PatientTextInput
                                label="Stop date (Optional)"
                                name="implant_usage_information:0:stop_date"
                                value={patient_information["implant_usage_information:0:stop_date"]}
                                handleValueChange={handleTextValueChange}
                                required={false}
                                type="date"
                            />
                            <PatientTextInput
                                label="Reason for stopping. (Optional)"
                                name="implant_usage_information:0:stop_reason"
                                value={patient_information["implant_usage_information:0:stop_reason"]}
                                handleValueChange={handleTextValueChange}
                                required={false}
                            />
                            <div className={"subheading-2 mt-2"}>Entry #1</div>
                            <PatientSelectInput
                                label="Start date"
                                name="implant_usage_information:1:brand"
                                handleValueChange={handleSelectValueChange}
                                value={patient_information["implant_usage_information:1:brand"]}
                                required={false}
                                choices={FormLists.ImplantBrandsEnumList}
                            >
                            </PatientSelectInput>
                            <PatientTextInput
                                label="Start date"
                                name="implant_usage_information:1:start_date"
                                value={patient_information["implant_usage_information:1:start_date"]}
                                handleValueChange={handleTextValueChange}
                                required={false}
                                type="date"
                            />
                            <PatientTextInput
                                label="Stop date (Optional)"
                                name="implant_usage_information:1:stop_date"
                                value={patient_information["implant_usage_information:1:stop_date"]}
                                handleValueChange={handleTextValueChange}
                                required={false}
                                type="date"
                            />
                            <PatientTextInput
                                label="Reason for stopping. (Optional)"
                                name="implant_usage_information:1:stop_reason"
                                value={patient_information["implant_usage_information:1:stop_reason"]}
                                handleValueChange={handleTextValueChange}
                                required={false}
                            />
                            <div className={"subheading-2 mt-2"}>Entry #2</div>
                            <PatientSelectInput
                                label="Start date"
                                name="implant_usage_information:2:brand"
                                handleValueChange={handleSelectValueChange}
                                value={patient_information["implant_usage_information:2:brand"]}
                                required={false}
                                choices={FormLists.ImplantBrandsEnumList}
                            >
                            </PatientSelectInput>
                            <PatientTextInput
                                label="Start date"
                                name="implant_usage_information:2:start_date"
                                value={patient_information["implant_usage_information:2:start_date"]}
                                handleValueChange={handleTextValueChange}
                                required={false}
                                type="date"
                            />
                            <PatientTextInput
                                label="Stop date (Optional)"
                                name="implant_usage_information:2:stop_date"
                                value={patient_information["implant_usage_information:2:stop_date"]}
                                handleValueChange={handleTextValueChange}
                                required={false}
                                type="date"
                            />
                            <PatientTextInput
                                label="Reason for stopping. (Optional)"
                                name="implant_usage_information:2:stop_reason"
                                value={patient_information["implant_usage_information:2:stop_reason"]}
                                handleValueChange={handleTextValueChange}
                                required={false}
                            />
                            <div className={"subheading-2 mt-2"}>Entry #3</div>
                            <PatientSelectInput
                                label="Start date"
                                name="implant_usage_information:3:brand"
                                handleValueChange={handleSelectValueChange}
                                value={patient_information["implant_usage_information:3:brand"]}
                                required={false}
                                choices={FormLists.ImplantBrandsEnumList}
                            >
                            </PatientSelectInput>
                            <PatientTextInput
                                label="Start date"
                                name="implant_usage_information:3:start_date"
                                value={patient_information["implant_usage_information:3:start_date"]}
                                handleValueChange={handleTextValueChange}
                                required={false}
                                type="date"
                            />
                            <PatientTextInput
                                label="Stop date (Optional)"
                                name="implant_usage_information:3:stop_date"
                                value={patient_information["implant_usage_information:3:stop_date"]}
                                handleValueChange={handleTextValueChange}
                                required={false}
                                type="date"
                            />
                            <PatientTextInput
                                label="Reason for stopping. (Optional)"
                                name="implant_usage_information:3:stop_reason"
                                value={patient_information["implant_usage_information:3:stop_reason"]}
                                handleValueChange={handleTextValueChange}
                                required={false}
                            />
                            <div className={"subheading-2 mt-2"}>Entry #4</div>
                            <PatientSelectInput
                                label="Start date"
                                name="implant_usage_information:4:brand"
                                handleValueChange={handleSelectValueChange}
                                value={patient_information["implant_usage_information:4:brand"]}
                                required={false}
                                choices={FormLists.ImplantBrandsEnumList}
                            >
                            </PatientSelectInput>
                            <PatientTextInput
                                label="Start date"
                                name="implant_usage_information:4:start_date"
                                value={patient_information["implant_usage_information:4:start_date"]}
                                handleValueChange={handleTextValueChange}
                                required={false}
                                type="date"
                            />
                            <PatientTextInput
                                label="Stop date (Optional)"
                                name="implant_usage_information:4:stop_date"
                                value={patient_information["implant_usage_information:4:stop_date"]}
                                handleValueChange={handleTextValueChange}
                                required={false}
                                type="date"
                            />
                            <PatientTextInput
                                label="Reason for stopping. (Optional)"
                                name="implant_usage_information:4:stop_reason"
                                value={patient_information["implant_usage_information:4:stop_reason"]}
                                handleValueChange={handleTextValueChange}
                                required={false}
                            />
                        </div>
                    )}

                {patient_information["previous_birth_control_methods"]
                    && patient_information["previous_birth_control_methods"].includes('shot') &&
                    (
                        <div className={"follow-up-subsection mt-2"}>
                            <div className={"heading"}>Injection Usage Information (Enter up to 5)</div>
                            <div className={"subheading-2 mt-2"}>Entry #0</div>
                            <PatientSelectInput
                                label="Start date"
                                name="injection_usage_information:0:brand"
                                handleValueChange={handleSelectValueChange}
                                value={patient_information["injection_usage_information:0:brand"]}
                                required={false}
                                choices={FormLists.InjectionBrandsEnumList}
                            >
                            </PatientSelectInput>
                            <PatientTextInput
                                label="Start date"
                                name="injection_usage_information:0:start_date"
                                value={patient_information["injection_usage_information:0:start_date"]}
                                handleValueChange={handleTextValueChange}
                                required={false}
                                type="date"
                            />
                            <PatientTextInput
                                label="Stop date (Optional)"
                                name="injection_usage_information:0:stop_date"
                                value={patient_information["injection_usage_information:0:stop_date"]}
                                handleValueChange={handleTextValueChange}
                                required={false}
                                type="date"
                            />
                            <PatientTextInput
                                label="Reason for stopping. (Optional)"
                                name="injection_usage_information:0:stop_reason"
                                value={patient_information["injection_usage_information:0:stop_reason"]}
                                handleValueChange={handleTextValueChange}
                                required={false}
                            />
                            <div className={"subheading-2 mt-2"}>Entry #1</div>
                            <PatientSelectInput
                                label="Start date"
                                name="injection_usage_information:1:brand"
                                handleValueChange={handleSelectValueChange}
                                value={patient_information["injection_usage_information:1:brand"]}
                                required={false}
                                choices={FormLists.InjectionBrandsEnumList}
                            >
                            </PatientSelectInput>
                            <PatientTextInput
                                label="Start date"
                                name="injection_usage_information:1:start_date"
                                value={patient_information["injection_usage_information:1:start_date"]}
                                handleValueChange={handleTextValueChange}
                                required={false}
                                type="date"
                            />
                            <PatientTextInput
                                label="Stop date (Optional)"
                                name="injection_usage_information:1:stop_date"
                                value={patient_information["injection_usage_information:1:stop_date"]}
                                handleValueChange={handleTextValueChange}
                                required={false}
                                type="date"
                            />
                            <PatientTextInput
                                label="Reason for stopping. (Optional)"
                                name="injection_usage_information:1:stop_reason"
                                value={patient_information["injection_usage_information:1:stop_reason"]}
                                handleValueChange={handleTextValueChange}
                                required={false}
                            />
                            <div className={"subheading-2 mt-2"}>Entry #2</div>
                            <PatientSelectInput
                                label="Start date"
                                name="injection_usage_information:2:brand"
                                handleValueChange={handleSelectValueChange}
                                value={patient_information["injection_usage_information:2:brand"]}
                                required={false}
                                choices={FormLists.InjectionBrandsEnumList}
                            >
                            </PatientSelectInput>
                            <PatientTextInput
                                label="Start date"
                                name="injection_usage_information:2:start_date"
                                value={patient_information["injection_usage_information:2:start_date"]}
                                handleValueChange={handleTextValueChange}
                                required={false}
                                type="date"
                            />
                            <PatientTextInput
                                label="Stop date (Optional)"
                                name="injection_usage_information:2:stop_date"
                                value={patient_information["injection_usage_information:2:stop_date"]}
                                handleValueChange={handleTextValueChange}
                                required={false}
                                type="date"
                            />
                            <PatientTextInput
                                label="Reason for stopping. (Optional)"
                                name="injection_usage_information:2:stop_reason"
                                value={patient_information["injection_usage_information:2:stop_reason"]}
                                handleValueChange={handleTextValueChange}
                                required={false}
                            />
                            <div className={"subheading-2 mt-2"}>Entry #3</div>
                            <PatientSelectInput
                                label="Start date"
                                name="injection_usage_information:3:brand"
                                handleValueChange={handleSelectValueChange}
                                value={patient_information["injection_usage_information:3:brand"]}
                                required={false}
                                choices={FormLists.InjectionBrandsEnumList}
                            >
                            </PatientSelectInput>
                            <PatientTextInput
                                label="Start date"
                                name="injection_usage_information:3:start_date"
                                value={patient_information["injection_usage_information:3:start_date"]}
                                handleValueChange={handleTextValueChange}
                                required={false}
                                type="date"
                            />
                            <PatientTextInput
                                label="Stop date (Optional)"
                                name="injection_usage_information:3:stop_date"
                                value={patient_information["injection_usage_information:3:stop_date"]}
                                handleValueChange={handleTextValueChange}
                                required={false}
                                type="date"
                            />
                            <PatientTextInput
                                label="Reason for stopping. (Optional)"
                                name="injection_usage_information:3:stop_reason"
                                value={patient_information["injection_usage_information:3:stop_reason"]}
                                handleValueChange={handleTextValueChange}
                                required={false}
                            />
                            <div className={"subheading-2 mt-2"}>Entry #4</div>
                            <PatientSelectInput
                                label="Start date"
                                name="injection_usage_information:4:brand"
                                handleValueChange={handleSelectValueChange}
                                value={patient_information["injection_usage_information:4:brand"]}
                                required={false}
                                choices={FormLists.InjectionBrandsEnumList}
                            >
                            </PatientSelectInput>
                            <PatientTextInput
                                label="Start date"
                                name="injection_usage_information:4:start_date"
                                value={patient_information["injection_usage_information:4:start_date"]}
                                handleValueChange={handleTextValueChange}
                                required={false}
                                type="date"
                            />
                            <PatientTextInput
                                label="Stop date (Optional)"
                                name="injection_usage_information:4:stop_date"
                                value={patient_information["injection_usage_information:4:stop_date"]}
                                handleValueChange={handleTextValueChange}
                                required={false}
                                type="date"
                            />
                            <PatientTextInput
                                label="Reason for stopping. (Optional)"
                                name="injection_usage_information:4:stop_reason"
                                value={patient_information["injection_usage_information:4:stop_reason"]}
                                handleValueChange={handleTextValueChange}
                                required={false}
                            />
                        </div>
                    )}

                {patient_information["previous_birth_control_methods"]
                    && patient_information["previous_birth_control_methods"].includes('ring') &&
                    (
                        <div className={"follow-up-subsection mt-2"}>
                            <div className={"heading"}>Ring Usage Information (Enter up to 5)</div>
                            <div className={"subheading-2 mt-2"}>Entry #0</div>
                            <PatientSelectInput
                                label="Start date"
                                name="ring_usage_information:0:brand"
                                handleValueChange={handleSelectValueChange}
                                value={patient_information["ring_usage_information:0:brand"]}
                                required={false}
                                choices={FormLists.RingBrandsEnumList}
                            >
                            </PatientSelectInput>
                            <PatientTextInput
                                label="Start date"
                                name="ring_usage_information:0:start_date"
                                value={patient_information["ring_usage_information:0:start_date"]}
                                handleValueChange={handleTextValueChange}
                                required={false}
                                type="date"
                            />
                            <PatientTextInput
                                label="Stop date (Optional)"
                                name="ring_usage_information:0:stop_date"
                                value={patient_information["ring_usage_information:0:stop_date"]}
                                handleValueChange={handleTextValueChange}
                                required={false}
                                type="date"
                            />
                            <PatientTextInput
                                label="Reason for stopping. (Optional)"
                                name="ring_usage_information:0:stop_reason"
                                value={patient_information["ring_usage_information:0:stop_reason"]}
                                handleValueChange={handleTextValueChange}
                                required={false}
                            />
                            <div className={"subheading-2 mt-2"}>Entry #1</div>
                            <PatientSelectInput
                                label="Start date"
                                name="ring_usage_information:1:brand"
                                handleValueChange={handleSelectValueChange}
                                value={patient_information["ring_usage_information:1:brand"]}
                                required={false}
                                choices={FormLists.RingBrandsEnumList}
                            >
                            </PatientSelectInput>
                            <PatientTextInput
                                label="Start date"
                                name="ring_usage_information:1:start_date"
                                value={patient_information["ring_usage_information:1:start_date"]}
                                handleValueChange={handleTextValueChange}
                                required={false}
                                type="date"
                            />
                            <PatientTextInput
                                label="Stop date (Optional)"
                                name="ring_usage_information:1:stop_date"
                                value={patient_information["ring_usage_information:1:stop_date"]}
                                handleValueChange={handleTextValueChange}
                                required={false}
                                type="date"
                            />
                            <PatientTextInput
                                label="Reason for stopping. (Optional)"
                                name="ring_usage_information:1:stop_reason"
                                value={patient_information["ring_usage_information:1:stop_reason"]}
                                handleValueChange={handleTextValueChange}
                                required={false}
                            />
                            <div className={"subheading-2 mt-2"}>Entry #2</div>
                            <PatientSelectInput
                                label="Start date"
                                name="ring_usage_information:2:brand"
                                handleValueChange={handleSelectValueChange}
                                value={patient_information["ring_usage_information:2:brand"]}
                                required={false}
                                choices={FormLists.RingBrandsEnumList}
                            >
                            </PatientSelectInput>
                            <PatientTextInput
                                label="Start date"
                                name="ring_usage_information:2:start_date"
                                value={patient_information["ring_usage_information:2:start_date"]}
                                handleValueChange={handleTextValueChange}
                                required={false}
                                type="date"
                            />
                            <PatientTextInput
                                label="Stop date (Optional)"
                                name="ring_usage_information:2:stop_date"
                                value={patient_information["ring_usage_information:2:stop_date"]}
                                handleValueChange={handleTextValueChange}
                                required={false}
                                type="date"
                            />
                            <PatientTextInput
                                label="Reason for stopping. (Optional)"
                                name="ring_usage_information:2:stop_reason"
                                value={patient_information["ring_usage_information:2:stop_reason"]}
                                handleValueChange={handleTextValueChange}
                                required={false}
                            />
                            <div className={"subheading-2 mt-2"}>Entry #3</div>
                            <PatientSelectInput
                                label="Start date"
                                name="ring_usage_information:3:brand"
                                handleValueChange={handleSelectValueChange}
                                value={patient_information["ring_usage_information:3:brand"]}
                                required={false}
                                choices={FormLists.RingBrandsEnumList}
                            >
                            </PatientSelectInput>
                            <PatientTextInput
                                label="Start date"
                                name="ring_usage_information:3:start_date"
                                value={patient_information["ring_usage_information:3:start_date"]}
                                handleValueChange={handleTextValueChange}
                                required={false}
                                type="date"
                            />
                            <PatientTextInput
                                label="Stop date (Optional)"
                                name="ring_usage_information:3:stop_date"
                                value={patient_information["ring_usage_information:3:stop_date"]}
                                handleValueChange={handleTextValueChange}
                                required={false}
                                type="date"
                            />
                            <PatientTextInput
                                label="Reason for stopping. (Optional)"
                                name="ring_usage_information:3:stop_reason"
                                value={patient_information["ring_usage_information:3:stop_reason"]}
                                handleValueChange={handleTextValueChange}
                                required={false}
                            />
                            <div className={"subheading-2 mt-2"}>Entry #4</div>
                            <PatientSelectInput
                                label="Start date"
                                name="ring_usage_information:4:brand"
                                handleValueChange={handleSelectValueChange}
                                value={patient_information["ring_usage_information:4:brand"]}
                                required={false}
                                choices={FormLists.RingBrandsEnumList}
                            >
                            </PatientSelectInput>
                            <PatientTextInput
                                label="Start date"
                                name="ring_usage_information:4:start_date"
                                value={patient_information["ring_usage_information:4:start_date"]}
                                handleValueChange={handleTextValueChange}
                                required={false}
                                type="date"
                            />
                            <PatientTextInput
                                label="Stop date (Optional)"
                                name="ring_usage_information:4:stop_date"
                                value={patient_information["ring_usage_information:4:stop_date"]}
                                handleValueChange={handleTextValueChange}
                                required={false}
                                type="date"
                            />
                            <PatientTextInput
                                label="Reason for stopping. (Optional)"
                                name="ring_usage_information:4:stop_reason"
                                value={patient_information["ring_usage_information:4:stop_reason"]}
                                handleValueChange={handleTextValueChange}
                                required={false}
                            />
                        </div>
                    )}
            </CategoryWithInformation>

            <CategoryWithInformation
                category="Medical History"
            >
                <PatientStringListInput
                    label="Please add any current medications being taken. Comma-separated."
                    name="current_medications"
                    value={patient_information["current_medications"]}
                    handleValueChange={handleStringListValueChange}
                    required={false}
                    placeholder="eg. loratadine, aspirin"
                />
                <PatientStringListInput
                    label="Please add any current supplements being taken. Comma-separated."
                    name="current_supplements"
                    value={patient_information["current_supplements"]}
                    handleValueChange={handleStringListValueChange}
                    required={false}
                    placeholder="eg. vitamin c, vitamin d"
                />
                <PatientStringListInput
                    label="Please add any current immunizations they have taken. Comma-separated."
                    name="current_immunizations"
                    value={patient_information["current_immunizations"]}
                    handleValueChange={handleStringListValueChange}
                    required={false}
                    placeholder="eg. COVID-19 vaccine, flu shot"
                />
                <PatientStringListInput
                    label="Please add any allergies the patient has. Comma-separated."
                    name="allergies"
                    value={patient_information["allergies"]}
                    handleValueChange={handleStringListValueChange}
                    required={false}
                    placeholder="eg. specific drug ingredients, cats"
                />
                <PatientSelectInput
                    label="Has the patient ever had weight loss surgery, also known as bariatric surgery?"
                    name="bariatric_surgery"
                    handleValueChange={handleSelectValueChange}
                    value={patient_information["bariatric_surgery"]}
                    required
                >
                    <option value="no">No</option>
                    <option value="yes_restrict_eating">Yes, restrict eating</option>
                    <option value="yes_restrict_eating_nutrients">Yes, restrict eating nutrients</option>
                </PatientSelectInput>
                <PatientCheckboxInput
                    label="Has the patient been told that they have a condition called atherosclerotic cardiovascular disease?"
                    name="atherosclerotic_cv_disease"
                    value={patient_information["atherosclerotic_cv_disease"]}
                    handleValueChange={handleBooleanValueChange}
                />
                <PatientSelectInput
                    label="Has a doctor ever told the patient that they have high blood pressure, also called hypertension?"
                    name="hypertension"
                    handleValueChange={handleSelectValueChange}
                    value={patient_information["hypertension"]}
                    required
                >
                    <option value="no">No</option>
                    <option value="yes_with_medications">Yes, with medications</option>
                    <option value="yes_without_medications">Yes, without medications</option>
                    <option value="yes_with_vascular_disease">Yes, with vascular disease</option>
                </PatientSelectInput>
                {patient_information["hypertension"]
                    && patient_information["hypertension"] === "yes_without_medications"
                    && (
                        <PatientSelectInput
                            label="What range does the patient's blood pressure generally fall into?"
                            name="hypertension_bp"
                            handleValueChange={handleSelectValueChange}
                            value={patient_information["hypertension_bp"]}
                            required
                        >
                            <option value="systolic_140_159_diastolic_90_99">Systolic 140-159 mm Hg or diastolic 90-99 mm Hg</option>
                            <option value="systolic_gte_160_diastolic_gte_100">Systolic ≥160 mm Hg or diastolic ≥100 mm Hg</option>
                            <option value="systolic_lte_130_diastolic_lte_80">Systolic &#60;/=130 or diastolic &#60;/=80</option>
                        </PatientSelectInput>
                    )}


                <PatientSelectInput
                    label="Has the patient ever been diagnosed with deep venous thrombosis (DVT) or pulmonary embolism (PE)?"
                    name="dvt_pe"
                    handleValueChange={handleSelectValueChange}
                    value={patient_information["dvt_pe"]}
                    required
                >
                    <option value="no">No</option>
                    <option value="leg_resolved_without_medications">Leg clot, lung clot, pulmonary embolism resolved without anticougulant medication</option>
                    <option value="leg_resolved_with_medications">Leg clot, lung clot, pulmonary embolism resolved treated with anticougulant medication</option>
                </PatientSelectInput>
                <PatientCheckboxInput
                    label="Does the patient have any known thrombogenic mutations, eg. factor V Leiden, prothrombin mutation, etc"
                    name="thrombogenic_mutations"
                    value={patient_information["thrombogenic_mutations"]}
                    handleValueChange={handleBooleanValueChange}
                />
                <PatientSelectInput
                    label="Does the patient have visible veins in their legs that look swollen or twisted, commonly known as varicose veins?"
                    name="superficial_venous_disorders"
                    handleValueChange={handleSelectValueChange}
                    value={patient_information["superficial_venous_disorders"]}
                    required
                >
                    <option value="no">No</option>
                    <option value="yes_visible">Yes, visible veins</option>
                    <option value="yes_history">Yes, history of superficial venous thrombosis</option>
                </PatientSelectInput>
                <PatientCheckboxInput
                    label="Has the patient been diagnosed with any blockages in the blood vessels that supply their heart, also known as ischemic heart disease?"
                    name="ischemic_heart_disease"
                    value={patient_information["ischemic_heart_disease"]}
                    handleValueChange={handleBooleanValueChange}
                />
                <PatientCheckboxInput
                    label="Has the patient ever had a stroke, also known as a cerebrovascular accident (CVA)?"
                    name="stroke"
                    value={patient_information["stroke"]}
                    handleValueChange={handleBooleanValueChange}
                />
                <PatientSelectInput
                    label="Has the patient been diagnosed with any conditions affecting their heart valves?"
                    name="valvular_heart_disease"
                    handleValueChange={handleSelectValueChange}
                    value={patient_information["valvular_heart_disease"]}
                    required
                >
                    <option value="no">No</option>
                    <option value="diagnosed_without_complications">Diagnosed without complications</option>
                    <option value="diagnosed_with_complications">Diagnosed with complications</option>
                </PatientSelectInput>
                {patient_information["valvular_heart_disease"]
                    && patient_information["valvular_heart_disease"] === "diagnosed_with_complications"
                    && (
                        <PatientMultiCheckboxInput
                            label="Please select the complications associated with the patient's heart valve condition."
                            name="valvular_heart_disease_complications"
                            handleValueChange={handleCheckboxListValueChange}
                            value={patient_information["valvular_heart_disease_complications"]}
                            choices={FormLists.ValvularHeartDiseaseComplicationsEnumList}
                        />
                    )}

                {patient_information["pregnant"] && (
                    <PatientSelectInput
                        label="Has the patient ever been diagnosed with peripartum cardiomyopathy, which affects the heart during or after pregnancy?"
                        name="peripartum_cardiomyopathy"
                        handleValueChange={handleSelectValueChange}
                        value={patient_information["peripartum_cardiomyopathy"]}
                        required
                    >
                        <option value="no">No</option>
                        <option value="diagnosed_normal_mild_impaired">Yes, normal or mildly impaired</option>
                        <option value="diagnosed_moderate_severe_impaired">Yes, moderately or severely impaired</option>
                    </PatientSelectInput>
                )}

                {patient_information["peripartum_cardiomyopathy"]
                    && patient_information["peripartum_cardiomyopathy"] === 'diagnosed_normal_mild_impaired'
                    && (
                        <PatientSelectInput
                            label="Please specify the duration of their peripartum cardiomyopathy (<6 months or ≥6 months):"
                            name="peripartum_cardiomyopathy_duration"
                            handleValueChange={handleSelectValueChange}
                            value={patient_information["peripartum_cardiomyopathy_duration"]}
                            required
                        >
                            <option value="lt_6_months">Less than 6 months</option>
                            <option value="gte_6_months">Greater than or equal to 6 months</option>
                        </PatientSelectInput>
                    )}
                <PatientMultiCheckboxInput
                    label="Does the patient have any of the following rheumatic diseases?"
                    name="rheumatic_diseases"
                    handleValueChange={handleCheckboxListValueChange}
                    value={patient_information["rheumatic_diseases"]}
                    choices={FormLists.RheumaticDiseasesEnumList}
                />
                {patient_information["rheumatic_diseases"]
                    && patient_information["rheumatic_diseases"] === 'other'
                    && (
                        <PatientTextInput
                            label="Enter the name of the other rheumatic disease"
                            name="other_rheumatic_disease"
                            value={patient_information["other_rheumatic_disease"]}
                            handleValueChange={handleTextValueChange}
                            required
                        />
                    )}
                {patient_information["rheumatic_diseases"]
                    && patient_information["rheumatic_diseases"] === 'systemic_lupus_erythematosus'
                    && (
                        <div className={"follow-up-subsection mt-2"}>
                            <div className={"heading"}>Follow up: SLE Rheumatic Diseases</div>
                            <PatientCheckboxInput
                                label="Does the patient have positive (or unknown) antiphospholipid antibodies?"
                                name="rheumatic_diseases_sle_follow_up:antiphospholipid_antibodies"
                                value={patient_information["rheumatic_diseases_sle_follow_up:antiphospholipid_antibodies"]}
                                handleValueChange={handleBooleanValueChange}
                            />
                            <PatientCheckboxInput
                                label="Was the patient diagnosed with severe thrombocytopenia?"
                                name="rheumatic_diseases_sle_follow_up:severe_thrombocytopenia"
                                value={patient_information["rheumatic_diseases_sle_follow_up:severe_thrombocytopenia"]}
                                handleValueChange={handleBooleanValueChange}
                            />
                            <PatientCheckboxInput
                                label="Is the patient currently receiving immunosuppressive therapy for SLE?"
                                name="rheumatic_diseases_sle_follow_up:immunosuppressive_therapy"
                                value={patient_information["rheumatic_diseases_sle_follow_up:immunosuppressive_therapy"]}
                                handleValueChange={handleBooleanValueChange}
                            />
                        </div>
                    )}

                {patient_information["rheumatic_diseases"]
                    && patient_information["rheumatic_diseases"] === 'rheumatoid_arthritis'
                    && (
                        <div className={"follow-up-subsection mt-2"}>
                            <div className={"heading"}>Follow up: RA Rheumatic Diseases</div>
                            <PatientCheckboxInput
                                label="Is the patient currently receiving immunosuppressive therapy for rheumatoid arthritis?"
                                name="rheumatic_diseases_ra_follow_up:immunosuppressive_therapy"
                                value={patient_information["rheumatic_diseases_ra_follow_up:immunosuppressive_therapy"]}
                                handleValueChange={handleBooleanValueChange}
                            />
                        </div>
                    )}

                <PatientMultiCheckboxInput
                    label="Has the patient been diagnosed with, or ever experienced, any of the following neurological conditions?"
                    name="neurologic_conditions"
                    handleValueChange={handleCheckboxListValueChange}
                    value={patient_information["neurologic_conditions"]}
                    choices={FormLists.NeurologicConditionsEnumList}
                />
                {patient_information["neurologic_conditions"]
                    && patient_information["neurologic_conditions"].includes('multiple_sclerosis')
                    && (
                        <div className={"follow-up-subsection mt-2"}>
                            <div className={"heading"}>Follow up: Neurologic Conditions</div>
                            <PatientCheckboxInput
                                label="Does the patient experience prolonged immobilization? This means any extended bedrest or lack of moving around by walking for most of an 8 hour period or longer. This could be due to chronic conditions, frequent long distance travel, acute conditions, surgery, etc."
                                name="neurologic_conditions_follow_up:multiple_sclerosis_immobilization"
                                value={patient_information["neurologic_conditions_follow_up:multiple_sclerosis_immobilization"]}
                                handleValueChange={handleBooleanValueChange}
                            />
                        </div>
                    )}
                <PatientMultiCheckboxInput
                    label="Does the patient have any of the following mood disorders?"
                    name="mood_disorders"
                    handleValueChange={handleCheckboxListValueChange}
                    value={patient_information["mood_disorders"]}
                    choices={FormLists.MoodDisordersEnumList}
                />
                <PatientMultiCheckboxInput
                    label="Has the patient experienced symptoms similar to, but have not yet been diagnosed with any of the following mood disorders?"
                    name="mood_disorders_undiagnosed"
                    handleValueChange={handleCheckboxListValueChange}
                    value={patient_information["mood_disorders_undiagnosed"]}
                    choices={FormLists.MoodDisordersEnumList}
                />
                {patient_information["mood_disorders"]
                    && patient_information["mood_disorders"].includes('other')
                    && (
                        <PatientTextInput
                            label="Please specify the other mood disorder"
                            name="other_mood_disorder"
                            value={patient_information["other_mood_disorder"]}
                            handleValueChange={handleTextValueChange}
                            required
                        />
                    )}
                <PatientMultiCheckboxInput
                    label="Does the patient have any of the following Reproductive Condition and Disorders?"
                    name="reproductive_tract_infections"
                    handleValueChange={handleCheckboxListValueChange}
                    value={patient_information["reproductive_tract_infections"]}
                    choices={FormLists.ReproductiveTractInfectionsEnumList}
                />
                <PatientSelectInput
                    label="Does the patient have any known cancers?"
                    name="cancer"
                    handleValueChange={handleSelectValueChange}
                    value={patient_information["cancer"]}
                    required={false}
                >
                    <option value="breast_cancer">Breast cancer</option>
                    <option value="liver_tumor">Liver tumor</option>
                </PatientSelectInput>
                {patient_information["cancer"]
                    && patient_information["cancer"] == 'breast_cancer'
                    && (
                        <div className={"follow-up-subsection mt-2"}>
                            <div className={"heading"}>Follow up: Cancer</div>
                            <PatientSelectInput
                                label="What is the status of the patient's breast disease?"
                                name="cancer_follow_up:breast_disease_status"
                                handleValueChange={handleSelectValueChange}
                                value={patient_information["cancer_follow_up:breast_disease_status"]}
                                required
                            >
                                <option value="undiagnosed_mass">Undiagnosed mass</option>
                                <option value="benign_breast_disease">Benign breast disease</option>
                                <option value="current_breast_cancer">Current breast cancer</option>
                                <option value="past_breast_cancer">Past breast cancer</option>
                            </PatientSelectInput>
                        </div>
                    )}
                <PatientCheckboxInput
                    label="Has the patient ever been diagnosed with HIV?"
                    name="hiv"
                    value={patient_information["hiv"]}
                    handleValueChange={handleBooleanValueChange}
                />
                {patient_information["hiv"] && (
                    <div className={"follow-up-subsection mt-2"}>
                        <div className={"heading"}>Follow up: HIV</div>
                        <PatientCheckboxInput
                            label="Is the patient receiving ARV therapy?"
                            name="hiv_follow_up:arv_therapy"
                            value={patient_information["hiv_follow_up:arv_therapy"]}
                            handleValueChange={handleBooleanValueChange}
                        />
                    </div>
                )}
                <PatientMultiCheckboxInput
                    label="Does the patient have any infections or infectious diseases?"
                    name="infections"
                    handleValueChange={handleCheckboxListValueChange}
                    value={patient_information["infections"]}
                    choices={FormLists.InfectionsEnumList}
                />
                <PatientMultiCheckboxInput
                    label="Does the patient have any endocrine conditions?"
                    name="endocrine_conditions"
                    handleValueChange={handleCheckboxListValueChange}
                    value={patient_information["endocrine_conditions"]}
                    choices={FormLists.EndocrineConditionsEnumList}
                />
                {patient_information["pregnant"]
                    && patient_information["endocrine_conditions"]
                    && (patient_information["endocrine_conditions"].includes('type_1_diabetes') || patient_information["endocrine_conditions"].includes('type_2_diabetes'))
                    && (
                        <div className={"follow-up-subsection mt-2"}>
                            <div className={"heading"}>Follow up: Pregnancy</div>
                            <PatientCheckboxInput
                                label="Has the patient previously had gestational diabetes?"
                                name="diabetes_pregnant_follow_up:gestational_diabetes"
                                value={patient_information["diabetes_pregnant_follow_up:gestational_diabetes"]}
                                handleValueChange={handleBooleanValueChange}
                            />
                        </div>
                    )}
                {patient_information["endocrine_conditions"]
                    && (patient_information["endocrine_conditions"].includes('type_1_diabetes') || patient_information["endocrine_conditions"].includes('type_2_diabetes'))
                    && (
                        <div className={"follow-up-subsection mt-2"}>
                            <div className={"heading"}>Follow up: Diabetes</div>
                            <PatientCheckboxInput
                                label="Has the patient had diabetes for more, or less, than 20 years?"
                                name="diabetes_follow_up:diabetes_lte_20_years"
                                value={patient_information["diabetes_follow_up:diabetes_lte_20_years"]}
                                handleValueChange={handleBooleanValueChange}
                            />
                            <PatientCheckboxInput
                                label="Does the patient experience nephropathy, retinopathy, or neuropathy?"
                                name="diabetes_follow_up:experience_nephropathy_retinopathy_neuropathy"
                                value={patient_information["diabetes_follow_up:experience_nephropathy_retinopathy_neuropathy"]}
                                handleValueChange={handleBooleanValueChange}
                            />
                        </div>
                    )}
                <PatientMultiCheckboxInput
                    label="Does the patient have any GI conditions?"
                    name="gi_conditions"
                    handleValueChange={handleCheckboxListValueChange}
                    value={patient_information["gi_conditions"]}
                    choices={FormLists.GiConditionsEnumList}
                />
                {patient_information["gi_conditions"]
                    && patient_information["gi_conditions"].includes("gallbladder_disease")
                    && (
                        <div className={"follow-up-subsection mt-2"}>
                            <div className={"heading"}>Follow up: Gallbladder Disease</div>
                            <PatientCheckboxInput
                                label="Is the patient symptomatic with their gallbladder disease?"
                                name="gi_conditions_gallbladder_follow_up:gallbladder_disease"
                                value={patient_information["gi_conditions_gallbladder_follow_up:gallbladder_disease"]}
                                handleValueChange={handleBooleanValueChange}
                            />
                        </div>
                    )}
                {patient_information["gi_conditions"]
                    && patient_information["gi_conditions"].includes("viral_hepatitis")
                    && (
                        <div className={"follow-up-subsection mt-2"}>
                            <div className={"heading"}>Follow up: Viral Hepatitis</div>
                            <PatientSelectInput
                                label="What is the status of the patient's viral hepatitis?"
                                name="gi_conditions_viral_hepatitis_follow_up:viral_hepatitis_status"
                                handleValueChange={handleSelectValueChange}
                                value={patient_information["gi_conditions_viral_hepatitis_follow_up:viral_hepatitis_status"]}
                                required
                            >
                                <option value="acute">Acute</option>
                                <option value="carrier">Carrier</option>
                                <option value="chronic">Chronic</option>
                                <option value="not_present">Not present</option>
                            </PatientSelectInput>
                        </div>
                    )}
                {patient_information["gi_conditions"]
                    && patient_information["gi_conditions"].includes("liver_tumors")
                    && (
                        <div className={"follow-up-subsection mt-2"}>
                            <div className={"heading"}>Follow up: Liver Tumors</div>
                            <PatientSelectInput
                                label="Which liver tumor does the patient have?"
                                name="gi_conditions_liver_tumors_follow_up:liver_tumor"
                                handleValueChange={handleSelectValueChange}
                                value={patient_information["gi_conditions_liver_tumors_follow_up:liver_tumor"]}
                                required
                            >
                                <option value="focal_nodular_hyperplasia">Focal nodular hyperplasia</option>
                                <option value="hepatocellular_adenoma">Hepatocellular adenoma</option>
                                <option value="hepatoma">Hepatoma</option>
                                <option value="dont_know">Dont know</option>
                            </PatientSelectInput>
                        </div>
                    )}
                <PatientCheckboxInput
                    label="Does the patient have cystic fibrosis?"
                    name="cystic_fibrosis"
                    value={patient_information["cystic_fibrosis"]}
                    handleValueChange={handleBooleanValueChange}
                />
                <PatientMultiCheckboxInput
                    label="Does the patient have any of the following anemias?"
                    name="anemias"
                    handleValueChange={handleCheckboxListValueChange}
                    value={patient_information["anemias"]}
                    choices={FormLists.AnemiaEnumList}
                />
                <PatientCheckboxInput
                    label="Has the patient ever had a solid organ transplantation?"
                    name="solid_organ_transplantation"
                    value={patient_information["solid_organ_transplantation"]}
                    handleValueChange={handleBooleanValueChange}
                />
                {patient_information["solid_organ_transplantation"] && (
                    <div className={"follow-up-subsection mt-2"}>
                        <div className={"heading"}>Follow up: Organ Transplant</div>
                        <PatientCheckboxInput
                            label="Did the patient experience any complications with their organ transplant?"
                            name="solid_organ_transplantation_follow_up:complications"
                            value={patient_information["solid_organ_transplantation_follow_up:complications"]}
                            handleValueChange={handleBooleanValueChange}
                        />
                    </div>
                )}
            </CategoryWithInformation>

            <CategoryWithInformation
                category="Family History"
            >
                <PatientCheckboxInput
                    label="Does the patient have a family history of any of heart disease?"
                    name="family_history:cardio"
                    value={patient_information["family_history:cardio"]}
                    handleValueChange={handleBooleanValueChange}
                />
                <PatientCheckboxInput
                    label="Does the patient have a first degree relative with high blood pressure?"
                    name="family_history:htn"
                    value={patient_information["family_history:htn"]}
                    handleValueChange={handleBooleanValueChange}
                />
                <PatientCheckboxInput
                    label="Does the patient have a first degree relative with diabetes?"
                    name="family_history:diabetes"
                    value={patient_information["family_history:diabetes"]}
                    handleValueChange={handleBooleanValueChange}
                />
                <PatientCheckboxInput
                    label="Does the patient have a first degree relative with cancer?"
                    name="family_history:cancer"
                    value={patient_information["family_history:cancer"]}
                    handleValueChange={handleBooleanValueChange}
                />
                <PatientCheckboxInput
                    label="Does the patient have a first degree relative that has previously experienced strokes?"
                    name="family_history:stroke"
                    value={patient_information["family_history:stroke"]}
                    handleValueChange={handleBooleanValueChange}
                />
                <PatientCheckboxInput
                    label="Does the patient have a first degree relative with any thyroid disorder?"
                    name="family_history:thyroid_disorders"
                    value={patient_information["family_history:thyroid_disorders"]}
                    handleValueChange={handleBooleanValueChange}
                />
                <PatientCheckboxInput
                    label="Does the patient have a first degree relative with any mental health disorders?"
                    name="family_history:mental_health"
                    value={patient_information["family_history:mental_health"]}
                    handleValueChange={handleBooleanValueChange}
                />
                <PatientCheckboxInput
                    label="Does the patient have a first degree relative that has deep vein thrombosis or experienced a pulmonary embolism?"
                    name="family_history:thrombosis"
                    value={patient_information["family_history:thrombosis"]}
                    handleValueChange={handleBooleanValueChange}
                />
            </CategoryWithInformation>

            <CategoryWithInformation
                category="Labs"
                classNames={["last"]}
            >
                <div className={"follow-up-subsection mt-2"}>
                    <div className={"heading"}>What is their most recent blood pressure reading?</div>
                    <PatientTextInput
                        label="Systolic (mm Hg)"
                        name="recent_blood_pressure:systolic"
                        type="number"
                        value={patient_information["recent_blood_pressure:systolic"]}
                        handleValueChange={handleNumberValueChange}
                        required
                    />
                    <PatientTextInput
                        label="Diastolic (mm Hg)"
                        name="recent_blood_pressure:diastolic"
                        type="number"
                        value={patient_information["recent_blood_pressure:diastolic"]}
                        handleValueChange={handleNumberValueChange}
                        required
                    />
                </div>

                <div className={"follow-up-subsection mt-2"}>
                    <div className={"heading"}>Labs</div>
                    <div>Note: Lab results follow a specific format. When in doubt, copy the values from another patient and modify the values before entering them in. Do not change the units.</div>
                    <div className={"follow-up-subsection mt-2"}>
                        <div className={"heading"}>Lab Results</div>
                        <div className={"follow-up-subsection mt-2"}>
                            <div className={"heading"}>DHEA</div>
                            <PatientTextInput
                                label="Results"
                                name="lab_details:lab_results:dhea:results"
                                value={patient_information["lab_details:lab_results:dhea:results"]}
                                handleValueChange={handleTextValueChange}
                                required
                            />
                            <PatientTextInput
                                label="Flags ('-' means none)"
                                name="lab_details:lab_results:dhea:flags"
                                value={patient_information["lab_details:lab_results:dhea:flags"]}
                                handleValueChange={handleTextValueChange}
                                required
                            />
                        </div>
                        <div className={"follow-up-subsection mt-2"}>
                            <div className={"heading"}>Testosterone</div>
                            <PatientTextInput
                                label="Results"
                                name="lab_details:lab_results:testosterone:results"
                                value={patient_information["lab_details:lab_results:testosterone:results"]}
                                handleValueChange={handleTextValueChange}
                                required
                            />
                            <PatientTextInput
                                label="Flags ('-' means none)"
                                name="lab_details:lab_results:testosterone:flags"
                                value={patient_information["lab_details:lab_results:testosterone:flags"]}
                                handleValueChange={handleTextValueChange}
                                required
                            />
                        </div>
                        <div className={"follow-up-subsection mt-2"}>
                            <div className={"heading"}>TSH with reflex to T4</div>
                            <PatientTextInput
                                label="Results"
                                name="lab_details:lab_results:tsh_r_t4:results"
                                value={patient_information["lab_details:lab_results:tsh_r_t4:results"]}
                                handleValueChange={handleTextValueChange}
                                required
                            />
                            <PatientTextInput
                                label="Flags ('-' means none)"
                                name="lab_details:lab_results:tsh_r_t4:flags"
                                value={patient_information["lab_details:lab_results:tsh_r_t4:flags"]}
                                handleValueChange={handleTextValueChange}
                                required
                            />
                        </div>
                        <div className={"follow-up-subsection mt-2"}>
                            <div className={"heading"}>AMH</div>
                            <PatientTextInput
                                label="Results"
                                name="lab_details:lab_results:amh:results"
                                value={patient_information["lab_details:lab_results:amh:results"]}
                                handleValueChange={handleTextValueChange}
                                required
                            />
                            <PatientTextInput
                                label="Flags ('-' means none)"
                                name="lab_details:lab_results:amh:flags"
                                value={patient_information["lab_details:lab_results:amh:flags"]}
                                handleValueChange={handleTextValueChange}
                                required
                            />
                        </div>
                        <div className={"follow-up-subsection mt-2"}>
                            <div className={"heading"}>E2 (Estradiol)</div>
                            <PatientTextInput
                                label="Results"
                                name="lab_details:lab_results:e2:results"
                                value={patient_information["lab_details:lab_results:e2:results"]}
                                handleValueChange={handleTextValueChange}
                                required
                            />
                            <PatientTextInput
                                label="Flags ('-' means none)"
                                name="lab_details:lab_results:e2:flags"
                                value={patient_information["lab_details:lab_results:e2:flags"]}
                                handleValueChange={handleTextValueChange}
                                required
                            />
                        </div>
                        <div className={"follow-up-subsection mt-2"}>
                            <div className={"heading"}>Pregnancy Test (HCG Levels)</div>
                            <PatientTextInput
                                label="Results"
                                name="lab_details:lab_results:hgc:results"
                                value={patient_information["lab_details:lab_results:hgc:results"]}
                                handleValueChange={handleTextValueChange}
                                required
                            />
                            <PatientTextInput
                                label="Flags ('-' means none)"
                                name="lab_details:lab_results:hgc:flags"
                                value={patient_information["lab_details:lab_results:hgc:flags"]}
                                handleValueChange={handleTextValueChange}
                                required
                            />
                        </div>
                        <div className={"follow-up-subsection mt-2"}>
                            <div className={"heading"}>AST/ALT</div>
                            <PatientTextInput
                                label="Results"
                                name="lab_details:lab_results:ast_alt:results"
                                value={patient_information["lab_details:lab_results:ast_alt:results"]}
                                handleValueChange={handleTextValueChange}
                                required
                            />
                            <PatientTextInput
                                label="Flags ('-' means none)"
                                name="lab_details:lab_results:ast_alt:flags"
                                value={patient_information["lab_details:lab_results:ast_alt:flags"]}
                                handleValueChange={handleTextValueChange}
                                required
                            />
                        </div>
                        <div className={"follow-up-subsection mt-2"}>
                            <div className={"heading"}>Prolactin Level</div>
                            <PatientTextInput
                                label="Results"
                                name="lab_details:lab_results:prolactin:results"
                                value={patient_information["lab_details:lab_results:prolactin:results"]}
                                handleValueChange={handleTextValueChange}
                                required
                            />
                            <PatientTextInput
                                label="Flags ('-' means none)"
                                name="lab_details:lab_results:prolactin:flags"
                                value={patient_information["lab_details:lab_results:prolactin:flags"]}
                                handleValueChange={handleTextValueChange}
                                required
                            />
                        </div>
                        <div className={"follow-up-subsection mt-2"}>
                            <div className={"heading"}>Glucose/Hemoglobin A1C</div>
                            <PatientTextInput
                                label="Results"
                                name="lab_details:lab_results:glucose_hemoglobin_a1c:results"
                                value={patient_information["lab_details:lab_results:glucose_hemoglobin_a1c:results"]}
                                handleValueChange={handleTextValueChange}
                                required
                            />
                            <PatientTextInput
                                label="Flags ('-' means none)"
                                name="lab_details:lab_results:glucose_hemoglobin_a1c:flags"
                                value={patient_information["lab_details:lab_results:glucose_hemoglobin_a1c:flags"]}
                                handleValueChange={handleTextValueChange}
                                required
                            />
                        </div>
                        <div className={"follow-up-subsection mt-2"}>
                            <div className={"heading"}>CBC</div>
                            <PatientStringListInput
                                label="Results"
                                name="lab_details:lab_results:cbc:results"
                                value={patient_information["lab_details:lab_results:cbc:results"]}
                                handleValueChange={handleStringListValueChange}
                                required
                            />
                            <PatientTextInput
                                label="Flags ('-' means none)"
                                name="lab_details:lab_results:cbc:flags"
                                value={patient_information["lab_details:lab_results:cbc:flags"]}
                                handleValueChange={handleTextValueChange}
                                required
                            />
                        </div>
                        <div className={"follow-up-subsection mt-2"}>
                            <div className={"heading"}>FSH</div>
                            <PatientTextInput
                                label="Results"
                                name="lab_details:lab_results:fsh:results"
                                value={patient_information["lab_details:lab_results:fsh:results"]}
                                handleValueChange={handleTextValueChange}
                                required
                            />
                            <PatientTextInput
                                label="Flags ('-' means none)"
                                name="lab_details:lab_results:fsh:flags"
                                value={patient_information["lab_details:lab_results:fsh:flags"]}
                                handleValueChange={handleTextValueChange}
                                required
                            />
                        </div>
                        <div className={"follow-up-subsection mt-2"}>
                            <div className={"heading"}>LH</div>
                            <PatientTextInput
                                label="Results"
                                name="lab_details:lab_results:lh:results"
                                value={patient_information["lab_details:lab_results:lh:results"]}
                                handleValueChange={handleTextValueChange}
                                required
                            />
                            <PatientTextInput
                                label="Flags ('-' means none)"
                                name="lab_details:lab_results:lh:flags"
                                value={patient_information["lab_details:lab_results:lh:flags"]}
                                handleValueChange={handleTextValueChange}
                                required
                            />
                        </div>
                        <div className={"follow-up-subsection mt-2"}>
                            <div className={"heading"}>LDL/HDL/TG</div>
                            <PatientStringListInput
                                label="Results (comma separated for LDL/HDL/TG)"
                                name="lab_details:lab_results:ldl_hdl_tg:results"
                                value={patient_information["lab_details:lab_results:ldl_hdl_tg:results"]}
                                handleValueChange={handleStringListValueChange}
                                required
                            />
                            <PatientStringListInput
                                label="Flags ('-' means none, comma separated for LDL/HDL/TG)"
                                name="lab_details:lab_results:ldl_hdl_tg:flags"
                                value={patient_information["lab_details:lab_results:ldl_hdl_tg:flags"]}
                                handleValueChange={handleStringListValueChange}
                                required
                            />
                        </div>
                    </div>
                </div>
            </CategoryWithInformation>
        </>
    );
}

export default function PatientForm({ patient_uuid }: { patient_uuid?: string | null }) {
    const [patient, setPatient] = useState(basePatientStateObject() as any); // TODO: probably shouldn't be 'any', but i'm not typing this out right now
    const [patientInformation, setPatientInformation] = useState(basePatientInformationStateObject() as any); // TODO: probably shouldn't be 'any', but i'm not typing this out right now
    const [pageHeader, setPageHeader] = useState('');
    const [submittingForm, setSubmittingForm] = useState(false);
    const [showPatientDisclaimer, setShowPatientDisclaimer] = useState(false);
    const [errors, setErrors] = useState("");
    const navigate = useNavigate();
    const [renderForm, setRenderForm] = useState(false);
    const backend_request = useFetch();

    if (patient_uuid && !patient['uuid']) {
        backend_request(getBackendUrl(`/api/patients/get/${patient_uuid}`), {
            method: 'GET',
        }).then((response) => {
            if (response.ok) {
                (async function (response: Response) {
                    const response_body = await response.json();
                    setPatientInformation(response_body['information']);
                    setPatient({ email: response_body['email'], uuid: response_body['uuid'] });
                    if (response_body['information']['name']) {
                        setPageHeader(response_body['information']['name']);
                    }
                    setShowPatientDisclaimer(true);
                    setRenderForm(true);
                })(response);
            }
        });
    }
    else if (!renderForm) {
        setPageHeader("New Patient");
        setShowPatientDisclaimer(true);
        setRenderForm(true);
    }

    // TODO: Reducers will likely simplify this, or make it worse. Consider it for the next iteration.
    const handlePatientEmailValueChange = (evt: React.FormEvent<HTMLInputElement>) => {
        setPatient({
            ...patient,
            email: evt.currentTarget.value,
        });
    }

    const handleTextValueChange = (evt: React.FormEvent<HTMLInputElement>) => {
        const name = evt.currentTarget.name;
        if (name in patientInformation) {
            let value = evt.currentTarget.value;
            setPatientInformation({
                ...patientInformation,
                [name]: value
            });
        }
        else {
            // console.log(`Error: ${name} field not found in state.`);
        }
    }

    const handleBooleanValueChange = (evt: React.FormEvent<HTMLInputElement>) => {
        const name = evt.currentTarget.name;
        if (name in patientInformation) {
            let value = evt.currentTarget.checked;
            setPatientInformation({
                ...patientInformation,
                [name]: value
            });
        }
        else {
            // console.log(`Error: ${name} field not found in state.`);
        }
    }

    const handleNumberValueChange = (evt: React.FormEvent<HTMLInputElement>) => {
        const name = evt.currentTarget.name;
        if (name in patientInformation) {
            let value = parseInt(evt.currentTarget.value);
            setPatientInformation({
                ...patientInformation,
                [name]: value
            });
        }
        else {
            // console.log(`Error: ${name} field not found in state.`);
        }
    }


    const handleCheckboxListValueChange = (evt: React.FormEvent<HTMLInputElement>) => {
        const name = evt.currentTarget.name;
        if (name in patientInformation) {
            let value;
            if (evt.currentTarget.checked) {
                // add
                value = patientInformation[name]
                value.push(evt.currentTarget.value);
            }
            else {
                // remove
                value = patientInformation[name].filter((val: string) => { return val !== evt.currentTarget.value; });
            }

            setPatientInformation({
                ...patientInformation,
                [name]: value
            });
        }
        else {
            // console.log(`Error: ${name} field not found in state.`);
        }
    }

    const handleStringListValueChange = (evt: React.FormEvent<HTMLInputElement>) => {
        const name = evt.currentTarget.name;
        if (name in patientInformation) {
            let value = evt.currentTarget.value.split(',').map((v) => { return v.trim(); }).filter((val: string) => { return val !== '' });
            setPatientInformation({
                ...patientInformation,
                [name]: value
            });
        }
        else {
            // console.log(`Error: ${name} field not found in state.`);
        }
    }

    const handleSelectValueChange = (evt: React.FormEvent<HTMLInputElement>) => {
        const name = evt.currentTarget.name;
        if (name in patientInformation) {
            let value = evt.currentTarget.value;
            setPatientInformation({
                ...patientInformation,
                [name]: value
            });
        }
        else {
            // console.log(`Error: ${name} field not found in state.`);
        }
    }

    async function postResponse(resp_promise: Promise<any>) {
        const resp = await resp_promise as Response;

        if (resp.ok) {
            const resp_body = await resp.json() as any;
            if (resp.status == 200) {
                navigate(`/d/providers/dashboard/patients`);
            }
            else if (resp.status == 201) {
                navigate(`/d/providers/dashboard/patients`);
            }
        }
        else if (resp.status == 500) {
            setErrors('An unknown error has occured. Please check the form values and try again.');
            window.scrollTo(0, 0);
            setSubmittingForm(false);
        }
        else {
            const resp_body = await resp.json() as any;
            if (resp_body && resp_body['errors']) {
                const errors = resp_body['errors'];

                setErrors(errors.join('\n'));
                window.scrollTo(0, 0);
                setSubmittingForm(false);
            }
        }
    }

    function handleSubmission(event: React.FormEvent<any>) {
        event.preventDefault();
        setSubmittingForm(true);

        const params = {
            patient: {
                ...patient
            },
            patient_information: {
                ...patientInformation
            },
        };
        let submit_path = '/api/patients/set'
        if (patient['uuid']) {
            submit_path = submit_path + `/${patient['uuid']}`
        }
        const resp = backend_request(getBackendUrl(submit_path), {
            method: 'POST',
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(params),
        });
        postResponse(resp);
    }

    return (
        <>
            <Container>
                <h1>
                    {pageHeader}
                    {/* {!patient_uuid && "New Patient"} */}
                    {/* {patient_uuid && patientInformation && patientInformation['name']} */}
                </h1>
                {showPatientDisclaimer && (
                    <div className="alert alert-primary d-flex align-items-center" role="alert">
                        <InfoSquare className="flex-shrink-0 me-4" size={32} />
                        <div>
                            Please note that this demonstration is specifically designed to assist in determining the best contraceptive formulation match for your patient. Our system, backed by precision medicine insights, provides a unique opportunity to enhance treatment efficacy and patient outcomes in this specific area.
                        </div>
                    </div>
                )}
                <Container style={{ paddingBottom: '20px' }}>
                    {errors && (
                        <Row>
                            <Alert key={'danger'} variant={'danger'}>
                                {errors}
                            </Alert>
                        </Row>
                    )}
                    {renderForm && (
                        <Form
                            id="patient_create_form"
                            onSubmit={handleSubmission}
                        >
                            <Row>
                                <Col>
                                    <LeftColFields
                                        patient={patient}
                                        patient_information={patientInformation}
                                        valueChangeHandlers={{
                                            handlePatientEmailValueChange: handlePatientEmailValueChange,
                                            handleTextValueChange: handleTextValueChange,
                                            handleBooleanValueChange: handleBooleanValueChange,
                                            handleNumberValueChange: handleNumberValueChange,
                                            handleCheckboxListValueChange: handleCheckboxListValueChange,
                                            handleStringListValueChange: handleStringListValueChange,
                                            handleSelectValueChange: handleSelectValueChange,
                                        }}
                                    />
                                </Col>
                            </Row>

                            <Button
                                as="input"
                                type="submit"
                                value={patient['uuid'] ? 'Submit' : 'Create'}
                                className="font-white"
                                data-testid="submit"
                                disabled={submittingForm}
                            />
                        </Form>
                    )}
                </Container>
            </Container>
        </>
    );
}