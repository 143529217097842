import { PropsWithChildren } from "react";
import { Form } from "react-bootstrap";

export type PatientSelectInputProps = {
    "label": string,
    "name": string,
    "value": string,
    handleValueChange: (...args: any[]) => void,
    "required"?: boolean,
    "choices"?: any,
};

export default function PatientSelectInput(props: PropsWithChildren<PatientSelectInputProps>) {
    const additional_form_options = {} as any;
    if (props.required) {
        additional_form_options['required'] = true;
    }

    return (
        <Form.Group className="mt-4">
            <Form.Label>
                {props.label}
            </Form.Label>
            <Form.Select
                aria-label="Default select example"
                name={props.name}
                onChange={props.handleValueChange}
                defaultValue={props.value}
                {...additional_form_options}
            >
                <option></option>
                {props.choices && props.choices.map((select_option: any) => {
                    return (
                        <option key={`${select_option.name}-${select_option.value}`} value={select_option.value}>{select_option.label}</option>
                    );
                })}
                {props.children}
            </Form.Select >
        </Form.Group>
    );
}