import { Form } from "react-bootstrap";

export default function PatientCheckboxInput({ label, name, value, handleValueChange, required = false }: { label: string, name: string, value: boolean, handleValueChange: (...args: any[]) => void, required?: boolean }) {
    const additional_form_options = {} as any;
    if (required) {
        additional_form_options['required'] = true;
    }
    return (
        <Form.Group className="mt-4">
            <Form.Check
                type="switch"
                id={`form-switch-${name}`}
                label={label}
                name={name}
                defaultChecked={value}
                onChange={handleValueChange}
                {...additional_form_options}
            />
        </Form.Group>
    );
}
