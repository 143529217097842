import { RedirectToSignIn, SignedIn, SignedOut, useClerk } from "@clerk/clerk-react";
import CelesteSignOutButton from "../../../components/SignOutButton";
import { Col, Container, Image, ListGroup, Row, Spinner } from "react-bootstrap";
import { Link, NavLink, useNavigate, useOutlet } from "react-router-dom";
import { useState } from "react";
import { useFetch } from "../../../helpers/clerk";
import { getBackendUrl } from "../../../utils";
import { DashboardIcon, PatientsIcon } from "../../../components/Icons";

const TopNav = ({ children }: { children: any }) => {
  return (
    <Row className="mb-4">
      <Col xs={11}>
        <div>{children}</div>
      </Col>
      <Col xs={1}>
        <div><CelesteSignOutButton /></div>
      </Col>
    </Row>
  );
}

const LeftNav = ({ children }: { children: any }) => {
  return (
    <Col
      style={{ background: '#E8ECFF', minHeight: '480px', paddingLeft: "12px", paddingRight: "12px", paddingTop: "28px" }}
    >
      <ul className="celeste left-nav">
        {children}
      </ul>
    </Col>
  );
}

const LeftNavItem = ({ text, href, icon }: { text: string, href: string, icon?: JSX.Element }) => {
  return (
    <li>
      <NavLink
        to={href}
        className={({ isActive, isPending }) => {
          return isPending ? "pending" : isActive ? "active" : ""
        }}
      >
        {icon} &nbsp;&nbsp; {text}
      </NavLink>
    </li>
  );
}

const LeftNavElement = ({ children, additionalStyles }: { children: any, additionalStyles?: object }) => {
  let styles = {};
  if (additionalStyles) {
    styles = {
      ...styles,
      ...additionalStyles
    };
  }

  return (
    <div style={styles}>
      {children}
    </div>
  );
}

const RightContents = ({ children }: { children: any }) => {
  return (
    <Col
      xs={10}
      style={{ background: '#FFF', paddingTop: '22px', paddingLeft: "24px", paddingRight: "24px" }}
      className="provider-content"
    >
      {children}
    </Col>
  );
}

export default function Index() {
  const outlet = useOutlet();
  const [loaded, setLoaded] = useState(false);
  const navigate = useNavigate();
  const backend_request = useFetch();

  // Block Non-providers from accessing this route

  if (!loaded) {
    backend_request(getBackendUrl('/api/auth/whoami'), {
      method: 'GET',
    }).then((response) => {
      if (response.ok) {
        (async function (response: Response) {
          const response_body = await response.json();
          if (response_body["user_type"] == "provider") {
            setLoaded(true);
            if (!outlet) {
              navigate('/d/providers/dashboard/home');
            }
          }
          else {
            navigate('/d');
          }
        })(response);
      }
      else {
        // TODO: An error here isn't supposed to just go straight back to the /d redirect
        navigate('/d');
      }
    });
  }

  return (
    <>
      <SignedOut>
        <RedirectToSignIn />
      </SignedOut>
      <SignedIn>
        {!loaded && <Spinner />}
        {loaded && (<>
          <Container fluid style={{ height: "100%" }}>
            <Row style={{ height: "100%" }}>
              <LeftNav>
                <Image className="mx-auto d-block" src={process.env.PUBLIC_URL + "/celeste.svg"} />
                {/* <LeftNavItem
                  text="Home"
                  href="/d/providers/dashboard/home"
                  icon={DashboardIcon()}
                /> */}
                {/* <LeftNavItem
                  text="My Organization"
                  href="my_organization"
                /> */}
                <LeftNavItem
                  text="All Patients"
                  href="patients"
                  icon={PatientsIcon()}
                />
                <LeftNavElement
                  additionalStyles={{ marginTop: "60px" }}
                >
                  <CelesteSignOutButton
                    className={['mx-auto', 'd-block']}
                  />
                </LeftNavElement>
              </LeftNav>
              <RightContents>
                {outlet}
              </RightContents>
            </Row>
          </Container>
        </>)}
      </SignedIn>
    </>
  );
}