import { useClerk } from "@clerk/clerk-react";
import { Button } from "react-bootstrap";

function CelesteSignOutButton({ className }: { className?: string[] }) {
    const { signOut } = useClerk();

    let classNames = ['btn-link', 'btn'];

    if (className) {
        classNames = [...classNames, ...className];
    }

    return (
        <Button className={classNames.join(' ')} onClick={() => signOut()} >
            Sign out
        </Button>
    );
};

export default CelesteSignOutButton;