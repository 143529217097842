import { Form } from "react-bootstrap";

type FormOptionsType = {
    type?: string,
    required?: boolean,
    placeholder?: string,
}

type PatientTextInputType = {
    label: string,
    name: string,
    value: string,
    handleValueChange: (...args: any[]) => void,
    type?: string,
    placeholder?: string,
    required?: boolean,
}

export default function PatientTextInput({ label, name, value, handleValueChange, type, placeholder = "", required = true }: PatientTextInputType) {
    const options = {} as FormOptionsType;
    if (type) {
        options["type"] = type;
    }
    if (required) {
        options["required"] = required;
    }
    if (placeholder) {
        options["placeholder"] = placeholder;
    }

    return (
        <Form.Group className="mt-4">
            <Form.Label>
                {label}
            </Form.Label>
            <Form.Control
                name={name}
                defaultValue={value}
                {...options}
                onChange={handleValueChange}
            />
        </Form.Group>
    );
}
