import { useLoaderData, useNavigate } from "react-router-dom"
import { getBackendUrl } from "../../../../../utils";
import { useFetch } from "../../../../../helpers/clerk";
import { useState } from "react";


export type LoaderData = {
    organization_id: number,
}

export async function loader({ params }: { params: { organization_id?: string } }) {
    return { organization_id: params.organization_id };
}

interface FormElements extends HTMLFormControlsCollection {
    email: HTMLInputElement,
    clerk_id: HTMLInputElement,
}
interface NewProviderFormElement extends HTMLFormElement {
    readonly elements: FormElements
}

export default function Create() {
    const data = useLoaderData() as LoaderData;
    const organization_id = data.organization_id;
    const backend_request = useFetch();
    const navigate = useNavigate();
    const [errors, setErrors] = useState('');

    async function postResponse(resp_promise: Promise<any>) {
        const resp = await resp_promise as Response;

        const resp_body = await resp.json() as any;

        if (resp.status == 201) {
            navigate(`/d/admin/organizations/${organization_id}`);
        }
        else {
            const errors = (resp_body as Array<Array<string>>).map((contents) => { return contents[1]; });

            setErrors(errors.join('\n'));
        }
    }

    function handleSubmission(event: React.FormEvent<NewProviderFormElement>) {
        event.preventDefault();

        const formElements = event.currentTarget.elements;
        const params = {
            organization_id: organization_id,
            email: formElements.email.value,
            clerk_id: formElements.clerk_id.value,
        };
        const resp = backend_request(getBackendUrl("/api/providers/create"), {
            method: 'POST',
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(params),
        });
        postResponse(resp);
    }

    return (
        <>
            <h1>Create New Provider</h1>
            <div>
                <form method="post" onSubmit={handleSubmission}>
                    <div>Email: <input type="text" name="email" data-testid="email"></input></div>
                    <div>Clerk ID: <input type="text" name="clerk_id" data-testid="clerk_id"></input></div>
                    <div>{errors}</div>
                    <button type="submit" data-testid="submit">Create</button>
                </form>
            </div>
        </>
    );
}
