import { PropsWithChildren } from 'react';
import Container from 'react-bootstrap/Container';

type Props = {
  "data-testid"?: string,
};

export default function GlobalContainer(props: PropsWithChildren<Props>) : JSX.Element {
  return (
    <Container
      className='celeste-global-container'
      fluid="xl"
      {...props["data-testid"] ? { "data-testid": props["data-testid"] } : {}}
    >
      {props.children}
    </Container>
  )
}