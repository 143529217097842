import { RedirectToSignIn, SignOutButton, SignedIn, SignedOut, useUser } from "@clerk/clerk-react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import GlobalContainer from "../components/GlobalContainer";

import { useFetch } from "../helpers/clerk";

// TODO: This file is really more of a placeholder for provider registration right now.
// None of the code here functions as intended, and should be heavily changed when we're working on provider registration.
export default function AfterSignUpPage(): JSX.Element {
  const { isSignedIn } = useUser();
  const navigate = useNavigate();

  const backend_request = useFetch();

  const handleFetchData = async () => {
    const resp =
      await backend_request("http://localhost:8000/api/auth/register", { method: 'POST' });
  }

  useEffect(() => {
    handleFetchData();
  }, [])


  return (
    <GlobalContainer>
      <SignedIn>
        <div>Hi, you're signed in</div>
        <div><SignOutButton /></div>
      </SignedIn>
      <SignedOut>
        <RedirectToSignIn />
      </SignedOut>
    </GlobalContainer>
  );
}