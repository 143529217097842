import { PropsWithChildren, useEffect, useState } from "react";
import { Col, Container, Row, Spinner } from "react-bootstrap";
import { Printer } from "react-bootstrap-icons";
import Fade from 'react-bootstrap/Fade';
import { useLoaderData } from "react-router-dom";
import { getMedicalRecommendation } from "../../../../helpers/datasets/provider/intake/datasets";

type TStyleProp = {
  style?: { [key: string]: string }
  className?: string
}

type LoaderData = {
  intakeId: string,
  recommendation: { [key: string]: [value: any] },
}

export async function loader({ params }: { params: { intakeId?: string } }) {
  const recommendation = getMedicalRecommendation(params.intakeId ? params.intakeId : 'nope')
  if (recommendation == null) {
    throw new Response("Not Found", {
      status: 404,
      statusText: "Not Found",
    });
  };

  return { intakeId: params.intakeId, recommendation: recommendation };
}

const RRow = (props: PropsWithChildren<TStyleProp>): JSX.Element => {
  let styleProps: { [key: string]: any; } = {};
  let classNames = props.className ? props.className : "";
  if (props.style) {
    styleProps['style'] = props.style;
  }

  return (
    <Row
      className={["content", classNames].join(' ')}
      {...styleProps}
    >
      {props.children}
    </Row>
  );
}

export default function IntakeReport() {
  const data = useLoaderData() as LoaderData;
  const rec = data.recommendation;
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setOpen(true);
    }, 2500);
  }, []);

  return (
    <>
      <Row>
        <Col s={10} style={{ flexGrow: "11" }} className="d-flex justify-content-start align-items-center">
          <h1
            className="celeste">
            Medication Recommendation Report
          </h1>
        </Col>
        <Col s={2} className="d-flex justify-content-end align-items-center">
          <Printer
            className="fakebutton"
            size={32}
            onClick={() => { window.print(); }}
            role="button"
            arial-label="Print generated report"
          />
        </Col>
      </Row>
      {!open && (
        <Row className="d-flex justify-content-center align-items-center" style={{ minHeight: "200px" }}>
          <Spinner
            id="js-screen-loading"
          />
        </Row>
      )}

      <Fade in={open}>
        <Container
          className={["intake-report", open ? '' : 'd-none'].join(' ')}
          style={{
            borderTop: "2px solid #C3C3C5",
            marginTop: "12px",
            paddingTop: "20px",
          }}
        >
          <RRow>
            <div>
              <strong>Patient Information:</strong>
            </div>
            <ul>
              <li>Name: {rec.name}</li>
              <li>Age: {rec.age}</li>
              <li>Gender: {rec.gender}</li>
              <li>Location: {rec.location}</li>
            </ul>
          </RRow>
          <RRow>
            <div>
              <strong>Patient Goals:</strong>
            </div>
            <ol>
              {rec.goals.map((val: string, index) => <li key={`pg-${index}`}>{val}</li>)}
            </ol>
          </RRow>
          <RRow>
            <div>
              <strong>Medication Recommendations:</strong>
            </div>
            <div>
              Based on the biomarker analysis and pharmacometabolomic relationships, the following stack-ranked list of birth control options is provided. Each option has been determined by a prediction of medication adherence and the frequency of potential side effects:
            </div>
            <div className="mt-4">
              <ol>
                {rec.medical_recommendations.map((val: any, index) => {
                  return (
                    <li key={`mrm-${index}`}>
                      {val.m}
                      <ul>
                        <li key={`mrs-${index}`}>
                          {val.s}
                        </li>
                      </ul>
                    </li>
                  );
                })}
              </ol>
            </div>
          </RRow>
          <RRow>
            <div>
              <strong>Potential Side Effects:</strong>
            </div>
            <ul>
              {rec.side_effects.map((val: string, index) => {
                return (
                  <li key={`se-{index}`}>{val}</li>
                );
              })}
            </ul>
          </RRow>
          <RRow>
            <div>
              <strong>Lifestyle Changes and Supplements:</strong>
            </div>
            <ul>
              {rec.lifestyle_changes.map((val: string, index) => {
                return (
                  <li key={`lc-{index}`}>{val}</li>
                );
              })}
            </ul>
          </RRow>
          <RRow>
            <div>
              <strong>Final Remarks: </strong>
            </div>
            <div>
              {rec.final_remarks}
            </div>
          </RRow>
          <RRow className="mt-4">
            <div>
              Sign-off: Dr. Gina Cocos, PharmD
            </div>
          </RRow>
          <RRow className="mt-4">
            <div>
              <strong>Clinical Research References:</strong>
            </div>
            <div>
              <a target="_blank" rel="noreferrer" href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC2923944/">Drospirenone for the treatment of acne</a>
            </div>
            <div>
              <a target="_blank" rel="noreferrer" href="https://pubmed.ncbi.nlm.nih.gov/17332038/">Oral contraceptives in the treatment of polycystic ovary syndrome</a>
            </div>
            <div>
              <a target="_blank" rel="noreferrer" href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC4171909/">Hormonal interventions for acne treatment</a>
            </div>
          </RRow>
        </Container>
      </Fade>
    </>
  );
}
