import { useState } from 'react';
import Column from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { Link } from 'react-router-dom';
import LegalText from '../../components/LegalText';
import { legal_navigation_configuration, legal_pages_configuration, legal_sections } from '../../constants';

const all_values_acceptable_by_user = [];
for (const v of Object.values(legal_pages_configuration)) {
  all_values_acceptable_by_user.push(v.action.value);
}

export default function Legal({ index }: { index: number }) {
  const navigation_configuration = legal_navigation_configuration[index];

  const [privacyGroup, setPrivacyGroup] = useState<string[]>([]);
  const [termsConditionsGroup, setTermsConditionsGroup] = useState<string[]>([]);
  const [consentDisclosureGroup, setConsentDisclosureGroup] = useState<string[]>([]);
  const [hipaaStatementGroup, setHipaaaStatementGroup] = useState<string[]>([]);

  // Check if the user can proceed
  let nextEnabled = false;
  const valuesRequired: string[] = [];
  navigation_configuration.sections.forEach((section) => {
    valuesRequired.push(legal_pages_configuration[section].action.value);
  });

  const acceptedValues = [...privacyGroup, ...termsConditionsGroup, ...consentDisclosureGroup, ...hipaaStatementGroup].filter((val) => val !== "");
  if (valuesRequired.every((val) => acceptedValues.includes(val))) {
    nextEnabled = true;
  }
  const nextVisible = nextEnabled ? "visible" : "invisible";
  const nextLink = navigation_configuration.next_index === null ?
    '/intake/chat' : `/legal/${navigation_configuration.next_index}`;

  const handlePrivacyGroupChange = (val: string[]) => setPrivacyGroup(val);
  const handleTermsConditionsGroupChange = (val: string[]) => setTermsConditionsGroup(val);
  const handleConsentDisclosureGroupChange = (val: string[]) => setConsentDisclosureGroup(val);
  const handleHipaaStatementGroupChange = (val: string[]) => setHipaaaStatementGroup(val);

  return (
    <>
      {navigation_configuration.sections.includes(legal_sections.PRIVACY) && (
        <LegalText
          section={legal_sections.PRIVACY}
          defaultValue={privacyGroup}
          onValueChange={handlePrivacyGroupChange}
        />
      )}

      {navigation_configuration.sections.includes(legal_sections.TERMS_CONDITIONS) && (
        <LegalText
          section={legal_sections.TERMS_CONDITIONS}
          defaultValue={termsConditionsGroup}
          onValueChange={handleTermsConditionsGroupChange}
        />
      )}

      {navigation_configuration.sections.includes(legal_sections.CONSENT_DISCLOSURE) && (
        <LegalText
          section={legal_sections.CONSENT_DISCLOSURE}
          defaultValue={consentDisclosureGroup}
          onValueChange={handleConsentDisclosureGroupChange}
        />
      )}

      {navigation_configuration.sections.includes(legal_sections.HIPAA_STATEMENT) && (
        <LegalText
          section={legal_sections.HIPAA_STATEMENT}
          defaultValue={hipaaStatementGroup}
          onValueChange={handleHipaaStatementGroupChange}
        />
      )}

      <Row className='mt-4'>
        <Column className="d-inline-flex justify-content-center">
          <Link
            className={['btn btn-primary btn-lg', nextVisible].join(' ')}
            data-testid="legal-next"
            to={nextLink}
            onClick={(e) => {
              if (!nextEnabled) {
                e.preventDefault();
              };
            }}
          >
            Let's Go
          </Link>
        </Column>
      </Row>
    </>
  )
}