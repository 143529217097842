import { TLegalTextConfigurationObject } from "./types";


export class LegalTextConfiguration {
  id: string;
  title: string;
  text: string[];
  action: {
    label: string,
    value: string,
  };
  learn_more: {
    label: string,
    a: string,
  };

  on_value_change: (value: any[]) => void;

  constructor(legal_text_configuration_object: TLegalTextConfigurationObject) {
    this.id = legal_text_configuration_object.id;
    this.title = legal_text_configuration_object.title;
    this.text = legal_text_configuration_object.text;
    this.action = legal_text_configuration_object.action;
    this.learn_more = legal_text_configuration_object.learn_more;
    this.on_value_change = legal_text_configuration_object.on_value_change;
  }
}