import { Col, Container, Row } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import { Link } from 'react-router-dom';

type TStyledCard = {
    img_src?: string | null,
    title: string,
    text: string,
    button_options?: {
        to?: string,
        disabled?: boolean
    }
}

function StyledCard({ img_src = null, title, text, button_options }: TStyledCard) {
    let to = {} as any;
    if (button_options && button_options['to']) {
        to['to'] = button_options['to']
    }
    let className = "btn btn-primary"
    if (button_options && button_options['disabled']) {
        className = `${className} disabled`;
    }

    return (
        <Card style={{ width: 'auto', height: '100%' }}>
            {img_src && (
                <Card.Img variant="top" src={img_src} />
            )}
            <Card.Body>
                <Card.Title>{title}</Card.Title>
                <Card.Text>
                    {text}
                </Card.Text>
                <Link
                    className={className}
                    {...to}
                >Go
                </Link>
            </Card.Body>
        </Card>
    );
}

export function Dashboard() {
    return (
        <Container>
            <h1>Welcome</h1>

            <Container>
                <Row>
                    <Col>
                        <StyledCard
                            title='Patients'
                            text='View your patients'
                            button_options={{ to: '/d/providers/dashboard/patients' }}
                        />
                    </Col>

                    <Col>
                        <StyledCard
                            title='Other actions'
                            text='Another thing that a provider might want to do.'
                            button_options={{ disabled: true }}
                        />
                    </Col>
                </Row>
            </Container>
        </Container>
    );
};