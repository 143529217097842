import { Form } from "react-bootstrap";

export default function PatientMultiCheckboxInput({ label, name, value, handleValueChange, choices }: { label: string, name: string, value: string[], handleValueChange: (...args: any[]) => void, choices: any[] }) {
    if (!value) {
        value = [];
    }
    return (
        <Form.Group className="mt-4">
            <div className="form-label">{label}</div>
            {choices.map((choice: any) => {
                return (
                    <Form.Check
                        type="checkbox"
                        key={`form-checkbox-${name}-${choice.value}`}
                        id={`form-checkbox-${name}-${choice.value}`}
                        label={choice['label']}
                        name={name}
                        defaultChecked={value.indexOf(choice.value) > -1}
                        value={choice.value}
                        onChange={handleValueChange}
                    />
                );
            })}
        </Form.Group>
    );
}
