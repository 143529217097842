// @ts-nocheck
import { useAuth, useUser } from "@clerk/clerk-react";

enum templates {
  withusers = 'withusers',
}

export function useFetch() {
  const { getToken } = useAuth();

  const authenticatedFetch = async (request, args) => {
    const token = await getToken({template: templates.withusers});

    if (args) {
      if (args.headers) {
        args.headers.Authorization = `Bearer ${token}`;
      }
      else {
        args.headers = {
          Authorization: `Bearer ${token}`,
        };
      }
    }
    else {
      args = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
    }

    return fetch(request, args).then(res => res);
  };

  return authenticatedFetch;
}

export const clerkUseUser = () => {
  return useUser;
}