import { useNavigate } from "react-router-dom"
import { getBackendUrl } from "../../../../utils";
import { useFetch } from "../../../../helpers/clerk";
import { useState } from "react";


interface FormElements extends HTMLFormControlsCollection {
    name: HTMLInputElement,
    unique_key: HTMLInputElement,
}
interface NewOrganizationFormElement extends HTMLFormElement {
    readonly elements: FormElements
}

export default function Create() {
    const backend_request = useFetch();
    const navigate = useNavigate();
    const [errors, setErrors] = useState('');

    async function postResponse(resp_promise: Promise<any>) {
        const resp = await resp_promise as Response;

        const resp_body = await resp.json() as any;

        if (resp.status == 201) {
            navigate(`/d/admin/organizations/${resp_body && resp_body.id}`);
        }
        else {
            const errors = (resp_body as Array<Array<string>>).map((contents) => { return contents[1]; });

            setErrors(errors.join('\n'));
        }
    }

    function handleSubmission(event: React.FormEvent<NewOrganizationFormElement>) {
        event.preventDefault();

        const formElements = event.currentTarget.elements;
        const params = {
            name: formElements.name.value,
            unique_key: formElements.unique_key.value,
        };
        const resp = backend_request(getBackendUrl("/api/organizations/create"), {
            method: 'POST',
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(params),
        });
        postResponse(resp);
    }

    return (
        <>
            <h1>Create New Organization</h1>
            <div>
                <form method="post" onSubmit={handleSubmission}>
                    <div>Name: <input type="text" name="name" data-testid="name"></input></div>
                    <div>Unique Key: <input type="text" name="unique_key" data-testid="unique_key"></input></div>
                    <div>{errors}</div>
                    <button type="submit" data-testid="submit">Create</button>
                </form>
            </div>
        </>
    );
}
