import { RedirectToSignIn, SignedIn, SignedOut, useClerk } from "@clerk/clerk-react";
import GlobalContainer from "../components/GlobalContainer";
import { Button } from "react-bootstrap";

// TODO: This file can and should be removed.

// TODO: Code below is used for testing what the backend gets.
// Remove this when backend is ready.
// import { useEffect } from "react";
// import { useFetch } from "../helpers/clerk";

const SignOutButton = () => {
  const { signOut } = useClerk();

  return (
    <Button className='btn btn-secondary btn-sm' onClick={() => signOut()} >
      Sign out
    </Button>
  );
};

export default function AuthMe() {
  // TODO: Code below is used for testing what the backend gets.
  // Remove this when backend is ready.

  // const backend_request = useFetch();

  // const handleFetchData = async () => {
  //   const response_json =
  //     await backend_request("http://localhost:8000/api/v1/authme", { method: 'GET' });
  // }

  // useEffect(() => {
  //   handleFetchData();
  // }, [])

  return (
    <GlobalContainer>
      <SignedIn>
        <div>Hi, you're signed in</div>
        <div><SignOutButton /></div>
      </SignedIn>
      <SignedOut>
        <RedirectToSignIn />
      </SignedOut>
    </GlobalContainer>
  );
}