import { Enum } from "./enums";

const ttable = {
  // Pills
  [Enum.PillBrandsEnum.altavera]: "Altavera",
  [Enum.PillBrandsEnum.alyacen_1_35]: "Alyacen 1/35",
  [Enum.PillBrandsEnum.amethia]: "Amethia",
  [Enum.PillBrandsEnum.amethia_lo]: "Amethia Lo",
  [Enum.PillBrandsEnum.amethyst]: "Amethyst",
  [Enum.PillBrandsEnum.apri]: "Apri",
  [Enum.PillBrandsEnum.aranelle]: "Aranelle",
  [Enum.PillBrandsEnum.ashlyna]: "Ashlyna",
  [Enum.PillBrandsEnum.aubra_eq]: "Aubra Eq",
  [Enum.PillBrandsEnum.aurovela_fe_1_5_30]: "Aurovela Fe 1.5/30",
  [Enum.PillBrandsEnum.aurovela_fe_1_20]: "Aurovela Fe 1/20",
  [Enum.PillBrandsEnum.aviane]: "Aviane",
  [Enum.PillBrandsEnum.azurette]: "Azurette",
  [Enum.PillBrandsEnum.balziva]: "Balziva",
  [Enum.PillBrandsEnum.beyaz]: "Beyaz",
  [Enum.PillBrandsEnum.blisovi_24_fe]: "Blisovi 24 Fe",
  [Enum.PillBrandsEnum.blisovi_fe_1_5_30]: "Blisovi Fe 1.5/30",
  [Enum.PillBrandsEnum.blisovi_fe_1_20]: "Blisovi Fe 1/20",
  [Enum.PillBrandsEnum.briellyn]: "Briellyn",
  [Enum.PillBrandsEnum.camila]: "Camila",
  [Enum.PillBrandsEnum.camrese]: "Camrese",
  [Enum.PillBrandsEnum.camrese_lo]: "Camrese Lo",
  [Enum.PillBrandsEnum.caziant]: "Caziant",
  [Enum.PillBrandsEnum.chateal]: "Chateal",
  [Enum.PillBrandsEnum.cryselle]: "Cryselle",
  [Enum.PillBrandsEnum.cyclafem_1_35]: "Cyclafem 1/35",
  [Enum.PillBrandsEnum.dasetta_1_35]: "Dasetta 1/35",
  [Enum.PillBrandsEnum.daysee]: "Daysee",
  [Enum.PillBrandsEnum.depo_subq_provera]: "Depo-Subq Provera",
  [Enum.PillBrandsEnum.drospirenone_ethinyl_estradiol]: "Drospirenone Ethinyl Estradiol",
  [Enum.PillBrandsEnum.elinest]: "Elinest",
  [Enum.PillBrandsEnum.emoquette]: "Emoquette",
  [Enum.PillBrandsEnum.enskyce]: "Enskyce",
  [Enum.PillBrandsEnum.errin]: "Errin",
  [Enum.PillBrandsEnum.estarylla]: "Estarylla",
  [Enum.PillBrandsEnum.estrostep_fe]: "Estrostep Fe",
  [Enum.PillBrandsEnum.falmina]: "Falmina",
  [Enum.PillBrandsEnum.femynor]: "Femynor",
  [Enum.PillBrandsEnum.generess_fe]: "Generess Fe",
  [Enum.PillBrandsEnum.gianvi]: "Gianvi",
  [Enum.PillBrandsEnum.hailey_24_fe]: "Hailey 24 Fe",
  [Enum.PillBrandsEnum.hailey_fe_1_5_30]: "Hailey Fe 1.5/30",
  [Enum.PillBrandsEnum.hailey_fe_1_20]: "Hailey Fe 1/20",
  [Enum.PillBrandsEnum.heather]: "Heather",
  [Enum.PillBrandsEnum.isibloom]: "Isibloom",
  [Enum.PillBrandsEnum.jolessa]: "Jolessa",
  [Enum.PillBrandsEnum.juleber]: "Juleber",
  [Enum.PillBrandsEnum.junel_1_5_30]: "Junel 1.5/30",
  [Enum.PillBrandsEnum.junel_1_20]: "Junel 1/20",
  [Enum.PillBrandsEnum.junel_fe_1_5_30]: "Junel Fe 1.5/30",
  [Enum.PillBrandsEnum.junel_fe_1_20]: "Junel Fe 1/20",
  [Enum.PillBrandsEnum.junel_fe_24]: "Junel Fe 24",
  [Enum.PillBrandsEnum.kariva]: "Kariva",
  [Enum.PillBrandsEnum.kelnor_1_35]: "Kelnor 1/35",
  [Enum.PillBrandsEnum.kurvelo]: "Kurvelo",
  [Enum.PillBrandsEnum.larin_1_5_30]: "Larin 1.5/30",
  [Enum.PillBrandsEnum.larin_1_20]: "Larin 1/20",
  [Enum.PillBrandsEnum.larin_24_fe]: "Larin 24 Fe",
  [Enum.PillBrandsEnum.larin_fe_1_5_30]: "Larin Fe 1.5/30",
  [Enum.PillBrandsEnum.larin_fe_1_20]: "Larin Fe 1/20",
  [Enum.PillBrandsEnum.larissia]: "Larissia",
  [Enum.PillBrandsEnum.leena]: "Leena",
  [Enum.PillBrandsEnum.lessina]: "Lessina",
  [Enum.PillBrandsEnum.levonest]: "Levonest",
  [Enum.PillBrandsEnum.levora]: "Levora",
  [Enum.PillBrandsEnum.lillow]: "Lillow",
  [Enum.PillBrandsEnum.lo_loestrin_fe]: "Lo Loestrin Fe",
  [Enum.PillBrandsEnum.lo_zumandimine]: "Lo-Zumandimine",
  [Enum.PillBrandsEnum.loestrin_1_5_30]: "Loestrin 1.5/30",
  [Enum.PillBrandsEnum.loestrin_1_20]: "Loestrin 1/20",
  [Enum.PillBrandsEnum.loestrin_fe_1_5_30]: "Loestrin Fe 1.5/30",
  [Enum.PillBrandsEnum.loestrin_fe_1_20]: "Loestrin Fe 1/20",
  [Enum.PillBrandsEnum.loryna]: "Loryna",
  [Enum.PillBrandsEnum.loseasonique]: "Loseasonique",
  [Enum.PillBrandsEnum.low_ogestrel]: "Low-Ogestrel",
  [Enum.PillBrandsEnum.lutera]: "Lutera",
  [Enum.PillBrandsEnum.lybrel]: "Lybrel",
  [Enum.PillBrandsEnum.marlissa]: "Marlissa",
  [Enum.PillBrandsEnum.melodetta_24_fe]: "Melodetta 24 Fe",
  [Enum.PillBrandsEnum.microgestin_1_5_30]: "Microgestin 1.5/30",
  [Enum.PillBrandsEnum.microgestin_1_20]: "Microgestin 1/20",
  [Enum.PillBrandsEnum.microgestin_fe_1_5_30]: "Microgestin Fe 1.5/30",
  [Enum.PillBrandsEnum.microgestin_fe_1_20]: "Microgestin Fe 1/20",
  [Enum.PillBrandsEnum.mili]: "Mili",
  [Enum.PillBrandsEnum.minastrin_24_fe]: "Minastrin 24 Fe",
  [Enum.PillBrandsEnum.mono_linyah]: "Mono-Linyah",
  [Enum.PillBrandsEnum.mononessa]: "Mononessa",
  [Enum.PillBrandsEnum.natazia]: "Natazia",
  [Enum.PillBrandsEnum.necon_0_5_35]: "Necon 0.5/35",
  [Enum.PillBrandsEnum.nikki]: "Nikki",
  [Enum.PillBrandsEnum.nora_be]: "Nora Be",
  [Enum.PillBrandsEnum.norethindrone]: "Norethindrone",
  [Enum.PillBrandsEnum.norethindrone_ethinyl_estradiol_fe]: "Norethindrone Ethinyl Estradiol Fe",
  [Enum.PillBrandsEnum.norgestimate_ethinyl_estradiol]: "Norgestimate-Ethinyl Estradiol",
  [Enum.PillBrandsEnum.norlyda]: "Norlyda",
  [Enum.PillBrandsEnum.nortrel_0_5_35]: "Nortrel 0.5/35",
  [Enum.PillBrandsEnum.nortrel_1_35]: "Nortrel 1/35",
  [Enum.PillBrandsEnum.nortrel_7_7_7]: "Nortrel 7/7/7",
  [Enum.PillBrandsEnum.ocella]: "Ocella",
  [Enum.PillBrandsEnum.orsythia]: "Orsythia",
  [Enum.PillBrandsEnum.ortho_cyclen]: "Ortho Cyclen",
  [Enum.PillBrandsEnum.ortho_tri_cyclen]: "Ortho Tri-Cyclen",
  [Enum.PillBrandsEnum.ortho_micronor]: "Ortho-Micronor",
  [Enum.PillBrandsEnum.philith]: "Philith",
  [Enum.PillBrandsEnum.pimtrea]: "Pimtrea",
  [Enum.PillBrandsEnum.portia]: "Portia",
  [Enum.PillBrandsEnum.previfem]: "Previfem",
  [Enum.PillBrandsEnum.quasense]: "Quasense",
  [Enum.PillBrandsEnum.reclipsen]: "Reclipsen",
  [Enum.PillBrandsEnum.safyral]: "Safyral",
  [Enum.PillBrandsEnum.seasonale]: "Seasonale",
  [Enum.PillBrandsEnum.seasonique]: "Seasonique",
  [Enum.PillBrandsEnum.setlakin]: "Setlakin",
  [Enum.PillBrandsEnum.sharobel]: "Sharobel",
  [Enum.PillBrandsEnum.simpesse]: "Simpesse",
  [Enum.PillBrandsEnum.sprintec]: "Sprintec",
  [Enum.PillBrandsEnum.sronyx]: "Sronyx",
  [Enum.PillBrandsEnum.syeda]: "Syeda",
  [Enum.PillBrandsEnum.tarina_fe_24]: "Tarina Fe 24",
  [Enum.PillBrandsEnum.taytulla]: "Taytulla",
  [Enum.PillBrandsEnum.tri_estarylla]: "Tri Estarylla",
  [Enum.PillBrandsEnum.tri_lo_estarylla]: "Tri Lo Estarylla",
  [Enum.PillBrandsEnum.tri_lo_marzia]: "Tri Lo Marzia",
  [Enum.PillBrandsEnum.tri_lo_mili]: "Tri Lo Mili",
  [Enum.PillBrandsEnum.tri_lo_sprintec]: "Tri Lo Sprintec",
  [Enum.PillBrandsEnum.tri_previfem]: "Tri Previfem",
  [Enum.PillBrandsEnum.tri_linyah]: "Tri-Linyah",
  [Enum.PillBrandsEnum.tri_sprintec]: "Tri-Sprintec",
  [Enum.PillBrandsEnum.tri_vylibra]: "Tri-Vylibra",
  [Enum.PillBrandsEnum.trilegest_fe]: "Trilegest Fe",
  [Enum.PillBrandsEnum.trinessa]: "Trinessa",
  [Enum.PillBrandsEnum.trivora]: "Trivora",
  [Enum.PillBrandsEnum.tulana]: "Tulana",
  [Enum.PillBrandsEnum.velivet]: "Velivet",
  [Enum.PillBrandsEnum.vestura]: "Vestura",
  [Enum.PillBrandsEnum.vienva]: "Vienva",
  [Enum.PillBrandsEnum.viorele]: "Viorele",
  [Enum.PillBrandsEnum.volnea]: "Volnea",
  [Enum.PillBrandsEnum.vylibra]: "Vylibra",
  [Enum.PillBrandsEnum.wera]: "Wera",
  [Enum.PillBrandsEnum.wymzya_fe]: "Wymzya Fe",
  [Enum.PillBrandsEnum.yasmin]: "Yasmin",
  [Enum.PillBrandsEnum.yaz]: "Yaz",
  [Enum.PillBrandsEnum.zovia_1_35]: "Zovia 1/35",
  [Enum.PillBrandsEnum.zumandimine]: "Zumandimine",

  // Patches
  [Enum.PatchBrandsEnum.xulane]: "Xulane patch",
  [Enum.PatchBrandsEnum.twirla]: "Twirla patch",

  // IUD
  [Enum.IudBrandsEnum.paragard_iud]: "Paragard IUD",
  [Enum.IudBrandsEnum.mirena_iud]: "Mirena IUD",
  [Enum.IudBrandsEnum.kyleena_iud]: "Kyleena IUD",
  [Enum.IudBrandsEnum.liletta_iud]: "Liletta IUD",
  [Enum.IudBrandsEnum.skyla_iud]: "Skyla IUD",

  // Implant
  [Enum.ImplantBrandsEnum.implanon]: "Implanon",
  [Enum.ImplantBrandsEnum.nexplanon]: "Nexplanon",

  // Injection
  [Enum.InjectionBrandsEnum.depo_provera]: "Depo-Provera",
  [Enum.InjectionBrandsEnum.depo_subq_provera_104]: "depo-subQ provera 104",

  // Ring
  [Enum.RingBrandsEnum.annovera]: "Annovera",
  [Enum.RingBrandsEnum.nuvaring]: "NuvaRing",
  [Enum.RingBrandsEnum.eluryng]: "EluRyng",
}

export interface IStringTranslator {
  (string_to_translate: string | number): string
}

export interface IBooleanTranslator {
  (string_to_translate: boolean | null): string
}

export const BooleanTranslator: IBooleanTranslator = (boolean_to_translate) => {
  if (boolean_to_translate === true) {
    return 'Yes';
  }
  else if (boolean_to_translate === false) {
    return 'No';
  }
  return 'Unknown';
}

export const PassthroughTranslator: IStringTranslator = (string_to_translate) => {
  return String(string_to_translate);
}

export const SimpleTranslator: IStringTranslator = (string_to_translate) => {
  let newstring = String(string_to_translate);
  newstring = newstring.charAt(0).toUpperCase() + newstring.slice(1)
  return newstring.replaceAll('_', ' ');
}

export const HypertensionTranslator = (value: Enum.HypertensionEnum) => {
  let retval = 'Unknown';
  switch (value) {
    case Enum.HypertensionEnum.no:
      retval = 'No';
      break;
    case Enum.HypertensionEnum.yes_with_medications:
      retval = 'Diagnosed and medicated';
      break;
    case Enum.HypertensionEnum.yes_without_medications:
      retval = 'Diagnosed but not medicated';
      break;
    case Enum.HypertensionEnum.yes_with_vascular_disease:
      retval = 'Diagnosed with vascular disease';
      break;
  }

  return retval;
}

export const HypertensionBpTranslator = (value: Enum.HypertensionBPEnum | null) => {
  let retval = 'Unknown';
  switch (value) {
    case Enum.HypertensionBPEnum.systolic_140_159_diastolic_90_99:
      retval = 'Systolic 140–159 mm Hg or diastolic 90–99 mm Hg';
      break;
    case Enum.HypertensionBPEnum.systolic_gte_160_diastolic_gte_100:
      retval = 'Systolic ≥160 mm Hg or diastolic ≥100 mm Hg';
      break;
    case Enum.HypertensionBPEnum.systolic_lte_130_diastolic_lte_80:
      retval = 'Systolic </=130 or diastolic </=80';
      break;
  }

  return retval;
}

export const DvtPeTranslator = (value: Enum.DTVPEEnum): string => {
  let retval = 'Unknown';

  switch (value) {
    case Enum.DTVPEEnum.no:
      retval = 'No';
      break;
    case Enum.DTVPEEnum.leg_resolved_with_medications:
      retval = 'Leg clot treated with medication';
      break;
    case Enum.DTVPEEnum.leg_resolved_without_medications:
      retval = 'Leg clot resolved without medication';
      break;
    case Enum.DTVPEEnum.lung_clot_or_pulmonary_embolism:
      retval = 'Lung clot or pulmonary embolism';
      break;
  }

  return retval;
}

export const SuperficialVenousDisordersTranslator = (value: Enum.SuperficialVenousDisordersEnum): string => {
  let retval = 'Unknown';

  switch (value) {
    case Enum.SuperficialVenousDisordersEnum.no:
      retval = 'No';
      break;
    case Enum.SuperficialVenousDisordersEnum.yes_history:
      retval = 'Yes, history of superficial venous thrombosis';
      break;
    case Enum.SuperficialVenousDisordersEnum.yes_visible:
      retval = 'Yes, visible veins';
      break;
  }

  return retval;
}

export const ValvularHeartDiseaseTranslator = (value: Enum.ValvularHeartDiseaseEnum): string => {
  let retval = 'Unknown';

  switch (value) {
    case Enum.ValvularHeartDiseaseEnum.no:
      retval = 'No';
      break;
    case Enum.ValvularHeartDiseaseEnum.diagnosed_without_complications:
      retval = 'Yes, without complications';
      break;
    case Enum.ValvularHeartDiseaseEnum.diagnosed_with_complications:
      retval = 'Yes, with complications';
      break;
  }

  return retval;
}

export const PeripartumCardiomyopathyTranslator = (value: Enum.PeripartumCardiomyopathyEnum): string => {
  let retval = 'Unknown';

  switch (value) {
    case Enum.PeripartumCardiomyopathyEnum.no:
      retval = 'No';
      break;
    case Enum.PeripartumCardiomyopathyEnum.diagnosed_normal_mild_impaired:
      retval = 'Yes, normal or mildly impaired';
      break;
    case Enum.PeripartumCardiomyopathyEnum.diagnosed_moderate_severe_impaired:
      retval = 'Yes, moderately or severely impaired';
      break;
  }

  return retval;
}


export const PeripartumCardiomyopathyDurationTranslator = (value: Enum.PeripartumCardiomyopathyDurationEnum): string => {
  let retval = 'Unknown';

  switch (value) {
    case Enum.PeripartumCardiomyopathyDurationEnum.lt_6_months:
      retval = '< 6 months';
      break;
    case Enum.PeripartumCardiomyopathyDurationEnum.gte_6_months:
      retval = '>= 6 months';
      break;
  }

  return retval;
}

export const ValvularHeartDiseaseComplicationsTranslator = (value: Enum.ValvularHeartDiseaseComplicationsEnum): string => {
  let retval = 'Unknown';

  switch (value) {
    case Enum.ValvularHeartDiseaseComplicationsEnum.pulmonary_hypertension:
      retval = 'Pulmonary hypertension';
      break;
    case Enum.ValvularHeartDiseaseComplicationsEnum.risk_for_atrial_fibrillation:
      retval = 'Risk for atrial fibrillation';
      break;
    case Enum.ValvularHeartDiseaseComplicationsEnum.subacute_bacterial_endocarditis:
      retval = 'Subacute bacterial endocarditis';
      break;
  }

  return retval;
}

export const RheumaticDiseasesTranslator = (value: Enum.RheumaticDiseasesEnum): string => {
  let retval = 'Unknown';

  switch (value) {
    case Enum.RheumaticDiseasesEnum.rheumatoid_arthritis:
      retval = ' Rheumatoid Arthritis (RA)';
      break;
    case Enum.RheumaticDiseasesEnum.systemic_lupus_erythematosus:
      retval = 'Systemic Lupus Erythematosus (SLE)';
      break;
    case Enum.RheumaticDiseasesEnum.other:
      retval = 'Other';
      break;
  }

  return retval;
}

export const HeightTranslator: IStringTranslator = (string_to_translate) => {
  let heigh_inches = Number(string_to_translate);
  if (Number.isNaN(heigh_inches)) {
    return String(string_to_translate);
  }

  const feet = Math.floor(heigh_inches / 12);
  const inches = heigh_inches % 12;
  return `${feet}'${inches}"`;
}

export const CigarettesPerDayTranslator: IStringTranslator = (string_to_translate) => {
  let retval = 'Unknown cigs/day';
  if (string_to_translate === Enum.CigarettesPerDayEnum.gt_15_per_day) {
    return "> 15 cigs/day";
  }
  if (string_to_translate === Enum.CigarettesPerDayEnum.lte_15_per_day) {
    return "<= 15 cigs/day";
  }
  return retval;
}

/*
 * Continutes the Python extraction code in celeste-shared-py to generate the translation table:
 * for info in res:
 *     print(f"Enum.PillBrandsEnum.{info['ref']}: \"{info['human_name']}\",")
 */
export const ContraceptiveBrandTranslator: IStringTranslator = (string_to_translate) => {
  if (ttable[String(string_to_translate) as keyof typeof ttable]) {
    return ttable[String(string_to_translate) as keyof typeof ttable];
  }

  return SimpleTranslator(string_to_translate);
}

export const PossessiveTranslator: IStringTranslator = (string_to_translate) => {
  const full_name = String(string_to_translate);
  const first_name = full_name.split(' ')[0];
  if (first_name.charAt(first_name.length-1).toLowerCase() === 's') {
    return `${first_name}'`;
  }

  return `${first_name}'s`;;
}