import { Outlet } from 'react-router-dom';
import './ProviderApp.css';
import GlobalContainer from './components/GlobalContainer';

function ProviderApp() {
  return (
    <GlobalContainer data-testid="global-container">
      <Outlet />
    </GlobalContainer>
  );
}

export default ProviderApp;
