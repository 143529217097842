import { Enum } from "./enums";
export namespace FormLists {
    export const BirthControlTypesList = [
        {
            "label": "Pill",
            "value": Enum.BirthControlTypesEnum['pill'],
        },
        {
            "label": "Patch",
            "value": Enum.BirthControlTypesEnum['patch'],
        },
        {
            "label": "CU IUD",
            "value": Enum.BirthControlTypesEnum['cu_iud'],
        },
        {
            "label": "Hormonal IUD",
            "value": Enum.BirthControlTypesEnum['hormonal_iud'],
        },
        {
            "label": "Implant",
            "value": Enum.BirthControlTypesEnum['implant'],
        },
        {
            "label": "Shot",
            "value": Enum.BirthControlTypesEnum['shot'],
        },
        {
            "label": "Ring",
            "value": Enum.BirthControlTypesEnum['ring'],
        },
        {
            "label": "Male condom",
            "value": Enum.BirthControlTypesEnum['male_condom'],
        },
        {
            "label": "Diaphragm",
            "value": Enum.BirthControlTypesEnum['diaphragm'],
        },
        {
            "label": "Abstinence",
            "value": Enum.BirthControlTypesEnum['abstinence'],
        },
        {
            "label": "Pull-out method",
            "value": Enum.BirthControlTypesEnum['pull_out_method'],
        },
    ];

    export const BirthControlGoalsEnumList = [
        {
            "label": "Menstrual cramps",
            "value": Enum.BirthControlGoalsEnum['menstrual_cramps'],
        },
        {
            "label": "Acne",
            "value": Enum.BirthControlGoalsEnum['acne'],
        },
        {
            "label": "Hormone Imbalance",
            "value": Enum.BirthControlGoalsEnum['hormonal_imbalance'],
        },
        {
            "label": "Irregular periods",
            "value": Enum.BirthControlGoalsEnum['irregular_periods'],
        },
        {
            "label": "Pregnancy",
            "value": Enum.BirthControlGoalsEnum['pregnancy'],
        },
    ];

    export const HormonalImbalanceChallengesEnumList = [
        {
            "label": "Irregular menstrual cycles",
            "value": "irregular_menstrual_cycles",
        },
        {
            "label": "Mood swings",
            "value": "mood_swings",
        },
        {
            "label": "Fatigue",
            "value": "fatigue",
        },
        {
            "label": "Weight changes",
            "value": "weight_changes",
        },
        {
            "label": "Sleep disturbances",
            "value": "sleep_disturbances",
        },
        {
            "label": "Hair and skin issues",
            "value": "hair_and_skin_issues",
        },
        {
            "label": "Digestive problems",
            "value": "digestive_problems",
        },
        {
            "label": "Changes in libido",
            "value": "changes_in_libido",
        },
        {
            "label": "Hot flashes and night sweats",
            "value": "hot_flashes_and_night_sweats",
        },
        {
            "label": "Changes in appetite",
            "value": "changes_in_appetite",
        },
        {
            "label": "Cognitive changes",
            "value": "cognitive_changes",
        },
        {
            "label": "Changes in body temperature",
            "value": "changes_in_body_temperature",
        },
        {
            "label": "Muscle and joint pain",
            "value": "muscle_and_joint_pain",
        },
        {
            "label": "Headaches",
            "value": "headaches",
        },
        {
            "label": "Heart palpitations",
            "value": "heart_palpitations",
        },
        {
            "label": "Changes in blood sugar levels",
            "value": "changes_in_blood_sugar_levels",
        },
        {
            "label": "Changes in blood pressure",
            "value": "changes_in_blood_pressure",
        },
        {
            "label": "Changes in hair and nail quality",
            "value": "changes_in_hair_and_nail_quality",
        },
        {
            "label": "Breast changes",
            "value": "breast_changes",
        },
        {
            "label": "Thyroid related symptoms",
            "value": "thyroid_related_symptoms",
        },
    ];

    export const ValvularHeartDiseaseComplicationsEnumList = [
        {
            "label": "Pulmonary hypertension",
            "value": "pulmonary_hypertension",
        },
        {
            "label": "Risk for atrial fibrillation",
            "value": "risk_for_atrial_fibrillation",
        },
        {
            "label": "Subacute bacterial endocarditis",
            "value": "subacute_bacterial_endocarditis",
        },
    ];

    export const RheumaticDiseasesEnumList = [
        {
            "label": "Systemic lupus erythematosus",
            "value": "systemic_lupus_erythematosus",
        },
        {
            "label": "Rheumatoid arthritis",
            "value": "rheumatoid_arthritis",
        },
        {
            "label": "Other",
            "value": "other",
        },
    ];

    export const NeurologicConditionsEnumList = [
        {
            "label": "Headaches",
            "value": "headaches",
        },
        {
            "label": "Migranes with aura",
            "value": "migranes_with_aura",
        },
        {
            "label": "Migranes without aura",
            "value": "migranes_without_aura",
        },
        {
            "label": "Epilepsy",
            "value": "epilepsy",
        },
        {
            "label": "Multiple sclerosis",
            "value": "multiple_sclerosis",
        },
    ];

    export const MoodDisordersEnumList = [
        {
            "label": "Major depressive disorder",
            "value": "major_depressive_disorder",
        },
        {
            "label": "Persistent depressive disorder",
            "value": "persistent_depressive_disorder",
        },
        {
            "label": "Bipolar disorder",
            "value": "bipolar_disorder",
        },
        {
            "label": "Seasonal affective disorder",
            "value": "seasonal_affective_disorder",
        },
        {
            "label": "Postpartum depression",
            "value": "postpartum_depression",
        },
        {
            "label": "Premenstrual dysphoric disorder",
            "value": "premenstrual_dysphoric_disorder",
        },
        {
            "label": "Disruptive mood dysregulation disorder",
            "value": "disruptive_mood_dysregulation_disorder",
        },
        {
            "label": "Atypical depression",
            "value": "atypical_depression",
        },
        {
            "label": "Anxiety",
            "value": "anxiety",
        },
        {
            "label": "Other",
            "value": "other",
        },
        {
            "label": "Posttraumatic stress disorder",
            "value": "posttraumatic_stress_disorder",
        },
    ];

    export const ReproductiveTractInfectionsEnumList = [
        {
            "label": "Irregular menstrual cycles no heavy bleeding",
            "value": "irregular_menstrual_cycles_no_heavy_bleeding",
        },
        {
            "label": "Heavy periods",
            "value": "heavy_periods",
        },
        {
            "label": "Unexplained vaginal bleeding",
            "value": "unexplained_vaginal_bleeding",
        },
        {
            "label": "Endometriosis",
            "value": "endometriosis",
        },
        {
            "label": "Benign ovarian tumors",
            "value": "benign_ovarian_tumors",
        },
        {
            "label": "Severe dysmenorrhea",
            "value": "severe_dysmenorrhea",
        },
        {
            "label": "Gestational trophoblastic disease",
            "value": "gestational_trophoblastic_disease",
        },
        {
            "label": "Cervical ectropion",
            "value": "cervical_ectropion",
        },
        {
            "label": "Cervical intraepithelial neoplasia",
            "value": "cervical_intraepithelial_neoplasia",
        },
        {
            "label": "Cervical cancer awaiting treatment",
            "value": "cervical_cancer_awaiting_treatment",
        },
        {
            "label": "Breast disease or cancer",
            "value": "breast_disease_or_cancer",
        },
        {
            "label": "Endometrial hyperplasia",
            "value": "endometrial_hyperplasia",
        },
        {
            "label": "Endometrial cancer",
            "value": "endometrial_cancer",
        },
        {
            "label": "Ovarian cancer",
            "value": "ovarian_cancer",
        },
        {
            "label": "Uterine fibroids",
            "value": "uterine_fibroids",
        },
        {
            "label": "Distorted uterine cavity",
            "value": "distorted_uterine_cavity",
        },
        {
            "label": "Pelvic inflammatory disease",
            "value": "pelvic_inflammatory_disease",
        },
        {
            "label": "Stds current or past",
            "value": "stds_current_or_past",
        },
        {
            "label": "Vaginitis",
            "value": "vaginitis",
        },
        {
            "label": "Other",
            "value": "other",
        },
    ];

    export const InfectionsEnumList = [
        {
            "label": "Schistosomiasis",
            "value": "schistosomiasis",
        },
        {
            "label": "Fibrosis of the liver",
            "value": "fibrosis_of_the_liver",
        },
        {
            "label": "Pelvic tuberculosis",
            "value": "pelvic_tuberculosis",
        },
        {
            "label": "Non pelvic tuberculosis",
            "value": "non_pelvic_tuberculosis",
        },
        {
            "label": "Malaria",
            "value": "malaria",
        },
        {
            "label": "Other",
            "value": "other",
        },
    ];

    export const EndocrineConditionsEnumList = [
        {
            "label": "Type 1 diabetes",
            "value": "type_1_diabetes",
        },
        {
            "label": "Type 2 diabetes",
            "value": "type_2_diabetes",
        },
        {
            "label": "Hyperthyroidism",
            "value": "hyperthyroidism",
        },
        {
            "label": "Hypothyroidism",
            "value": "hypothyroidism",
        },
        {
            "label": "Simple Goiter Thyroid Disorder",
            "value": "simple_goiter_thyroid_disorder",
        },
        {
            "label": "Other",
            "value": "other",
        }
    ];

    export const GiConditionsEnumList = [
        {
            "label": "Gallbladder disease",
            "value": "gallbladder_disease",
        },
        {
            "label": "Pregnancy related cholestasis",
            "value": "pregnancy_related_cholestasis",
        },
        {
            "label": "Hormonal birth control related cholestasis",
            "value": "hormonal_birth_control_related_cholestasis",
        },
        {
            "label": "Viral hepatitis",
            "value": "viral_hepatitis",
        },
        {
            "label": "Mild cirrhosis",
            "value": "mild_cirrhosis",
        },
        {
            "label": "Severe cirrhosis",
            "value": "severe_cirrhosis",
        },
        {
            "label": "Liver tumors",
            "value": "liver_tumors",
        },
        {
            "label": "Other",
            "value": "other",
        },
    ];

    export const AnemiaEnumList = [
        {
            "label": "Thalassemia",
            "value": "thalassemia",
        },
        {
            "label": "Sickle cell disease",
            "value": "sickle_cell_disease",
        },
        {
            "label": "Iron deficiency anemia",
            "value": "iron_deficiency_anemia",
        },
    ];

    export const BirthControlConcernsEnumList = [
        {
            "label": "Effectiveness",
            "value": "effectiveness",
        },
        {
            "label": "Side effects",
            "value": "side_effects",
        },
        {
            "label": "Health risks",
            "value": "health_risks",
        },
        {
            "label": "Convenience",
            "value": "convenience",
        },
        {
            "label": "Cost",
            "value": "cost",
        },
        {
            "label": "Interference with sexual spontaneity",
            "value": "interference_with_sexual_spontaneity",
        },
        {
            "label": "Impact on fertility",
            "value": "impact_on_fertility",
        },
        {
            "label": "Other",
            "value": "other",
        },
    ];

    export const RecreationalDrugsEnumList = [
        {
            "label": "Marijuana",
            "value": "marijuana",
        },
        {
            "label": "Cocaine",
            "value": "cocaine",
        },
        {
            "label": "Amphetamines",
            "value": "amphetamines",
        },
        {
            "label": "Methamphetamines",
            "value": "methamphetamines",
        },
        {
            "label": "Mephedrone",
            "value": "mephedrone",
        },
        {
            "label": "Hallucinogens",
            "value": "hallucinogens",
        },
        {
            "label": "Benzylpiperazine",
            "value": "benzylpiperazine",
        },
        {
            "label": "Bath salts",
            "value": "bath_salts",
        },
    ];

    export const SmokingProductsEnumList = [
        {
            "label": "Vape",
            "value": "vape",
        },
        {
            "label": "E-cigarette",
            "value": "ecigarette",
        },
        {
            "label": "Cigarette",
            "value": "cigarette",
        },
        {
            "label": "Nicotine patch",
            "value": "nicotine_patch",
        },
    ];

    export const PillBrandsEnumList = [
        {
            "label": "Altavera",
            "value": "altavera",
        },
        {
            "label": "Alyacen 1/35",
            "value": "alyacen_1_35",
        },
        {
            "label": "Alyacen 7/7/7",
            "value": "alyacen_7_7_7",
        },
        {
            "label": "Amethia",
            "value": "amethia",
        },
        {
            "label": "Amethia Lo",
            "value": "amethia_lo",
        },
        {
            "label": "Amethyst",
            "value": "amethyst",
        },
        {
            "label": "Apri",
            "value": "apri",
        },
        {
            "label": "Aranelle",
            "value": "aranelle",
        },
        {
            "label": "Ashlyna",
            "value": "ashlyna",
        },
        {
            "label": "Aubra Eq",
            "value": "aubra_eq",
        },
        {
            "label": "Aurovela Fe 1.5-30",
            "value": "aurovela_fe_1_5_30",
        },
        {
            "label": "Aurovela Fe 1/20",
            "value": "aurovela_fe_1_20",
        },
        {
            "label": "Aviane",
            "value": "aviane",
        },
        {
            "label": "Azurette",
            "value": "azurette",
        },
        {
            "label": "Balziva",
            "value": "balziva",
        },
        {
            "label": "Beyaz",
            "value": "beyaz",
        },
        {
            "label": "Blisovi 24 Fe",
            "value": "blisovi_24_fe",
        },
        {
            "label": "Blisovi Fe 1.5/30",
            "value": "blisovi_fe_1_5_30",
        },
        {
            "label": "Blisovi Fe 1/20",
            "value": "blisovi_fe_1_20",
        },
        {
            "label": "Briellyn",
            "value": "briellyn",
        },
        {
            "label": "Camila",
            "value": "camila",
        },
        {
            "label": "Camrese",
            "value": "camrese",
        },
        {
            "label": "Camrese Lo",
            "value": "camrese_lo",
        },
        {
            "label": "Caziant",
            "value": "caziant",
        },
        {
            "label": "Chateal",
            "value": "chateal",
        },
        {
            "label": "Cryselle",
            "value": "cryselle",
        },
        {
            "label": "Cyclafem 1/35",
            "value": "cyclafem_1_35",
        },
        {
            "label": "Dasetta 1/35",
            "value": "dasetta_1_35",
        },
        {
            "label": "Daysee",
            "value": "daysee",
        },
        {
            "label": "Depo-Subq Provera",
            "value": "depo_subq_provera",
        },
        {
            "label": "Drospirenone Ethinyl Estradiol",
            "value": "drospirenone_ethinyl_estradiol",
        },
        {
            "label": "Elinest",
            "value": "elinest",
        },
        {
            "label": "Emoquette",
            "value": "emoquette",
        },
        {
            "label": "Enskyce",
            "value": "enskyce",
        },
        {
            "label": "Errin",
            "value": "errin",
        },
        {
            "label": "Estarylla",
            "value": "estarylla",
        },
        {
            "label": "Estrostep Fe",
            "value": "estrostep_fe",
        },
        {
            "label": "Falmina",
            "value": "falmina",
        },
        {
            "label": "Femynor",
            "value": "femynor",
        },
        {
            "label": "Generess Fe",
            "value": "generess_fe",
        },
        {
            "label": "Gianvi",
            "value": "gianvi",
        },
        {
            "label": "Hailey 24 Fe",
            "value": "hailey_24_fe",
        },
        {
            "label": "Hailey fe 1.5/30",
            "value": "hailey_fe_1_5_30",
        },
        {
            "label": "Hailey fe 1/20",
            "value": "hailey_fe_1_20",
        },
        {
            "label": "Heather",
            "value": "heather",
        },
        {
            "label": "Isibloom",
            "value": "isibloom",
        },
        {
            "label": "Jolessa",
            "value": "jolessa",
        },
        {
            "label": "Juleber",
            "value": "juleber",
        },
        {
            "label": "Junel 1.5/30",
            "value": "junel_1_5_30",
        },
        {
            "label": "Junel 1/20",
            "value": "junel_1_20",
        },
        {
            "label": "Junel Fe 1.5/30",
            "value": "junel_fe_1_5_30",
        },
        {
            "label": "Junel Fe 1/20",
            "value": "junel_fe_1_20",
        },
        {
            "label": "Junel Fe 24",
            "value": "junel_fe_24",
        },
        {
            "label": "Kariva",
            "value": "kariva",
        },
        {
            "label": "Kelnor 1/35",
            "value": "kelnor_1_35",
        },
        {
            "label": "Kurvelo",
            "value": "kurvelo",
        },
        {
            "label": "Larin 1.5/30",
            "value": "larin_1_5_30",
        },
        {
            "label": "Larin 1/20",
            "value": "larin_1_20",
        },
        {
            "label": "Larin 24 Fe",
            "value": "larin_24_fe",
        },
        {
            "label": "Larin fe 1.5/30",
            "value": "larin_fe_1_5_30",
        },
        {
            "label": "Larin fe 1/20",
            "value": "larin_fe_1_20",
        },
        {
            "label": "Larissia",
            "value": "larissia",
        },
        {
            "label": "Leena",
            "value": "leena",
        },
        {
            "label": "Lessina",
            "value": "lessina",
        },
        {
            "label": "Levonest",
            "value": "levonest",
        },
        {
            "label": "Levora",
            "value": "levora",
        },
        {
            "label": "Lillow",
            "value": "lillow",
        },
        {
            "label": "Lo Loestrin Fe",
            "value": "lo_loestrin_fe",
        },
        {
            "label": "Lo-Zumandimine",
            "value": "lo_zumandimine",
        },
        {
            "label": "Loestrin 1.5/30",
            "value": "loestrin_1_5_30",
        },
        {
            "label": "Loestrin 1/20",
            "value": "loestrin_1_20",
        },
        {
            "label": "Loestrin fe 1.5/30",
            "value": "loestrin_fe_1_5_30",
        },
        {
            "label": "Loestrin fe 1/20",
            "value": "loestrin_fe_1_20",
        },
        {
            "label": "Loryna",
            "value": "loryna",
        },
        {
            "label": "Loseasonique",
            "value": "loseasonique",
        },
        {
            "label": "Low ogestrel",
            "value": "low_ogestrel",
        },
        {
            "label": "Lutera",
            "value": "lutera",
        },
        {
            "label": "Lybrel",
            "value": "lybrel",
        },
        {
            "label": "Marlissa",
            "value": "marlissa",
        },
        {
            "label": "Melodetta 24 Fe",
            "value": "melodetta_24_fe",
        },
        {
            "label": "Microgestin 1.5/30",
            "value": "microgestin_1_5_30",
        },
        {
            "label": "Microgestin 1/20",
            "value": "microgestin_1_20",
        },
        {
            "label": "Microgestin Fe 1.5/30",
            "value": "microgestin_fe_1_5_30",
        },
        {
            "label": "Microgestin Fe 1/20",
            "value": "microgestin_fe_1_20",
        },
        {
            "label": "Mili",
            "value": "mili",
        },
        {
            "label": "Minastrin 24 Fe",
            "value": "minastrin_24_fe",
        },
        {
            "label": "Mono-Linyah",
            "value": "mono_linyah",
        },
        {
            "label": "Mononessa",
            "value": "mononessa",
        },
        {
            "label": "Natazia",
            "value": "natazia",
        },
        {
            "label": "Necon 0.5/35",
            "value": "necon_0_5_35",
        },
        {
            "label": "Nikki",
            "value": "nikki",
        },
        {
            "label": "Nora Be",
            "value": "nora_be",
        },
        {
            "label": "Norethindrone",
            "value": "norethindrone",
        },
        {
            "label": "Norethindrone Ethinyl Estradiol Fe",
            "value": "norethindrone_ethinyl_estradiol_fe",
        },
        {
            "label": "Norgestimate Ethinyl Estradiol",
            "value": "norgestimate_ethinyl_estradiol",
        },
        {
            "label": "Norlyda",
            "value": "norlyda",
        },
        {
            "label": "Nortrel 0.5/35",
            "value": "nortrel_0_5_35",
        },
        {
            "label": "Nortrel 1/35",
            "value": "nortrel_1_35",
        },
        {
            "label": "Nortrel 7/7/7",
            "value": "nortrel_7_7_7",
        },
        {
            "label": "Ocella",
            "value": "ocella",
        },
        {
            "label": "Orsythia",
            "value": "orsythia",
        },
        {
            "label": "Ortho Cyclen",
            "value": "ortho_cyclen",
        },
        {
            "label": "Ortho Tri-Cyclen",
            "value": "ortho_tri_cyclen",
        },
        {
            "label": "Ortho-Micronor",
            "value": "ortho_micronor",
        },
        {
            "label": "Philith",
            "value": "philith",
        },
        {
            "label": "Pimtrea",
            "value": "pimtrea",
        },
        {
            "label": "Portia",
            "value": "portia",
        },
        {
            "label": "Previfem",
            "value": "previfem",
        },
        {
            "label": "Quasense",
            "value": "quasense",
        },
        {
            "label": "Reclipsen",
            "value": "reclipsen",
        },
        {
            "label": "Safyral",
            "value": "safyral",
        },
        {
            "label": "Seasonale",
            "value": "seasonale",
        },
        {
            "label": "Seasonique",
            "value": "seasonique",
        },
        {
            "label": "Setlakin",
            "value": "setlakin",
        },
        {
            "label": "Sharobel",
            "value": "sharobel",
        },
        {
            "label": "Simpesse",
            "value": "simpesse",
        },
        {
            "label": "Sprintec",
            "value": "sprintec",
        },
        {
            "label": "Sronyx",
            "value": "sronyx",
        },
        {
            "label": "Syeda",
            "value": "syeda",
        },
        {
            "label": "Tarina Fe 24",
            "value": "tarina_fe_24",
        },
        {
            "label": "Taytulla",
            "value": "taytulla",
        },
        {
            "label": "Tri Estarylla",
            "value": "tri_estarylla",
        },
        {
            "label": "Tri Lo Estarylla",
            "value": "tri_lo_estarylla",
        },
        {
            "label": "Tri Lo Marzia",
            "value": "tri_lo_marzia",
        },
        {
            "label": "Tri Lo Mili",
            "value": "tri_lo_mili",
        },
        {
            "label": "Tri Lo Sprintec",
            "value": "tri_lo_sprintec",
        },
        {
            "label": "Tri Previfem",
            "value": "tri_previfem",
        },
        {
            "label": "Tri-Linyah",
            "value": "tri_linyah",
        },
        {
            "label": "Tri-Sprintec",
            "value": "tri_sprintec",
        },
        {
            "label": "Tri-Vylibra",
            "value": "tri_vylibra",
        },
        {
            "label": "Trilegest fe",
            "value": "trilegest_fe",
        },
        {
            "label": "Trinessa",
            "value": "trinessa",
        },
        {
            "label": "Trivora",
            "value": "trivora",
        },
        {
            "label": "Tulana",
            "value": "tulana",
        },
        {
            "label": "Velivet",
            "value": "velivet",
        },
        {
            "label": "Vestura",
            "value": "vestura",
        },
        {
            "label": "Vienva",
            "value": "vienva",
        },
        {
            "label": "Viorele",
            "value": "viorele",
        },
        {
            "label": "Volnea",
            "value": "volnea",
        },
        {
            "label": "Vylibra",
            "value": "vylibra",
        },
        {
            "label": "Wera",
            "value": "wera",
        },
        {
            "label": "Wymzya Fe",
            "value": "wymzya_fe",
        },
        {
            "label": "Yasmin",
            "value": "yasmin",
        },
        {
            "label": "Yaz",
            "value": "yaz",
        },
        {
            "label": "Zovia 1/35",
            "value": "zovia_1_35",
        },
        {
            "label": "Zumandimine",
            "value": "zumandimine",
        },
    ];

    export const PatchBrandsEnumList = [
        {
            "label": "Xulane patch",
            "value": "xulane",
        },
        {
            "label": "Twirla patch",
            "value": "twirla",
        },
    ];

    export const IudBrandsEnumList = [
        {
            "label": "Paragard IUD",
            "value": "paragard_iud",
        },
        {
            "label": "Mirena IUD",
            "value": "mirena_iud",
        },
        {
            "label": "Kyleena IUD",
            "value": "kyleena_iud",
        },
        {
            "label": "Liletta IUD",
            "value": "liletta_iud",
        },
        {
            "label": "Skyla IUD",
            "value": "skyla_iud",
        },
    ];

    export const ImplantBrandsEnumList = [
        {
            "label": "Implanon",
            "value": "implanon",
        },
        {
            "label": "Nexplanon",
            "value": "nexplanon",
        },
    ];

    export const InjectionBrandsEnumList = [
        {
            "label": "Depo-Provera",
            "value": "depo_provera",
        },
        {
            "label": "depo-subQ provera 104",
            "value": "depo_subq_provera_104",
        },
    ];

    export const RingBrandsEnumList = [
        {
            "label": "Annovera",
            "value": "annovera",
        },
        {
            "label": "NuvaRing",
            "value": "nuvaring",
        },
        {
            "label": "EluRyng",
            "value": "eluryng",
        },
    ];
}