import { useEffect, useState } from "react";
import { Col, Container, Row, Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { clerkUseUser, useFetch } from "../../helpers/clerk";
import { getBackendUrl } from "../../utils";

export default function HandleLogin() {
  const [message, setMessage] = useState("");
  const [pageLoaded, setPageLoaded] = useState(false);
  const backend_request = useFetch();
  const navigate = useNavigate();
  const { isSignedIn, isLoaded } = clerkUseUser()();

  const handleFetchData = async () => {
    if (!isLoaded || pageLoaded) {
      return;
    }

    if (!isSignedIn) {
      navigate("/login");
    }
    else {
      const resp = await backend_request(getBackendUrl("/api/auth/login"), { method: 'POST' });
      if (resp.status === 401) {
        // TODO: unauthorized. Do something about this.
        setMessage("An unexpected error has occurred. Please try again.");
        // throw new Error("Unauthorized");
      }
      else if (!resp.ok) {
        // TODO
        setMessage("An unexpected error has occurred. Please try again.");
        // throw new Error("Server error");
      }

      setPageLoaded(true);

      if (resp.ok) {
        const response_json = await resp.json();
        if (response_json["user_type"] === "superuser") {
          navigate("/d/admin");
        }
        else {
          // Demo V1
          // navigate("/d/provider/intake");

          // Demo V2
          // navigate("/d/providers/dashboard");

          // Demo 2024-01-17
          navigate("/d/providers/dashboard/patients");
        }
      }
    }
  };

  useEffect(() => {
    handleFetchData();
  }, [isLoaded]);

  return (
    <Container className="h-100 d-flex align-items-center justify-content-center">
      <Row>
        <Col>
          {!message && (<Spinner />)}
          {message && (<>{message}</>)}
        </Col>
      </Row>
    </Container>
  );
};