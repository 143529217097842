import { useLoaderData } from "react-router-dom";
import { useFetch } from "../../../../helpers/clerk";
import { useState } from "react";
import { getBackendUrl } from "../../../../utils";
import PatientForm from "../../../../components/forms/PatientForm";

export type LoaderData = {
    patient_uuid: string,
}

export async function loader({ params }: { params: { patient_uuid?: string } }) {
    return { patient_uuid: params.patient_uuid };
}

export default function Patient() {
    const data = useLoaderData() as LoaderData;

    return (
        <>
            <PatientForm
                patient_uuid={data.patient_uuid}
            />
        </>
    );
}