import { TLegalNavigation, TLegalPagesConfiguration } from "./types";

export const constants = {
  LEGAL_PRIVACY_ACCEPTED: "privacyAccepted",
  TERMS_CONDITIONS_ACCEPTED: "termsConditionsAccepted",
  CONSENT_DISCLOSURE_ACCEPTED: "consentDisclosureAccepted",
  HIPAA_STATEMENT_ACCEPTED: "hipaaStatementAccepted",
  PRIVACY_SECTION: 'privacy',
  TERMS_CONDITIONS_SECTION: 'terms_and_conditions',
  CONSENT_DISCLOSURE_SECTION: 'consent_disclosure',
  HIPAA_STATEMENT_SECTION: 'hipaa_statement',
};

export const legal_sections = {
  PRIVACY: 'privacy',
  TERMS_CONDITIONS: 'terms_and_conditions',
  CONSENT_DISCLOSURE: 'consent_disclosure',
  HIPAA_STATEMENT: 'hipaa_statement',
}

export const legal_pages_configuration : TLegalPagesConfiguration = {
  [legal_sections.PRIVACY]: {
    id: 'legal-privacy-accept',
    title: 'Privacy Policy',
    text: [
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.',
      'Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    ],
    action: {
      label: 'I agree',
      value: constants.LEGAL_PRIVACY_ACCEPTED,
    },
    learn_more: {
      label: 'Read full Privacy Statement',
      a: 'https://www.example.com',
    },
  },
  [legal_sections.TERMS_CONDITIONS]: {
    id: 'legal-tac-accept',
    title: 'Terms & Conditions',
    text: [
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.',
      'Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    ],
    action: {
      label: 'I agree',
      value: constants.TERMS_CONDITIONS_ACCEPTED,
    },
    learn_more: {
      label: 'Read full Terms & Conditions',
      a: 'https://www.example.com',
    },
  },
  [legal_sections.CONSENT_DISCLOSURE]: {
    id: 'consent-disclosure-accept',
    title: 'Consent for Disclosure',
    text: [
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.',
      'Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    ],
    action: {
      label: 'I agree',
      value: constants.CONSENT_DISCLOSURE_ACCEPTED,
    },
    learn_more: {
      label: 'Read full Consent Statement',
      a: 'https://www.example.com',
    },
  },
  [legal_sections.HIPAA_STATEMENT]: {
    id: 'hipaa-statement-accept',
    title: 'HIPAA Statement',
    text: [
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.',
      'Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    ],
    action: {
      label: 'I understand',
      value: constants.HIPAA_STATEMENT_ACCEPTED,
    },
    learn_more: {
      label: 'Read full HIPAA Statement',
      a: 'https://www.example.com',
    },
  }
};

export const legal_navigation_configuration : TLegalNavigation = {
  1: {
    sections: [legal_sections.PRIVACY, legal_sections.TERMS_CONDITIONS],
    next_index: 2,
  },
  2: {
    sections: [legal_sections.CONSENT_DISCLOSURE, legal_sections.HIPAA_STATEMENT],
    next_index: null,
  },
}