import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import App from './App';
import './custom.scss';
import reportWebVitals from './reportWebVitals';

import { ClerkProvider } from "@clerk/clerk-react";
import ErrorPage from './error';
import { ConfigHelper } from './helpers/config';
import PageTitle from './PageTitle';
import ProviderApp from './ProviderApp';
import AfterSignUpPage from './routes/after_signup';
import AuthMe from './routes/authme';
import { default as AdminIndex } from './routes/d/admin/index';
import HandleLogin from './routes/d/handle_login';
import { Intake as ProviderIntake } from './routes/d/provider/intake';
import IntakeReport, { loader as intakeReportLoader } from './routes/d/provider/intakeId/report';
import Legal from './routes/intake/legal';
import Welcome, { loader as welcomeLoader } from './routes/intake/welcome';
import LoginPage from './routes/login';
import SignUpPage from './routes/signup';
import Organizations from './routes/d/admin/organizations/organizations';
import OrganizationsCreate from './routes/d/admin/organizations/create';
import Organization, { loader as organizationsGetLoader } from './routes/d/admin/organizations/organization';
import ProvidersCreate, { loader as providersCreateLoader } from './routes/d/admin/organizations/providers/create';
import ProviderIndex from './routes/d/providers/index'
import { Dashboard as ProviderDashboard } from './routes/d/providers/dashboard'
import ProviderPatients from './routes/d/providers/patients/patients'
import ProviderPatient, {loader as providerPatientLoader} from './routes/d/providers/patients/patient'
import ProviderPatientCreate from './routes/d/providers/patients/create'
import ProviderPatientRecommendations, {loader as providerPatientRecommendationsLoader} from './routes/d/providers/patients/recommendations'

// import { IntakeItem as ProviderIntakeItem, loader as providerIntakeItemLoader } from './routes/d/provider/intake_item';

if (!process.env.REACT_APP_CLERK_PUBLISHABLE_KEY) {
  throw new Error();
}

const config_helper = new ConfigHelper();

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <ErrorPage />,
    children: [
      {
        index: true,
        element: <Welcome />,
        loader: welcomeLoader,
      },
      {
        path: 'legal/1',
        element: <Legal index={1} />,
      },
      {
        path: 'legal/2',
        element: <Legal index={2} />,
      }
    ],
  },
  {
    path: "/login",
    element: (
      <PageTitle title="Login">
        <LoginPage />
      </PageTitle>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/signup",
    element: (
      <PageTitle title="Sign Up">
        <SignUpPage />
      </PageTitle>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/after_signup",
    element: (
      <PageTitle title="Post Sign Up Steps">
        <AfterSignUpPage />
      </PageTitle>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/authme",
    element: <AuthMe />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/d",
    element: <ProviderApp />,
    errorElement: <ErrorPage />,
    children: [
      {
        index: true,
        element: <HandleLogin />,
      },
      {
        path: 'provider/intake',
        element: (
          <PageTitle title="Patient Information">
            <ProviderIntake />
          </PageTitle>
        ),
        children: [
          // {
          //   path: ':intakeId',
          //   loader: providerIntakeItemLoader,
          //   element: <ProviderIntakeItem />,
          // },
        ],
      },
      {
        path: 'provider/intake/:intakeId/report',
        loader: intakeReportLoader,
        element: (
          <PageTitle title="Patient Medication Recommendation">
            <IntakeReport />
          </PageTitle>
        ),
      },
      {
        path: 'providers/dashboard',
        element: (
          <PageTitle title="Your Dashboard">
            <ProviderIndex />
          </PageTitle>
        ),
        children: [
          {
            path: 'home',
            element: <ProviderDashboard />
          },
          {
            path: 'patients',
            element: <ProviderPatients />
          },
          {
            path: 'patients/:patient_uuid',
            loader: providerPatientLoader,
            element: <ProviderPatient />
          },
          {
            path: 'patients/:patient_uuid/recommendations',
            loader: providerPatientRecommendationsLoader,
            element: <ProviderPatientRecommendations />
          },
          {
            path: 'patients/create',
            element: <ProviderPatientCreate />
          },
        ],
      },
      {
        path: 'admin',
        element: <AdminIndex />,
        children: [
          {
            path: 'organizations',
            element: <Organizations />
          },
          {
            path: 'organizations/create',
            element: <OrganizationsCreate />,
          },
          {
            path: 'organizations/:organization_id',
            loader: organizationsGetLoader,
            element: <Organization />,
          },
          {
            path: 'organizations/:organization_id/providers/create',
            loader: providersCreateLoader,
            element: <ProvidersCreate />,
          },
        ],
      }
    ],
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <ClerkProvider publishableKey={config_helper.getClerkPublicKey()}>
      <RouterProvider router={router} />
    </ClerkProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
