import { Enum } from "./enums"
import { FollowUps } from "./follow_ups"
import { Wrappers } from "./wrappers"

export class IntakeData {
  unique_id: string
  name: string
  pregnant: boolean
  breastfeeding: boolean
  days_post_partum: number
  c_section: boolean
  planning_pregnancy: boolean
  gender: Enum.GenderEnum
  sex: Enum.SexEnum
  date_of_birth: string // TODO: convert to date
  ethnicity: string
  height: number
  weight: number
  first_menstruation: number
  sexual_activity: boolean
  previous_abortion: boolean
  abortion_trimester: Enum.TrimesterEnum | null
  symptoms_of_septic_abortion: boolean | null
  recent_blood_pressure: Wrappers.BloodPressure
  previous_birth_control: boolean
  previous_birth_control_methods: Enum.BirthControlTypesEnum[]
  previous_birth_control_details: number
  pill_usage_information: Wrappers.PillUsageInformation[]
  patch_usage_information: Wrappers.PatchUsageInformation[]
  iud_usage_information: Wrappers.IudUsageInformation[]
  implant_usage_information: Wrappers.ImplantUsageInformation[]
  injection_usage_information: Wrappers.InjectionUsageInformation[]
  ring_usage_information: Wrappers.RingUsageInformation[]
  current_medications: string[]  // TODO: Object
  current_supplements: string[]  // TODO: Object
  current_immunizations: string[]  // TODO: Object
  allergies: string[]
  birth_control_goals: Enum.BirthControlGoalsEnum[]
  menstrual_cramps_severity: number
  menstrual_cramps_past_treatments: boolean
  menstrual_cramps_challenges: string | null
  acne_severity: number
  acne_past_treatments: boolean | null
  acne_challenges: string | null
  hormonal_imbalance_condition: boolean | null
  hormonal_imbalance_treatment: boolean | null
  hormonal_imbalance_challenges: Enum.HormonalImbalanceChallengesEnum[]
  irregular_periods_severity: Enum.SeverityEnum | null
  irregular_periods_consultation: boolean | null
  irregular_periods_challenges: string | null
  bariatric_surgery: Enum.BariatricSurgeryEnum
  atherosclerotic_cv_disease: boolean
  hypertension: Enum.HypertensionEnum
  hypertension_bp: Enum.HypertensionBPEnum | null
  high_bp_pregnancy: boolean | null
  dvt_pe: Enum.DTVPEEnum
  thrombogenic_mutations: boolean
  superficial_venous_disorders: Enum.SuperficialVenousDisordersEnum
  ischemic_heart_disease: boolean
  stroke: boolean
  valvular_heart_disease: Enum.ValvularHeartDiseaseEnum
  valvular_heart_disease_complications: Enum.ValvularHeartDiseaseComplicationsEnum[]
  peripartum_cardiomyopathy: Enum.PeripartumCardiomyopathyEnum
  peripartum_cardiomyopathy_duration: Enum.PeripartumCardiomyopathyDurationEnum | null
  rheumatic_diseases: Enum.RheumaticDiseasesEnum[]
  other_rheumatic_disease: string | null
  rheumatic_diseases_sle_follow_up: FollowUps.RheumaticDiseasesSleFollowUp
  rheumatic_diseases_ra_follow_up: FollowUps.RheumaticDiseasesRaFollowup | null
  neurologic_conditions: Enum.NeurologicConditionsEnum[]
  neurologic_conditions_follow_up: FollowUps.NeurologicConditionsMsFollowUp | null
  mood_disorders: Enum.MoodDisordersEnum[]
  mood_disorders_undiagnosed: Enum.MoodDisordersEnum[]
  other_mood_disorder: string | null
  reproductive_tract_infections: Enum.ReproductiveTractInfectionsEnum[]
  cancer: Enum.CancersEnum | null
  cancer_follow_up: FollowUps.CancerFollowUp | null
  hiv: boolean
  hiv_follow_up: FollowUps.HivFollowUp
  infections: Enum.InfectionsEnum[]
  endocrine_conditions: Enum.EndocrineConditionsEnum[]
  diabetes_pregnant_follow_up: FollowUps.DiabetesPregnantFollowUp | null
  diabetes_follow_up: FollowUps.DiabetesFollowUp | null
  gi_conditions: Enum.GiConditionsEnum[]
  gi_conditions_gallbladder_follow_up: FollowUps.GiConditionsGallbladderFollowUp | null
  gi_conditions_viral_hepatitis_follow_up: FollowUps.GiConditionsViralHepatitisFollowUp | null
  gi_conditions_liver_tumors_follow_up: FollowUps.GiConditionsLiverTumorsFollowUp | null
  cystic_fibrosis: boolean
  anemias: Enum.AnemiaEnum[]
  solid_organ_transplantation: boolean
  solid_organ_transplantation_follow_up: FollowUps.SolidOrganTransplantationFollowUp | null
  birth_control_concerns: Enum.BirthControlConcernsEnum[]
  family_history: Wrappers.FamilyHistory
  lifestyle_activity: Enum.LifestyleActivityEnum
  lifestyle_diet: Enum.LifestyleDietEnum
  lifestyle_smoking: boolean
  lifestyle_smoking_follow_up: FollowUps.LifestyleSmokingFollowUp | null
  lifestyle_smoking_products_cigarettes_follow_up: FollowUps.LifestyleSmokingProductsCigarettesFollowUp | null
  rec_drugs_list: Enum.RecreationalDrugsEnum[]
  birth_control_preferences: Enum.BirthControlPreferencesEnum
  lab_details: Wrappers.LabDetails


  constructor(intake_data: { [x: string]: any }) {
    this.unique_id = intake_data['unique_id'];
    this.name = intake_data['name'];
    this.pregnant = intake_data['pregnant'];
    this.breastfeeding = intake_data['breastfeeding'];
    this.days_post_partum = intake_data['days_post_partum'];
    this.c_section = intake_data['c_section'];
    this.planning_pregnancy = intake_data['planning_pregnancy'];
    this.gender = intake_data['gender'];
    this.sex = intake_data['sex'];
    this.date_of_birth = intake_data['date_of_birth'];
    this.ethnicity = intake_data['ethnicity'];
    this.height = intake_data['height'];
    this.weight = intake_data['weight'];
    this.first_menstruation = intake_data['first_menstruation'];
    this.sexual_activity = intake_data['sexual_activity'];
    this.previous_abortion = intake_data['previous_abortion'];
    this.abortion_trimester = intake_data['abortion_trimester'];
    this.symptoms_of_septic_abortion = intake_data['symptoms_of_septic_abortion'];
    this.recent_blood_pressure = intake_data['recent_blood_pressure'];
    this.previous_birth_control = intake_data['previous_birth_control'];
    this.previous_birth_control_methods = intake_data['previous_birth_control_methods'];
    this.previous_birth_control_details = intake_data['previous_birth_control_details'];
    this.pill_usage_information = intake_data['pill_usage_information'];
    this.patch_usage_information = intake_data['patch_usage_information'];
    this.iud_usage_information = intake_data['iud_usage_information'];
    this.implant_usage_information = intake_data['implant_usage_information'];
    this.injection_usage_information = intake_data['injection_usage_information'];
    this.ring_usage_information = intake_data['ring_usage_information'];
    this.current_medications = intake_data['current_medications'];
    this.current_supplements = intake_data['current_supplements'];
    this.current_immunizations = intake_data['current_immunizations'];
    this.allergies = intake_data['allergies'];
    this.birth_control_goals = intake_data['birth_control_goals'];
    this.menstrual_cramps_severity = intake_data['menstrual_cramps_severity'];
    this.menstrual_cramps_past_treatments = intake_data['menstrual_cramps_past_treatments'];
    this.menstrual_cramps_challenges = intake_data['menstrual_cramps_challenges'];
    this.acne_severity = intake_data['acne_severity'];
    this.acne_past_treatments = intake_data['acne_past_treatments'];
    this.acne_challenges = intake_data['acne_challenges'];
    this.hormonal_imbalance_condition = intake_data['hormonal_imbalance_condition'];
    this.hormonal_imbalance_treatment = intake_data['hormonal_imbalance_treatment'];
    this.hormonal_imbalance_challenges = intake_data['hormonal_imbalance_challenges'];
    this.irregular_periods_severity = intake_data['irregular_periods_severity'];
    this.irregular_periods_consultation = intake_data['irregular_periods_consultation'];
    this.irregular_periods_challenges = intake_data['irregular_periods_challenges'];
    this.bariatric_surgery = intake_data['bariatric_surgery'];
    this.atherosclerotic_cv_disease = intake_data['atherosclerotic_cv_disease'];
    this.hypertension = intake_data['hypertension'];
    this.hypertension_bp = intake_data['hypertension_bp'];
    this.high_bp_pregnancy = intake_data['high_bp_pregnancy'];
    this.dvt_pe = intake_data['dvt_pe'];
    this.thrombogenic_mutations = intake_data['thrombogenic_mutations'];
    this.superficial_venous_disorders = intake_data['superficial_venous_disorders'];
    this.ischemic_heart_disease = intake_data['ischemic_heart_disease'];
    this.stroke = intake_data['stroke'];
    this.valvular_heart_disease = intake_data['valvular_heart_disease'];
    this.valvular_heart_disease_complications = intake_data['valvular_heart_disease_complications'];
    this.peripartum_cardiomyopathy = intake_data['peripartum_cardiomyopathy'];
    this.peripartum_cardiomyopathy_duration = intake_data['peripartum_cardiomyopathy_duration'];
    this.rheumatic_diseases = intake_data['rheumatic_diseases'];
    this.other_rheumatic_disease = intake_data['other_rheumatic_disease'];
    this.rheumatic_diseases_sle_follow_up = intake_data['rheumatic_diseases_sle_follow_up'];
    this.rheumatic_diseases_ra_follow_up = intake_data['rheumatic_diseases_ra_follow_up'];
    this.neurologic_conditions = intake_data['neurologic_conditions'];
    this.neurologic_conditions_follow_up = intake_data['neurologic_conditions_follow_up'];
    this.mood_disorders = intake_data['mood_disorders'];
    this.mood_disorders_undiagnosed = intake_data['mood_disorders_undiagnosed'];
    this.other_mood_disorder = intake_data['other_mood_disorder'];
    this.reproductive_tract_infections = intake_data['reproductive_tract_infections'];
    this.cancer = intake_data['cancer'];
    this.cancer_follow_up = intake_data['cancer_follow_up'];
    this.hiv = intake_data['hiv'];
    this.hiv_follow_up = intake_data['hiv_follow_up'];
    this.infections = intake_data['infections'];
    this.endocrine_conditions = intake_data['endocrine_conditions'];
    this.diabetes_pregnant_follow_up = intake_data['diabetes_pregnant_follow_up'];
    this.diabetes_follow_up = intake_data['diabetes_follow_up'];
    this.gi_conditions = intake_data['gi_conditions'];
    this.gi_conditions_gallbladder_follow_up = intake_data['gi_conditions_gallbladder_follow_up'];
    this.gi_conditions_viral_hepatitis_follow_up = intake_data['gi_conditions_viral_hepatitis_follow_up'];
    this.gi_conditions_liver_tumors_follow_up = intake_data['gi_conditions_liver_tumors_follow_up'];
    this.cystic_fibrosis = intake_data['cystic_fibrosis'];
    this.anemias = intake_data['anemias'];
    this.solid_organ_transplantation = intake_data['solid_organ_transplantation'];
    this.solid_organ_transplantation_follow_up = intake_data['solid_organ_transplantation_follow_up'];
    this.birth_control_concerns = intake_data['birth_control_concerns'];
    this.family_history = intake_data['family_history'];
    this.lifestyle_activity = intake_data['lifestyle_activity'];
    this.lifestyle_diet = intake_data['lifestyle_diet'];
    this.lifestyle_smoking = intake_data['lifestyle_smoking'];
    this.lifestyle_smoking_follow_up = intake_data['lifestyle_smoking_follow_up'];
    this.lifestyle_smoking_products_cigarettes_follow_up = intake_data['lifestyle_smoking_products_cigarettes_follow_up'];
    this.rec_drugs_list = intake_data['rec_drugs_list'];
    this.birth_control_preferences = intake_data['birth_control_preferences'];
    this.lab_details = intake_data['lab_details'];
  }
}