import { getBackendUrl } from "../../../../utils";
import { useFetch } from "../../../../helpers/clerk";
import { Link, useLoaderData } from "react-router-dom";
import { useState } from "react";
import { Spinner } from "react-bootstrap";

export type LoaderData = {
    organization_id: number,
}

type ProviderType = {
    id: number,
    email: string,
}

export async function loader({ params }: { params: { organization_id?: string } }) {
    return { organization_id: params.organization_id };
}

export default function Organization() {
    // TODO: We can make one API call to get both the organization and its providers as opposed to two
    const data = useLoaderData() as LoaderData;
    const [organization, setOrganization] = useState({ id: null, name: null, unique_key: null });
    const [providers, setProviders] = useState([]);
    const backend_request = useFetch();

    async function displayOrganization(response: Response) {
        const response_body = await response.json();
        setOrganization(
            {
                id: response_body.id,
                name: response_body.name,
                unique_key: response_body.unique_key
            }
        );
    }

    async function getProviders() {
        backend_request(getBackendUrl(`/api/providers/organizations/${data.organization_id}`), {
            method: 'GET',
        }).then((response) => {
            if (response.ok) {
                (async function (response: Response) {
                    const response_body = await response.json();
                    setProviders(response_body);
                })(response);
            }
        });
    }

    if (!organization.id) {
        backend_request(getBackendUrl(`/api/organizations/get/${data.organization_id}`), {
            method: 'GET',
        }).then((response) => {
            if (response.ok) {
                displayOrganization(response);
                getProviders();
            }
        });
    }

    return (
        <>
            <h1>Organization</h1>
            {!organization.id && (<Spinner />)}
            {organization.id && (
                <>
                    <div>
                        <div>ID: {organization.id}</div>
                        <div>Name: {organization.name}</div>
                        <div>Unique Key: {organization.unique_key}</div>
                    </div>
                    <div>
                        <h2>
                            Providers
                        </h2>
                        <div><Link to="providers/create">New Provider</Link></div>
                        <div>
                            <table>
                                <thead>
                                    <tr>
                                        <td>ID</td>
                                        <td>Email</td>
                                        <td></td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {providers && providers.map((provider: ProviderType) => {
                                        return (
                                            <tr>
                                                <td>{provider.id}</td>
                                                <td>{provider.email}</td>
                                                <td>View</td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </>
            )}
        </>
    );
}