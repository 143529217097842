import { Link } from "react-router-dom"
import { useFetch } from "../../../../helpers/clerk";
import { useState } from "react";
import { getBackendUrl } from "../../../../utils";

type OrganizationType = {
    id: number,
    name: string,
    unique_key: string,
}

export default function Organizations() {
    const backend_request = useFetch();
    const [organizations, setOrganizations] = useState([]);
    const [loading, setLoading] = useState(false);

    async function displayOrganizations(response: Response) {
        const response_body = await response.json();
        setOrganizations(response_body);
    }

    if (!loading) {
        setLoading(true);
        backend_request(getBackendUrl(`/api/organizations`), {
            method: 'GET',
        }).then((response) => {
            if (response.ok) {
                displayOrganizations(response);
            }
        });
    }

    return (
        <>
            <h1>Organizations</h1>
            <div>
                <div><Link to="create">New Organization</Link></div>
            </div>
            <div>
                <table>
                    <thead>
                        <tr>
                            <td>
                                ID
                            </td>
                            <td>
                                Name
                            </td>
                            <td>
                                Unique Key
                            </td>
                            <td>
                                &nbsp;
                            </td>
                        </tr>
                    </thead>
                    <tbody>
                        {organizations && organizations.map((organization: OrganizationType) => {
                            return (
                                <tr key={`org-${organization.id}`}>
                                    <td>{organization.id}</td>
                                    <td>{organization.name}</td>
                                    <td>{organization.unique_key}</td>
                                    <td><Link to={`/d/admin/organizations/${organization.id}`}>View Organization</Link></td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </>
    );
}