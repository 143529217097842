import Column from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import ToggleButton from 'react-bootstrap/ToggleButton';
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup';
import { legal_pages_configuration } from '../constants';
import { LegalTextConfiguration } from './classes';

type TLegalText = {
  section: string,
  defaultValue: string[],
  onValueChange: (value: string[]) => void,
}

export default function LegalText({ section, defaultValue, onValueChange }: TLegalText) {
  const configuration =
    new LegalTextConfiguration(
      Object.assign(
        legal_pages_configuration[section],
        { on_value_change: onValueChange }
      )
    );

  return (
    <>
      <Row>
        <h2>{configuration.title}</h2>
      </Row>
      <Row>
        {configuration.text.map((text, i) => {
          return (
            <p key={i}>{text}</p>)
        })}
      </Row>
      <Row>
        <Column className='d-inline-flex justify-content-left'><a href={configuration.learn_more.a} target='_'>{configuration.learn_more.label}</a></Column>
        <Column className='d-inline-flex justify-content-right'>
          <ToggleButtonGroup type="checkbox" defaultValue={defaultValue} onChange={configuration.on_value_change}>
            <ToggleButton
              id={configuration.id}
              name={configuration.id}
              data-testid={configuration.id}
              value={configuration.action.value}
              type="checkbox"
            >
              {configuration.action.label}
            </ToggleButton>
          </ToggleButtonGroup>
        </Column>
      </Row>
    </>
  );
};