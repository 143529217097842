import { RedirectToSignIn, SignedIn, SignedOut, useClerk } from "@clerk/clerk-react";
import { PropsWithChildren, ReactNode, useEffect, useState } from "react";
import { Accordion, Button, Col, Container, Dropdown, Row, Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { getAllIntakeData } from "../../../helpers/datasets/provider/intake/datasets";
import { Enum } from "../../../helpers/datasets/provider/intake/support/enums";
import { FollowUps } from "../../../helpers/datasets/provider/intake/support/follow_ups";
import { IntakeData } from "../../../helpers/datasets/provider/intake/support/intake_data";
import { BooleanTranslator, CigarettesPerDayTranslator, ContraceptiveBrandTranslator, DvtPeTranslator, HeightTranslator, HypertensionBpTranslator, HypertensionTranslator, IStringTranslator, PassthroughTranslator, PeripartumCardiomyopathyDurationTranslator, PeripartumCardiomyopathyTranslator, PossessiveTranslator, RheumaticDiseasesTranslator, SimpleTranslator, SuperficialVenousDisordersTranslator, ValvularHeartDiseaseComplicationsTranslator, ValvularHeartDiseaseTranslator } from "../../../helpers/datasets/provider/intake/support/translators";
import { Wrappers } from "../../../helpers/datasets/provider/intake/support/wrappers";

enum LabDetailItemThemeEnum {
  normal = 'normal',
  light = 'light'
}

type TListWithHeader = {
  header: string,
  items: string[]
}

type TRenderListArg = {
  items: (string | TListWithHeader)[],
  translator?: IStringTranslator,
  list_type?: string
}

type TLabDetailItem = {
  left: string,
  right: string,
  theme?: string,
  right_class?: string
}

type TLabResultItem = {
  eventKey: string,
  header: string,
  result: string | string[],
  unit: string | null,
  reference: string | string[],
  flag: string | string[] | null,
}

type TLabItemClass = {
  key: string,
  header: string,
  unit: string | null,
  reference: string | string[],
}

interface IRenderListItems {
  (args: TRenderListArg): ReactNode
}

const lab_item_classes: TLabItemClass[] = [
  {
    key: 'dhea',
    header: 'DHEA',
    unit: 'µg/dL, µmol/L',
    reference: [
      "Ages 18 to 19: 3.92 to 10.66 micromoles per liter (µmol/L)",
      "Ages 20 to 29: 65 to 380 µg/dL or 1.75 to 10.26 µmol/L.",
      "Ages 30 to 39: 45 to 270 µg/dL or 1.22 to 7.29 µmol/L.",
    ]
  },
  {
    key: 'testosterone',
    header: 'Testosterone',
    unit: 'ng/dL',
    reference: [
      "15 to 70 ng/dL or 0.5 to 2.4 nmol/L ",
      "For Transgender Men on Hormone Therapy: 300 - 1000 ng/dl",
    ]
  },
  {
    key: 'tsh_r_t4',
    header: 'TSH with reflex to T4',
    unit: 'mU/L',
    reference: "0.05-4.5 mU/L"
  },
  {
    key: 'amh',
    header: 'AMH',
    unit: 'ng/mL',
    reference: "Normal serum AMH level range is 2-6.8 ng/ml (14.28-48.55 pmol/l) in any phase of the cycle"
  },
  {
    key: 'e2',
    header: 'E2 (Estradiol)',
    unit: 'pg/mL',
    reference: "normal levels of estradiol (E2) for menstruating women range from 15 to 350 picograms per milliliter (pg/mL)"
  },
  {
    key: 'hgc',
    header: 'Pregnancy Test (HCG Levels)',
    unit: '',
    reference: "An hCG level of less than 5 mIU/mL is considered negative for pregnancy, and anything above 25 mIU/mL is considered positive for pregnancy."
  },
  {
    key: 'ast_alt',
    header: 'AST/ALT',
    unit: 'U/L',
    reference: "AST 17-42 U/L,  ALT 11-50 U/L"
  },
  {
    key: 'prolactin',
    header: 'Prolactin Level',
    unit: 'µg/L',
    reference: [
      "Nonpregnant women: less than 25 ng/mL (25 µg/L)",
      "Pregnant women: 80 to 400 ng/mL (80 to 400 µg/L)",
    ]
  },
  {
    key: 'glucose_hemoglobin_a1c',
    header: 'Glucose/Hemoglobin A1C',
    unit: null,
    reference: "<5.7 %"
  },
  {
    key: 'cbc',
    header: 'CBC',
    unit: null,
    reference: [
      "Red blood cell count - Female: 3.92 trillion to 5.13 trillion cells/L",
      "Hemoglobin: Female - 11.6 to 15 grams/dL (116 to 150 grams/L)",
      "Hematocrit: Female - 35.5% to 44.9%",
      "White blood cell count - 3.4 billion to 9.6 billion cells/L",
      "Platelet count: Female - 157 billion to 371 billion/L"
    ]
  },
  {
    key: 'fsh',
    header: 'FSH',
    unit: 'IU/L, mIU/mL',
    reference: [
      "Before puberty: 0 to 4.0 mIU/mL (0 to 4.0 IU/L)",
      "During puberty: 0.3 to 10.0 mIU/mL (0.3 to 10.0 IU/L) ",
      "Women who are still menstruating: 4.7 to 21.5 mIU/mL (4.5 to 21.5 IU/L)",
    ]
  },
  {
    key: 'lh',
    header: 'LH',
    unit: 'IU/L',
    reference: "5 to 25 IU/L. Level peaks even higher around the middle of the menstrual cycle."
  },
  {
    key: 'ldl_hdl_tg',
    header: 'LDL/HDL/TG',
    unit: "mg/dL",
    reference: [
      "LDL: <100 desirable; ≥190 very high",
      "HDL: ≥ 50",
      "TG: <150 desirable; ≥ 500 very high",
      "mg/dL",
    ]
  },
];

const CalculateBmi = (height: number, weight: number): string => {
  // Assumes inches and lbs
  return ((weight / (height ** 2)) * 703.0).toFixed(2);
}

const RenderListItems: IRenderListItems = ({ items, translator = SimpleTranslator, list_type = 'ol' }) => {
  if (list_type === 'ol') {
    return (
      <ol>
        {items.length < 1 && <li>None</li>}
        {items.length > 0 && items.map((val: string | TListWithHeader) => {
          if (typeof val === 'string') {
            return (
              <li>{translator(val)}</li>
            );
          }
          else {
            return (
              <>
                <li>{translator(val.header)}</li>
                <ol>
                  {val.items.map((ival: string) => {
                    return (
                      <li>
                        {translator(ival)}
                      </li>
                    );
                  })}
                </ol>
              </>
            );
          }
        })}
      </ol>
    );
  }

  return (
    <ul>
      {items.length < 1 && <li>None</li>}
      {items.length > 0 && items.map((val: string | TListWithHeader) => {
        if (typeof val === 'string') {
          return (
            <li>{translator(val)}</li>
          );
        }
        else {
          return (
            <>
              <li>{translator(val.header)}</li>
              <ul>
                {val.items.map((ival: string) => {
                  return (
                    <li>
                      {translator(ival)}
                    </li>
                  );
                })}
              </ul>
            </>
          );
        }
      })}
    </ul>
  );
}

const FamilyHistory = ({ history }: { history: Wrappers.FamilyHistory }): ReactNode => {
  const items = [
    `Heart Disease: ${BooleanTranslator(history.cardio)}`,
    `High Blood Pressure: ${BooleanTranslator(history.htn)}`,
    `Diabetes: ${BooleanTranslator(history.diabetes)}`,
    `Cancer: ${BooleanTranslator(history.cancer)}`,
    `Strokes: ${BooleanTranslator(history.stroke)}`,
    `Thyroid Disorders: ${BooleanTranslator(history.thyroid_disorders)}`,
    `Mental Health Disorders: ${BooleanTranslator(history.mental_health)}`,
    `Deep vein thrombosis / Experienced a pulmonary embolism: ${BooleanTranslator(history.thrombosis)}`,
  ];
  return RenderListItems({ items: items, translator: PassthroughTranslator });
}

const PastMedicalHistory = ({ ds }: { ds: IntakeData }): ReactNode => {
  const items = [] as (string | TListWithHeader)[];

  if (ds.sexual_activity) {
    items.push(`Has previously had an abortion: ${BooleanTranslator(ds.previous_abortion)}`);
    if (ds.previous_abortion) {
      items.push(`Abortion Trimester: ${SimpleTranslator(ds.abortion_trimester ? ds.abortion_trimester : 'Unknown')}`);
      items.push(`Experienced Septic Abortion: ${ds.symptoms_of_septic_abortion !== null ? BooleanTranslator(ds.symptoms_of_septic_abortion) : 'Unknown'}`);
    }
  }

  items.push(`Has Atherosclerotic Cardiovascular Disease: ${BooleanTranslator(ds.atherosclerotic_cv_disease)}`)
  items.push(`Has High Blood Pressure/Hypertension: ${HypertensionTranslator(ds.hypertension)}`)

  if (ds.hypertension === Enum.HypertensionEnum.yes_without_medications) {
    items.push(`Blood Pressure Range: ${HypertensionBpTranslator(ds.hypertension_bp)}`);
  }

  if (ds.pregnant) {
    items.push(`High BP during any pregnancy: ${BooleanTranslator(ds.high_bp_pregnancy)}`);
  }

  items.push(`Has been diagnosed with DVT/PE: ${DvtPeTranslator(ds.dvt_pe)}`);
  items.push(`Has Known Thrombogenic Mutations: ${BooleanTranslator(ds.thrombogenic_mutations)}`);
  items.push(`Has Varicose Veins: ${SuperficialVenousDisordersTranslator(ds.superficial_venous_disorders)}`);
  items.push(`Has Ischemic Heart Disease: ${BooleanTranslator(ds.ischemic_heart_disease)}`);
  items.push(`Has previously had a stroke: ${BooleanTranslator(ds.stroke)}`);
  items.push(`Has been diagnosed with conditions affecting heart valves: ${ValvularHeartDiseaseTranslator(ds.valvular_heart_disease)}`);

  if (ds.valvular_heart_disease === Enum.ValvularHeartDiseaseEnum.diagnosed_with_complications &&
    ds.valvular_heart_disease_complications !== null) {
    items.push(`Heart Valve Conditions: ${ds.valvular_heart_disease_complications.map((val: Enum.ValvularHeartDiseaseComplicationsEnum) => { return ValvularHeartDiseaseComplicationsTranslator(val); }).join(', ')}`);
  }

  if (ds.pregnant) {
    items.push(`Diagnosed with Peripartum Cardiomyopathy: ${PeripartumCardiomyopathyTranslator(ds.peripartum_cardiomyopathy)}`);
    if (ds.peripartum_cardiomyopathy === Enum.PeripartumCardiomyopathyEnum.diagnosed_normal_mild_impaired && ds.peripartum_cardiomyopathy_duration !== null) {
      items.push(`Duration of Peripartum Cardiomyopathy: ${PeripartumCardiomyopathyDurationTranslator(ds.peripartum_cardiomyopathy_duration)}`);
    }
  }

  items.push(`Rheumatic Diseases: ${ds.rheumatic_diseases.length < 1 ? 'None' : ds.rheumatic_diseases.map((value: Enum.RheumaticDiseasesEnum) => { return RheumaticDiseasesTranslator(value) }).join(', ')}`);
  if (ds.rheumatic_diseases.includes(Enum.RheumaticDiseasesEnum.other)) {
    items.push(`Other Rheumatic Diseases: ${ds.other_rheumatic_disease}`);
  }

  if (ds.rheumatic_diseases.includes(Enum.RheumaticDiseasesEnum.systemic_lupus_erythematosus)) {
    items.push(`Has Positive (or Unknown) Antiphospholipid Antibodies: ${BooleanTranslator(ds.rheumatic_diseases_sle_follow_up.antiphospholipid_antibodies)}`);
    items.push(`Diagnosed with Severe Thrombocytopenia: ${BooleanTranslator(ds.rheumatic_diseases_sle_follow_up.severe_thrombocytopenia)}`);
    items.push(`Currently Receiving Immunosuppressive Therapy for SLE: ${BooleanTranslator(ds.rheumatic_diseases_sle_follow_up.immunosuppressive_therapy)}`);
  }

  if (ds.rheumatic_diseases.includes(Enum.RheumaticDiseasesEnum.rheumatoid_arthritis) && ds.rheumatic_diseases_ra_follow_up) {
    items.push(`Currently Receiving Immunosuppressive Therapy for Rheumatoid Arthritis: ${BooleanTranslator(ds.rheumatic_diseases_ra_follow_up.immunosuppressive_therapy)}`);
  }

  // Sorta stopped making bespoke translators after this
  if (ds.neurologic_conditions.length < 1) {
    items.push(`Diagnosed/Experienced Neurological Conditions: None`);
  }
  else {
    items.push({
      header: 'Diagnosed/Experienced Neurological Conditions:',
      items: ds.neurologic_conditions.map((val: Enum.NeurologicConditionsEnum) => { return SimpleTranslator(val); }),
    });
  }

  if (ds.neurologic_conditions.includes(Enum.NeurologicConditionsEnum.multiple_sclerosis) && ds.neurologic_conditions_follow_up) {
    items.push(`Experiences Prolonged Immobilization: ${BooleanTranslator(ds.neurologic_conditions_follow_up.multiple_sclerosis_immobilization)}`);
  }

  if (ds.mood_disorders.length < 1) {
    items.push(`Diagnosed Mood Disorders: None`);
  }
  else {
    items.push({
      header: 'Diagnosed/Experienced Neurological Conditions:',
      items: ds.mood_disorders.map((val: Enum.MoodDisordersEnum) => { return SimpleTranslator(val) }),
    });
  }

  if (ds.mood_disorders_undiagnosed.length < 1) {
    items.push(`Undiagnosed Mood Disorders: None`);
  }
  else {
    items.push({
      header: 'Undiagnosed/Experienced Neurological Conditions:',
      items: ds.mood_disorders_undiagnosed.map((val: Enum.MoodDisordersEnum) => { return SimpleTranslator(val) }),
    });
  }

  if (ds.mood_disorders.includes(Enum.MoodDisordersEnum.other) && ds.other_mood_disorder) {
    items.push(`Other Mood Disorders: ${ds.other_mood_disorder}`);
  }

  items.push(`Reproductive Tract Infections: ${ds.reproductive_tract_infections.map((val: Enum.ReproductiveTractInfectionsEnum) => { return SimpleTranslator(val) }).join(', ')}`);

  // RTI_gestational_trophoblastic_disease
  // cancer
  // breast_disease_status

  items.push(`Have Been Diagnosed with HIV: ${BooleanTranslator(ds.hiv)}`);
  items.push(`Diagnosed Infections: ${ds.infections.length < 1 ? 'None' : ds.infections?.map((val: Enum.InfectionsEnum) => { return SimpleTranslator(val); }).join(', ')}`);
  items.push(`Endocrine Conditions: ${ds.endocrine_conditions.length < 1 ? 'None' : ds.endocrine_conditions?.map((val: Enum.EndocrineConditionsEnum) => { return SimpleTranslator(val); }).join(', ')}`);


  if (ds.endocrine_conditions.includes(Enum.EndocrineConditionsEnum.type_1_diabetes) ||
    ds.endocrine_conditions.includes(Enum.EndocrineConditionsEnum.type_2_diabetes)) {
    if (ds.pregnant && ds.diabetes_pregnant_follow_up) {
      items.push(`Has Previously had Gestational Diabetes: ${BooleanTranslator(ds.diabetes_pregnant_follow_up?.gestational_diabetes)}`);
    }

    if (ds.diabetes_follow_up) {
      items.push(`Duration living with Diabetes: ${ds.diabetes_follow_up.diabetes_lte_20_years ? '<= 20 years' : '> 20 years'}`);
      items.push(`Experiences Nephropathy, Retinopathy, or Neuropathy: ${BooleanTranslator(ds.diabetes_follow_up.experience_nephropathy_retinopathy_neuropathy)}`);
    }
  }

  items.push(`GI Conditions: ${ds.gi_conditions.length < 1 ? 'None' : ds.gi_conditions.map((val: Enum.GiConditionsEnum) => { return SimpleTranslator(val); }).join(', ')}`);

  if (ds.gi_conditions.includes(Enum.GiConditionsEnum.gallbladder_disease) && ds.gi_conditions_gallbladder_follow_up) {
    items.push(`Is Symptomatic with Gallbladder Disease: ${BooleanTranslator(ds.gi_conditions_gallbladder_follow_up.gallbladder_disease)}`);
  }

  if (ds.gi_conditions.includes(Enum.GiConditionsEnum.viral_hepatitis) && ds.gi_conditions_viral_hepatitis_follow_up) {
    items.push(`Viral Hepatitis Status: ${SimpleTranslator(ds.gi_conditions_viral_hepatitis_follow_up.viral_hepatitis_status)}`);
  }

  if (ds.gi_conditions.includes(Enum.GiConditionsEnum.liver_tumors) && ds.gi_conditions_liver_tumors_follow_up) {
    items.push(`Type of Liver Tumor: ${SimpleTranslator(ds.gi_conditions_liver_tumors_follow_up.liver_tumor)}`);
  }

  items.push(`Has Cystic Fibrosis: ${BooleanTranslator(ds.cystic_fibrosis)}`);

  items.push(`Have the Following Anemias: ${ds.anemias.length < 1 ? 'None' : ds.anemias.map((val: Enum.AnemiaEnum) => { return SimpleTranslator(val) }).join(', ')}`);

  return RenderListItems({ items: items, translator: PassthroughTranslator, list_type: 'ul' });
}

const contraceptive_usage_information = (
  name: string,
  usage_information:
    (
      Wrappers.PillUsageInformation |
      Wrappers.PatchUsageInformation |
      Wrappers.IudUsageInformation |
      Wrappers.ImplantUsageInformation |
      Wrappers.InjectionUsageInformation |
      Wrappers.RingUsageInformation
    )[]): TListWithHeader | null => {
  let retval = null;
  if (usage_information.length > 0) {
    const info: TListWithHeader = {
      header: 'Pill Usage Information',
      items: [],
    }
    usage_information.forEach((usage_information) => {
      info.items.push(`${ContraceptiveBrandTranslator(usage_information.brand)}, ${usage_information.start_date} - ${usage_information.stop_date ? usage_information.stop_date : 'current'}${usage_information.stop_reason ? ', stopped because: ' + usage_information.stop_reason : ''}`);
    });

    retval = info;
  }
  return retval;
}

const MedicationHistory = ({ ds }: { ds: IntakeData }): ReactNode => {
  const items = [] as (string | TListWithHeader)[];

  if (!ds.previous_birth_control) {
    items.push(`No Birth Control Methods Used`);
  }
  else {
    if (ds.previous_birth_control_methods.length > 0) {
      items.push(`Birth Control Methods Used: ${ds.previous_birth_control_methods.map((val: Enum.BirthControlTypesEnum) => { return SimpleTranslator(val); }).join(', ')}`);

      const methods_with_info: string[] = ['Pill', 'Patch', 'IUD', 'Implant', 'Injection', 'Ring'];
      methods_with_info.forEach((val) => {
        const index = `${val.toLowerCase()}_usage_information`;
        const usage_information = ds[index as keyof typeof ds];
        if (usage_information !== null) {
          // @ts-ignore
          const result = contraceptive_usage_information(index, usage_information);
          if (result) {
            items.push(result);
          }
        }
      });
    }
  }

  return RenderListItems({ items: items, translator: PassthroughTranslator, list_type: 'ul' });
}

const SocialHistory = ({ sexual_activity, lifestyle_smoking, lifestyle_smoking_follow_up, lifestyle_smoking_products_cigarettes_follow_up, rec_drugs_list }:
  {
    sexual_activity: boolean,
    lifestyle_smoking: boolean,
    lifestyle_smoking_follow_up: FollowUps.LifestyleSmokingFollowUp | null,
    lifestyle_smoking_products_cigarettes_follow_up: FollowUps.LifestyleSmokingProductsCigarettesFollowUp | null,
    rec_drugs_list: Enum.RecreationalDrugsEnum[]
  }): ReactNode => {
  const items = [] as string[];

  items.push(`Is currently or has been sexually active: ${BooleanTranslator(sexual_activity)}`);

  if (lifestyle_smoking && lifestyle_smoking_follow_up?.products.includes(Enum.SmokingProductsEnum.cigarette)) {
    items.push(`Smoker${lifestyle_smoking_products_cigarettes_follow_up?.cigarettes_per_day ? `: (${CigarettesPerDayTranslator(String(lifestyle_smoking_products_cigarettes_follow_up.cigarettes_per_day))})` : ''}`);
  }

  let non_cig_products: any[] = lifestyle_smoking_follow_up?.products ? lifestyle_smoking_follow_up?.products.filter((val) => { return val !== Enum.SmokingProductsEnum.cigarette }) : [];
  non_cig_products = non_cig_products.concat(rec_drugs_list);
  if (non_cig_products.length > 0) {
    items.push(`Other products used: ${non_cig_products.map((val) => { return SimpleTranslator(val) }).join(', ')}`);
  }

  if (items.length < 1) {
    items.push('No history given.');
  }

  return RenderListItems({ items: items, translator: PassthroughTranslator });
}

const SectionHeader = (props: PropsWithChildren): JSX.Element => {
  return (
    <div>
      <strong>{props.children}</strong>
    </div>
  );
}

const Section = (props: PropsWithChildren): JSX.Element => {
  return (
    <div style={{ marginBottom: '8px' }}>
      {props.children}
    </div>
  );
}

const LabDetailItem = ({ left, right, theme = LabDetailItemThemeEnum.normal, right_class }: TLabDetailItem): JSX.Element => {
  return (
    <Container className={['labdetails', theme].join(' ')}>
      <Row>
        <Col s={6} className="flex-column">
          {left}
        </Col>
        <Col s={{ span: 2, offset: 4 }} className={"flex-column" + right_class ? ` ${right_class}` : ''} style={{ textAlign: 'right' }}>
          {right}
        </Col>
      </Row>
    </Container>
  );
}

const LabResult = ({ lab_results }: { lab_results: Wrappers.LabResults }) => {
  return (
    <Accordion className="celeste" flush alwaysOpen>
      {lab_item_classes.map((li_class: TLabItemClass) => {
        return (
          <LabResultItem
            key={li_class.key}
            eventKey={li_class.key}
            header={li_class.header}
            result={lab_results[li_class.key as keyof typeof lab_results]['results']}
            unit={li_class.unit}
            reference={li_class.reference}
            flag={lab_results[li_class.key as keyof typeof lab_results]['flags']}
          />
        );
      })}
    </Accordion>
  );
}

const LabResultItem = ({ eventKey, header, result, unit, reference, flag }: TLabResultItem): JSX.Element => {
  return (
    <Accordion.Item eventKey={eventKey}>
      <Accordion.Header>{header}</Accordion.Header>
      <Accordion.Body>
        <Container>
          <Row>
            <Col s={6}>
              {typeof result === 'string' && (
                <div>
                  Results: {result}
                </div>
              )}
              {typeof result === 'object' && (
                <>
                  <div>
                    Results:
                  </div>
                  <ul>
                    {result.map((val: string) => {
                      return (
                        <li>
                          {val}
                        </li>
                      );
                    })}
                  </ul>
                </>
              )}
              {unit && (
                <div>
                  Units: {unit}
                </div>
              )}
            </Col>
            <Col s={{ span: 3, offset: 3 }}>
              <div>
                <div>Reference Interval:</div>
                {typeof reference === 'string' && (
                  <div style={{ paddingLeft: '16px' }}>{reference}</div>
                )}
                {typeof reference === 'object' && (
                  <ul>
                    {reference.map((val: string) => {
                      return (
                        <li>
                          {val}
                        </li>
                      );
                    })}
                  </ul>
                )}
              </div>
              <div>
                {flag && typeof flag == 'string' && (
                  <>
                    Flag: {flag}
                  </>
                )}
                {flag && typeof flag == 'object' && (
                  <>
                    <div>Flag:</div>
                    <ul>
                      {flag.map((val: string) => {
                        return (
                          <li>
                            {val}
                          </li>
                        );
                      })}
                    </ul>
                  </>
                )}
              </div>
            </Col>
          </Row>
        </Container>
      </Accordion.Body>
    </Accordion.Item>
  );
}

function IntakeItem({ id, full_name, current_id, onClick }: { id: string, full_name: string, current_id: string | null, onClick: (k: string) => void }) {
  const active_object = {} as { [k: string]: any };

  if (id === current_id) {
    active_object['active'] = true;
  }

  return (
    <Dropdown.Item
      key={id}
      {...active_object}
      onClick={(e) => {
        e.preventDefault();
        onClick(id);
      }}
    >
      {full_name}
    </Dropdown.Item>
  );
}


export function Intake() {
  const [dropdownText, setDropdownText] = useState('');
  const [intakeId, setIntakeId] = useState('');
  const [generatingReport, setGeneratingReport] = useState(false);
  const [currentDataset, setCurrentDataset] = useState<null | IntakeData>(null);
  const { signOut } = useClerk();
  const datasets = getAllIntakeData();
  const navigate = useNavigate();

  function updateDataSet() {
    if (datasets[intakeId as keyof typeof datasets]) {
      setDropdownText(datasets[intakeId as keyof typeof datasets].name);
      setCurrentDataset(new IntakeData(datasets[intakeId as keyof typeof datasets]));
    }
  }

  function generateReport(unique_id: string) {
    setGeneratingReport(true);
    setTimeout(() => navigate(`${unique_id}/report`), 3000);
  }

  useEffect(() => {
    updateDataSet();
  }, [intakeId]);

  return (
    <>
      <Container>
        <SignedOut>
          <RedirectToSignIn />
        </SignedOut>
        <SignedIn>
          <Row className="align-items-center">
            <Col s={2}>Patient name: </Col>
            <Col s={2}>
              <Row>
                <Dropdown>
                  <Dropdown.Toggle variant="success" id="dropdown-basic">
                    {dropdownText ? dropdownText : 'Select Mock Patient'}
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    {Object.entries(datasets).map(([k, v]) => {
                      return (
                        <IntakeItem
                          key={k}
                          id={k}
                          full_name={v.name}
                          current_id={intakeId}
                          onClick={setIntakeId}
                        />);
                    })}
                  </Dropdown.Menu>
                </Dropdown>
              </Row>
            </Col>
            <Col s={{ span: 2, offset: 6 }} className="ml-auto d-flex justify-content-end">Date: {new Date().toLocaleDateString()}</Col>
          </Row>

          {currentDataset !== null && (
            <>
              <Row>
                <Col s={6}>
                  Patient Unique Identifier: {currentDataset.unique_id}
                </Col>
                <Col s={{ span: 3, offset: 3 }} className="d-flex justify-content-end">
                  Date of Birth: {currentDataset.date_of_birth}
                </Col>
              </Row>
              <Container className="border-celeste-provider">
                <Row>
                  <Col><strong>Patient Intake:</strong></Col>
                </Row>
                <Row>
                  <Col className="flex-column">
                    <Section>
                      <SectionHeader>Goals for Birth Control:</SectionHeader>
                      {RenderListItems({ items: currentDataset.birth_control_goals })}
                      <div><strong>Birth Control Preferences:</strong> {SimpleTranslator(currentDataset.birth_control_preferences)}</div>
                    </Section>

                    <Section>
                      <SectionHeader>Concerns about Birth Control</SectionHeader>
                      {RenderListItems({ items: currentDataset.birth_control_concerns })}
                    </Section>

                    <Section>
                      <SectionHeader>Current Health:</SectionHeader>
                      <ul>
                        <li>Date of Birth: {currentDataset.date_of_birth}</li>
                        <li>Ht: {HeightTranslator(currentDataset.height)}</li>
                        <li>Weight: {currentDataset.weight} lbs</li>
                        <li>BMI: {CalculateBmi(currentDataset.height, currentDataset.weight)}</li>
                        <li>Ethniciy: {currentDataset.ethnicity}</li>
                        <li>Sex: {currentDataset.sex}</li>
                        <li>Gender: {currentDataset.gender}</li>
                        <li>Has Ever Been Pregnant: {BooleanTranslator(currentDataset.pregnant)}</li>
                        {currentDataset.pregnant && (
                          <>
                            <li>Currently Breastfeeting: {BooleanTranslator(currentDataset.breastfeeding)}</li>
                            <li>Had a C-Section: {BooleanTranslator(currentDataset.c_section)}</li>
                            <li>Planning on getting pregnant again soon: {BooleanTranslator(currentDataset.planning_pregnancy)}</li>
                          </>
                        )}
                        {!currentDataset.pregnant && (
                          <>
                            <li>Interested in getting pregnant soon: {BooleanTranslator(currentDataset.planning_pregnancy)}</li>
                          </>
                        )}
                        <li>Age of First Menstruation: {currentDataset.first_menstruation}</li>
                      </ul>
                    </Section>

                    <Section>
                      <SectionHeader>Current Medications:</SectionHeader>
                      {RenderListItems({ items: currentDataset.current_medications, list_type: 'ul' })}
                    </Section>

                    <Section>
                      <SectionHeader>Current Supplements:</SectionHeader>
                      {RenderListItems({ items: currentDataset.current_supplements, list_type: 'ul' })}
                    </Section>

                    <Section>
                      <SectionHeader>Current Immunizations:</SectionHeader>
                      {RenderListItems({ items: currentDataset.current_immunizations, list_type: 'ul' })}
                    </Section>

                    <Section>
                      <SectionHeader>Past Medical History:</SectionHeader>
                      {PastMedicalHistory({
                        ds: currentDataset,
                      })}
                    </Section>
                  </Col>
                  <Col className="flex-column">
                    <Section>
                      <SectionHeader>Medication History</SectionHeader>
                      {MedicationHistory({
                        ds: currentDataset,
                      })}
                    </Section>

                    <Section>
                      <SectionHeader>Allergies and Medication Intolerances</SectionHeader>
                      <div>{currentDataset.allergies.join(', ')}</div>
                    </Section>

                    <Section>
                      <SectionHeader>Family History</SectionHeader>
                      {FamilyHistory({ history: currentDataset.family_history })}
                    </Section>

                    <Section>
                      <SectionHeader>Social History</SectionHeader>
                      {SocialHistory({
                        sexual_activity: currentDataset.sexual_activity,
                        lifestyle_smoking: currentDataset.lifestyle_smoking,
                        lifestyle_smoking_follow_up: currentDataset.lifestyle_smoking_follow_up,
                        lifestyle_smoking_products_cigarettes_follow_up: currentDataset.lifestyle_smoking_products_cigarettes_follow_up,
                        rec_drugs_list: currentDataset.rec_drugs_list
                      })}
                    </Section>

                    <Section>
                      <SectionHeader>Lifestyle and Preferences</SectionHeader>
                      <ul>
                        <li>Level of Activity: {currentDataset.lifestyle_activity}</li>
                        <li>Diet: {SimpleTranslator(currentDataset.lifestyle_diet)}</li>
                      </ul>
                    </Section>
                  </Col>
                </Row>
                <Row>
                  <Col s={12}><strong>&nbsp;&nbsp;&nbsp;&nbsp;{PossessiveTranslator(currentDataset.name)} Biomarker Profile</strong></Col>
                </Row>
                <Row>
                  <Col className="flex-column">
                    <LabDetailItem
                      left="Lab Facility"
                      right="LabCorp"
                    />
                    <LabDetailItem
                      left="Speciment Number"
                      right="22620300090"
                    />
                    <LabDetailItem
                      left="Date/Time Collected"
                      right="2023-08-13 21:00:00 PDT"
                    />
                    <LabDetailItem
                      left="Date/Time Entered"
                      right="2023-08-13 21:00:00 PDT"
                    />
                    <LabDetailItem
                      left="Date/Time Reported"
                      right="2023-08-15 01:35:00 PDT"
                    />
                  </Col>
                  <Col className="flex-column">
                    <LabDetailItem
                      left="NPI#"
                      right="1548739204"
                    />
                    <LabDetailItem
                      left="Overall Report Status"
                      right="FINAL"
                      right_class="font-celeste-pink"
                    />
                    <LabDetailItem
                      left="Ordering Physician"
                      right="LECLAIR, E"
                    />
                    <LabDetailItem
                      left="Date/Time Entered"
                      right="2023-08-13 21:00:00 PDT"
                      theme={LabDetailItemThemeEnum.light}
                    />
                    <LabDetailItem
                      left="Date/Time Reported"
                      right="2023-08-15 01:35:00 PDT"
                      theme={LabDetailItemThemeEnum.light}
                    />
                  </Col>
                </Row>
                <Row style={{ marginTop: '16px', marginBottom: '16px' }}>
                  <Col s={12} className="font-celeste-purple">&nbsp;&nbsp;&nbsp;&nbsp;Results</Col>
                </Row>
                <Row>
                  <LabResult
                    lab_results={currentDataset.lab_details.lab_results}
                  />
                </Row>
              </Container>
            </>
          )}
          {currentDataset !== null && (
            <Row className="d-flex justify-content-center" style={{ marginTop: '40px' }}>
              <Col s={4} className="d-flex justify-content-center">
                <Button
                  href="#"
                  as="span"
                  onClick={() => generateReport(currentDataset.unique_id)}
                  disabled={generatingReport}
                  aria-label="Generate medication recommendation"
                  role="button"
                >
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    className={generatingReport ? "" : 'd-none'}
                  />
                  <span
                    className={generatingReport ? "d-none" : ''}
                  >
                    Generate Medication Recommendation
                  </span>
                  <span
                    className={generatingReport ? "" : 'd-none'}
                  >
                    &nbsp;Generating...
                  </span>
                </Button>
              </Col>
            </Row>
          )}

          <Row className="d-flex justify-content-end">
            <Col style={{ flexGrow: 1 }}>
            </Col>
            <Col style={{ flexGrow: 0, minWidth: "100px" }}>
              <a
                href="#"
                onClick={(e) => { e.preventDefault(); signOut() }}>
                Log out
              </a>
            </Col>
          </Row>
        </SignedIn>
      </Container>
    </>
  );
}