import { RedirectToSignIn, SignedIn, SignedOut, useClerk } from "@clerk/clerk-react";
import CelesteSignOutButton from "../../../components/SignOutButton";
import { Col, Container, Image, Row, Spinner } from "react-bootstrap";
import { Link, useNavigate, useOutlet } from "react-router-dom";
import { useState } from "react";
import { useFetch } from "../../../helpers/clerk";
import { getBackendUrl } from "../../../utils";

const TopNav = ({ children }: { children: any }) => {
  return (
    <Row className="mb-4">
      <Col xs={11}>
        <div>{children}</div>
      </Col>
      <Col xs={1}>
        <div><CelesteSignOutButton /></div>
      </Col>
    </Row>
  );
}

const LeftNav = ({ children }: { children: any }) => {
  return (
    <Col
      style={{ background: '#CCC', minHeight: '480px' }}
    >
      {children}
    </Col>
  );
}

const RightContents = ({ children }: { children: any }) => {
  return (
    <Col
      xs={10}
      style={{ background: '#DDD' }}
    >
      {children}
    </Col>
  );
}

function Home() {
  return (
    <>
      Home
    </>
  );
}

export default function Index() {
  const outlet = useOutlet();
  const [loaded, setLoaded] = useState(false);
  const navigate = useNavigate();
  const backend_request = useFetch();

  // Block non-superuser admins from accessing this route

  if (!loaded) {
    backend_request(getBackendUrl('/api/auth/whoami'), {
      method: 'GET',
    }).then((response) => {
      if (response.ok) {
        (async function (response: Response) {
          const response_body = await response.json();
          if (response_body["user_type"] == "superuser") {
            setLoaded(true);
          }
          else {
            navigate('/d');
          }
        })(response);
      }
      else {
        // TODO: An error here isn't supposed to just go straight back to the /d redirect
        setLoaded(true);
        alert('A fatal error has occured.');
        // navigate('/d');
      }
    });
  }

  return (
    <>
      <SignedOut>
        <RedirectToSignIn />
      </SignedOut>
      <SignedIn>
        {!loaded && <Spinner />}
        {loaded && (<>
          <TopNav>
            <Image src={process.env.PUBLIC_URL + "/celeste.svg"} />
          </TopNav>
          <Container fluid>
            <Row>
              <LeftNav>
                <div>
                  <ul>
                    <li>
                      <Link to="">
                        <>
                          Home
                        </>
                      </Link>
                    </li>
                    <li>
                      <Link to="organizations">
                        <>
                          Organizations
                        </>
                      </Link>
                    </li>
                  </ul>
                </div>
              </LeftNav>
              <RightContents>
                {outlet || (<Home />)}
              </RightContents>
            </Row>
          </Container>
        </>)}
      </SignedIn>
    </>
  );
}