export namespace Enum {
  export enum TrimesterEnum {
    first = "first",
    second = "second",
    third = "third"
  }

  export enum BirthControlGoalsEnum {
    menstrual_cramps = "menstrual_cramps",
    acne = "acne",
    hormonal_imbalance = "hormonal_imbalance",
    irregular_periods = "irregular_periods",
    pregnancy = "pregnancy"
  }

  export enum HormonalImbalanceChallengesEnum {
    irregular_menstrual_cycles = "irregular_menstrual_cycles",
    mood_swings = "mood_swings",
    fatigue = "fatigue",
    weight_changes = "weight_changes",
    sleep_disturbances = "sleep_disturbances",
    hair_and_skin_issues = "hair_and_skin_issues",
    digestive_problems = "digestive_problems",
    changes_in_libido = "changes_in_libido",
    hot_flashes_and_night_sweats = "hot_flashes_and_night_sweats",
    changes_in_appetite = "changes_in_appetite",
    cognitive_changes = "cognitive_changes",
    changes_in_body_temperature = "changes_in_body_temperature",
    muscle_and_joint_pain = "muscle_and_joint_pain",
    headaches = "headaches",
    heart_palpitations = "heart_palpitations",
    changes_in_blood_sugar_levels = "changes_in_blood_sugar_levels",
    changes_in_blood_pressure = "changes_in_blood_pressure",
    changes_in_hair_and_nail_quality = "changes_in_hair_and_nail_quality",
    breast_changes = "breast_changes",
    thyroid_related_symptoms = "thyroid_related_symptoms"
  }

  export enum BariatricSurgeryEnum {
    no = "no",
    yes_restrict_eating = "yes_restrict_eating",
    yes_restrict_eating_nutrients = "yes_restrict_eating_nutrients"
  }

  export enum HypertensionEnum {
    no = "no",
    yes_with_medications = "yes_with_medications",
    yes_without_medications = "yes_without_medications",
    yes_with_vascular_disease = "yes_with_vascular_disease"
  }

  export enum HypertensionBPEnum {

    // 1. Systolic 140–159 mm Hg or diastolic 90–99 mm Hg, 2. Systolic ≥160 mm Hg or diastolic ≥100 mm Hg 3. Systolic </=130 or diastolic </=80

    systolic_140_159_diastolic_90_99 = "systolic_140_159_diastolic_90_99",
    systolic_gte_160_diastolic_gte_100 = "systolic_gte_160_diastolic_gte_100",
    systolic_lte_130_diastolic_lte_80 = "systolic_lte_130_diastolic_lte_80"
  }

  export enum DTVPEEnum {
    no = "no",
    leg_resolved_without_medications = "leg_resolved_without_medications",
    leg_resolved_with_medications = "leg_resolved_with_medications",
    lung_clot_or_pulmonary_embolism = "lung_clot_or_pulmonary_embolism"
  }

  export enum SuperficialVenousDisordersEnum {
    no = "no",
    yes_visible = "yes_visible",
    yes_history = "yes_history"
  }

  export enum ValvularHeartDiseaseEnum {
    no = "no",
    diagnosed_without_complications = "diagnosed_without_complications",
    diagnosed_with_complications = "diagnosed_with_complications"
  }

  export enum ValvularHeartDiseaseComplicationsEnum {
    pulmonary_hypertension = "pulmonary_hypertension",
    risk_for_atrial_fibrillation = "risk_for_atrial_fibrillation",
    subacute_bacterial_endocarditis = "subacute_bacterial_endocarditis"
  }

  export enum PeripartumCardiomyopathyEnum {
    no = "no",
    diagnosed_normal_mild_impaired = "diagnosed_normal_mild_impaired",
    diagnosed_moderate_severe_impaired = "diagnosed_moderate_severe_impaired"
  }

  export enum PeripartumCardiomyopathyDurationEnum {
    lt_6_months = "lt_6_months",
    gte_6_months = "gte_6_months"
  }

  export enum RheumaticDiseasesEnum {
    systemic_lupus_erythematosus = "systemic_lupus_erythematosus",
    rheumatoid_arthritis = "rheumatoid_arthritis",
    other = "other"
  }

  export enum NeurologicConditionsEnum {
    headaches = "headaches",
    migranes_with_aura = "migranes_with_aura",
    migranes_without_aura = "migranes_without_aura",
    epilepsy = "epilepsy",
    multiple_sclerosis = "multiple_sclerosis"
  }

  export enum ReproductiveTractInfectionsEnum {
    irregular_menstrual_cycles_no_heavy_bleeding = "irregular_menstrual_cycles_no_heavy_bleeding",
    heavy_periods = "heavy_periods",
    unexplained_vaginal_bleeding = "unexplained_vaginal_bleeding",
    endometriosis = "endometriosis",
    benign_ovarian_tumors = "benign_ovarian_tumors",
    severe_dysmenorrhea = "severe_dysmenorrhea",
    gestational_trophoblastic_disease = "gestational_trophoblastic_disease",
    cervical_ectropion = "cervical_ectropion",
    cervical_intraepithelial_neoplasia = "cervical_intraepithelial_neoplasia",
    cervical_cancer_awaiting_treatment = "cervical_cancer_awaiting_treatment",
    breast_disease_or_cancer = "breast_disease_or_cancer",
    endometrial_hyperplasia = "endometrial_hyperplasia",
    endometrial_cancer = "endometrial_cancer",
    ovarian_cancer = "ovarian_cancer",
    uterine_fibroids = "uterine_fibroids",
    distorted_uterine_cavity = "distorted_uterine_cavity",
    pelvic_inflammatory_disease = "pelvic_inflammatory_disease",
    stds_current_or_past = "stds_current_or_past",
    vaginitis = "vaginitis",
    other = "other",
  }

  export enum BreastDiseaseStatusEnum {
    undiagnosed_mass = "undiagnosed_mass",
    benign_breast_disease = "benign_breast_disease",
    current_breast_cancer = "current_breast_cancer",
    past_breast_cancer = "past_breast_cancer"
  }

  export enum MoodDisordersEnum {
    major_depressive_disorder = "major_depressive_disorder",
    persistent_depressive_disorder = "persistent_depressive_disorder",
    bipolar_disorder = "bipolar_disorder",
    seasonal_affective_disorder = "seasonal_affective_disorder",
    postpartum_depression = "postpartum_depression",
    premenstrual_dysphoric_disorder = "premenstrual_dysphoric_disorder",
    disruptive_mood_dysregulation_disorder = "disruptive_mood_dysregulation_disorder",
    atypical_depression = "atypical_depression",
    anxiety = "anxiety",
    other = "other",

    // TODO: keep?
    posttraumatic_stress_disorder = "posttraumatic_stress_disorder"
  }

  export enum InfectionsEnum {
    schistosomiasis = "schistosomiasis",
    fibrosis_of_the_liver = "fibrosis_of_the_liver",
    pelvic_tuberculosis = "pelvic_tuberculosis",
    non_pelvic_tuberculosis = "non_pelvic_tuberculosis",
    malaria = "malaria",
    other = "other"
  }

  export enum EndocrineConditionsEnum {
    type_1_diabetes = "type_1_diabetes",
    type_2_diabetes = "type_2_diabetes",
    hyperthyroidism = "hyperthyroidism",
    hypothyroidism = "hypothyroidism",
    simple_goiter_thyroid_disorder = "simple_goiter_thyroid_disorder",
    other = "other"
  }

  export enum GiConditionsEnum {
    gallbladder_disease = "gallbladder_disease",
    pregnancy_related_cholestasis = "pregnancy_related_cholestasis",
    hormonal_birth_control_related_cholestasis = "hormonal_birth_control_related_cholestasis",
    viral_hepatitis = "viral_hepatitis",
    mild_cirrhosis = "mild_cirrhosis",
    severe_cirrhosis = "severe_cirrhosis",
    liver_tumors = "liver_tumors",
    other = "other"
  }

  export enum ViralHepatitisEnum {
    acute = "acute",
    carrier = "carrier",
    chronic = "chronic",
    not_present = "not_present"
  }

  export enum LiverTumorsEnum {
    focal_nodular_hyperplasia = "focal_nodular_hyperplasia",
    hepatocellular_adenoma = "hepatocellular_adenoma",
    hepatoma = "hepatoma",
    dont_know = "dont_know"
  }

  export enum AnemiaEnum {
    thalassemia = "thalassemia",
    sickle_cell_disease = "sickle_cell_disease",
    iron_deficiency_anemia = "iron_deficiency_anemia"
  }

  export enum BirthControlConcernsEnum {
    effectiveness = "effectiveness",
    side_effects = "side_effects",
    health_risks = "health_risks",
    convenience = "convenience",
    cost = "cost",
    interference_with_sexual_spontaneity = "interference_with_sexual_spontaneity",
    impact_on_fertility = "impact_on_fertility",
    other = "other"
  }

  export enum SeverityEnum {
    // TODO: Delete these
    mild = "mild",
    moderate = "moderate",
    severe = "severe",

    regular = "regular",
    off_few_days = "off_few_days",
    off_few_weeks = "off_few_weeks",
    completely_unpredictable = "completely_unpredictable",
  }

  export enum CancersEnum {
    breast_cancer = "breast_cancer",
    liver_tumor = "liver_tumor"
  }

  export enum LifestyleActivityEnum {
    sedentary = "sedentary",
    gentle = "gentle",
    moderate = "moderate",
    vigorous = "vigorous"
  }

  export enum LifestyleDietEnum {
    vegan = "vegan",
    vegetarian = "vegetarian",
    pescatarian = "pescatarian",
    omnivore = "omnivore"
  }

  export enum SmokingProductsEnum {
    vape = "vape",
    ecigarette = "ecigarette",
    cigarette = "cigarette",
    nicotine_patch = "nicotine_patch"
  }

  export enum CigarettesPerDayEnum {
    gt_15_per_day = "gt_15_per_day",
    lte_15_per_day = "lte_15_per_day"
  }

  export enum RecreationalDrugsEnum {
    marijuana = "marijuana",
    cocaine = "cocaine",
    amphetamines = "amphetamines",
    methamphetamines = "methamphetamines",
    mephedrone = "mephedrone",
    hallucinogens = "hallucinogens",
    benzylpiperazine = "benzylpiperazine",
    bath_salts = "bath_salts"
  }

  export enum GenderEnum {
    female = "female",
    male = "male",
    nonbinary = "nonbinary",
  }

  export enum SexEnum {
    female = "female",
    male = "male",
    intersex = "intersex"
  }

  export enum BirthControlTypesEnum {
    pill = "pill",
    patch = "patch",
    cu_iud = "cu_iud",
    hormonal_iud = "hormonal_iud",
    implant = "implant",
    shot = "shot",
    ring = "ring",
    male_condom = "male_condom",
    diaphragm = "diaphragm",
    abstinence = "abstinence",
    pull_out_method = "pull_out_method"
  }

  export enum PillBrandsEnum {
    altavera = "altavera",
    alyacen_1_35 = "alyacen_1_35",
    alyacen_7_7_7 = "alyacen_7_7_7",
    amethia = "amethia",
    amethia_lo = "amethia_lo",
    amethyst = "amethyst",
    apri = "apri",
    aranelle = "aranelle",
    ashlyna = "ashlyna",
    aubra_eq = "aubra_eq",
    aurovela_fe_1_5_30 = "aurovela_fe_1_5_30",
    aurovela_fe_1_20 = "aurovela_fe_1_20",
    aviane = "aviane",
    azurette = "azurette",
    balziva = "balziva",
    beyaz = "beyaz",
    blisovi_24_fe = "blisovi_24_fe",
    blisovi_fe_1_5_30 = "blisovi_fe_1_5_30",
    blisovi_fe_1_20 = "blisovi_fe_1_20",
    briellyn = "briellyn",
    camila = "camila",
    camrese = "camrese",
    camrese_lo = "camrese_lo",
    caziant = "caziant",
    chateal = "chateal",
    cryselle = "cryselle",
    cyclafem_1_35 = "cyclafem_1_35",
    dasetta_1_35 = "dasetta_1_35",
    daysee = "daysee",
    depo_subq_provera = "depo_subq_provera",
    drospirenone_ethinyl_estradiol = "drospirenone_ethinyl_estradiol",
    elinest = "elinest",
    emoquette = "emoquette",
    enskyce = "enskyce",
    errin = "errin",
    estarylla = "estarylla",
    estrostep_fe = "estrostep_fe",
    falmina = "falmina",
    femynor = "femynor",
    generess_fe = "generess_fe",
    gianvi = "gianvi",
    hailey_24_fe = "hailey_24_fe",
    hailey_fe_1_5_30 = "hailey_fe_1_5_30",
    hailey_fe_1_20 = "hailey_fe_1_20",
    heather = "heather",
    isibloom = "isibloom",
    jolessa = "jolessa",
    juleber = "juleber",
    junel_1_5_30 = "junel_1_5_30",
    junel_1_20 = "junel_1_20",
    junel_fe_1_5_30 = "junel_fe_1_5_30",
    junel_fe_1_20 = "junel_fe_1_20",
    junel_fe_24 = "junel_fe_24",
    kariva = "kariva",
    kelnor_1_35 = "kelnor_1_35",
    kurvelo = "kurvelo",
    larin_1_5_30 = "larin_1_5_30",
    larin_1_20 = "larin_1_20",
    larin_24_fe = "larin_24_fe",
    larin_fe_1_5_30 = "larin_fe_1_5_30",
    larin_fe_1_20 = "larin_fe_1_20",
    larissia = "larissia",
    leena = "leena",
    lessina = "lessina",
    levonest = "levonest",
    levora = "levora",
    lillow = "lillow",
    lo_loestrin_fe = "lo_loestrin_fe",
    lo_zumandimine = "lo_zumandimine",
    loestrin_1_5_30 = "loestrin_1_5_30",
    loestrin_1_20 = "loestrin_1_20",
    loestrin_fe_1_5_30 = "loestrin_fe_1_5_30",
    loestrin_fe_1_20 = "loestrin_fe_1_20",
    loryna = "loryna",
    loseasonique = "loseasonique",
    low_ogestrel = "low_ogestrel",
    lutera = "lutera",
    lybrel = "lybrel",
    marlissa = "marlissa",
    melodetta_24_fe = "melodetta_24_fe",
    microgestin_1_5_30 = "microgestin_1_5_30",
    microgestin_1_20 = "microgestin_1_20",
    microgestin_fe_1_5_30 = "microgestin_fe_1_5_30",
    microgestin_fe_1_20 = "microgestin_fe_1_20",
    mili = "mili",
    minastrin_24_fe = "minastrin_24_fe",
    mono_linyah = "mono_linyah",
    mononessa = "mononessa",
    natazia = "natazia",
    necon_0_5_35 = "necon_0_5_35",
    nikki = "nikki",
    nora_be = "nora_be",
    norethindrone = "norethindrone",
    norethindrone_ethinyl_estradiol_fe = "norethindrone_ethinyl_estradiol_fe",
    norgestimate_ethinyl_estradiol = "norgestimate_ethinyl_estradiol",
    norlyda = "norlyda",
    nortrel_0_5_35 = "nortrel_0_5_35",
    nortrel_1_35 = "nortrel_1_35",
    nortrel_7_7_7 = "nortrel_7_7_7",
    ocella = "ocella",
    orsythia = "orsythia",
    ortho_cyclen = "ortho_cyclen",
    ortho_tri_cyclen = "ortho_tri_cyclen",
    ortho_micronor = "ortho_micronor",
    philith = "philith",
    pimtrea = "pimtrea",
    portia = "portia",
    previfem = "previfem",
    quasense = "quasense",
    reclipsen = "reclipsen",
    safyral = "safyral",
    seasonale = "seasonale",
    seasonique = "seasonique",
    setlakin = "setlakin",
    sharobel = "sharobel",
    simpesse = "simpesse",
    sprintec = "sprintec",
    sronyx = "sronyx",
    syeda = "syeda",
    tarina_fe_24 = "tarina_fe_24",
    taytulla = "taytulla",
    tri_estarylla = "tri_estarylla",
    tri_lo_estarylla = "tri_lo_estarylla",
    tri_lo_marzia = "tri_lo_marzia",
    tri_lo_mili = "tri_lo_mili",
    tri_lo_sprintec = "tri_lo_sprintec",
    tri_previfem = "tri_previfem",
    tri_linyah = "tri_linyah",
    tri_sprintec = "tri_sprintec",
    tri_vylibra = "tri_vylibra",
    trilegest_fe = "trilegest_fe",
    trinessa = "trinessa",
    trivora = "trivora",
    tulana = "tulana",
    velivet = "velivet",
    vestura = "vestura",
    vienva = "vienva",
    viorele = "viorele",
    volnea = "volnea",
    vylibra = "vylibra",
    wera = "wera",
    wymzya_fe = "wymzya_fe",
    yasmin = "yasmin",
    yaz = "yaz",
    zovia_1_35 = "zovia_1_35",
    zumandimine = "zumandimine"
  }

  export enum PatchBrandsEnum {
    xulane = "xulane",
    twirla = "twirla"
  }

  export enum IudBrandsEnum {
    paragard_iud = "paragard_iud",
    mirena_iud = "mirena_iud",
    kyleena_iud = "kyleena_iud",
    liletta_iud = "liletta_iud",
    skyla_iud = "skyla_iud"
  }

  export enum ImplantBrandsEnum {
    implanon = "implanon",
    nexplanon = "nexplanon"
  }

  export enum InjectionBrandsEnum {
    depo_provera = "depo_provera",
    depo_subq_provera_104 = "depo_subq_provera_104"
  }

  export enum RingBrandsEnum {
    annovera = "annovera",
    nuvaring = "nuvaring",
    eluryng = "eluryng"
  }

  export enum BirthControlPreferencesEnum {
    low_maintenance = "low_maintenance",
    daily_regimen = "daily_regimen",
    no_preferences = "no_preferences"
  }
}