import { IntakeData } from "./support/intake_data";

const sample_datasets = {
  "c7820ea5-92d2-451d-8a6a-95f80d357b76": {
    "unique_id": "c7820ea5-92d2-451d-8a6a-95f80d357b76",
    "name": "Kate Buster Hawthorn",
    "pregnant": false,
    "breastfeeding": false,
    "days_post_partum": 0,
    "c_section": false,
    "planning_pregnancy": false,
    "gender": "female",
    "sex": "female",
    "date_of_birth": "2001-05-08",
    "ethnicity": "White",
    "height": 63,
    "weight": 130,
    "first_menstruation": 14,
    "sexual_activity": true,
    "previous_abortion": false,
    "abortion_trimester": null,
    "symptoms_of_septic_abortion": null,
    "recent_blood_pressure": "107/70",
    "previous_birth_control": true,
    "previous_birth_control_methods": ['pill', 'male condom', 'pull-out method'],
    "previous_birth_control_details": 3,
    "pill_usage_information": [
      {
        "start_date": "2023-02-24",
        "stop_date": null,
        "stop_reason": null,
        "brand": "levora"
      }
    ],
    "patch_usage_information": [],
    "iud_usage_information": [],
    "implant_usage_information": [],
    "injection_usage_information": [],
    "ring_usage_information": [],
    "current_medications": ['Ritalin', 'Seroquel'],
    "current_supplements": [],
    "current_immunizations": [],
    "allergies": ['Seasonal to grass'],
    "birth_control_goals": ["menstrual_cramps", "irregular_periods", "pregnancy"],
    "menstrual_cramps_severity": 2,
    "menstrual_cramps_past_treatments": true,
    "menstrual_cramps_challenges": "none",
    "acne_severity": 0,
    "acne_past_treatments": null,
    "acne_challenges": "",
    "hormonal_imbalance_condition": null,
    "hormonal_imbalance_treatment": null,
    "hormonal_imbalance_challenges": "",
    "irregular_periods_severity": "off_few_days",
    "irregular_periods_consultation": true,
    "irregular_periods_challenges": "Just not being able to predict them",
    "bariatric_surgery": "no",
    "atherosclerotic_cv_disease": false,
    "hypertension": "no",
    "hypertension_bp": "systolic_lte_130_diastolic_lte_80",
    "high_bp_pregnancy": null,
    "dvt_pe": "no",
    "thrombogenic_mutations": false,
    "superficial_venous_disorders": "no",
    "ischemic_heart_disease": false,
    "stroke": false,
    "valvular_heart_disease": "no",
    "valvular_heart_disease_complications": "",
    "peripartum_cardiomyopathy": "no",
    "peripartum_cardiomyopathy_duration": null,
    "rheumatic_diseases": [],
    "other_rheumatic_disease": "",
    "rheumatic_diseases_sle_follow_up": null,
    "rheumatic_diseases_ra_follow_up": null,
    "neurologic_conditions": ["headaches", "migranes_with_aura"],
    "neurologic_conditions_follow_up": null,
    "mood_disorders": ["bipolar_disorder"],
    "mood_disorders_undiagnosed": [],
    "other_mood_disorder": "",
    "reproductive_tract_infections": [],
    "cancer": null,
    "hiv": false,
    "infections": [],
    "endocrine_conditions": [],
    "diabetes_follow_up": null,
    "gi_conditions": [],
    "gi_conditions_gallbladder_follow_up": null,
    "gi_conditions_viral_hepatitis_follow_up": null,
    "gi_conditions_liver_tumors_follow_up": null,
    "cystic_fibrosis": false,
    "anemias": [],
    "solid_organ_transplantation": false,
    "solid_organ_transplantation_follow_up": null,
    "birth_control_concerns": ["effectiveness", "side_effects"],
    "family_history": {
      "cardio": false,
      "htn": true,
      "diabetes": false,
      "cancer": false,
      "stroke": false,
      "thyroid_disorders": false,
      "mental_health": false,
      "thrombosis": false,
    },
    "lifestyle_activity": "moderate",
    "lifestyle_diet": "omnivore",
    "lifestyle_smoking": false,
    "lifestyle_smoking_follow_up": null,
    "lifestyle_smoking_products_cigarettes_follow_up": null,
    "rec_drugs_list": [],
    "birth_control_preferences": "low_maintenance",
    "lab_details": {
      "lab_results": {
        "dhea": {
          results: "360 µg/dL",
          flags: "-",
        },
        "testosterone": {
          results: "50 ng/dL",
          flags: "-",
        },
        "tsh_r_t4": {
          results: "3.2 mU/L",
          flags: "-",
        },
        "amh": {
          results: "6 ng/mL",
          flags: "-",
        },
        "e2": {
          results: "140 pg/mL",
          flags: "-",
        },
        "hgc": {
          results: "<5 mIU/mL",
          flags: "-",
        },
        "ast_alt": {
          results: "AST 20  ALT 25",
          flags: "-",
        },
        "prolactin": {
          results: "20 µg/L",
          flags: "-",
        },
        "glucose_hemoglobin_a1c": {
          results: "5.00%",
          flags: "-",
        },
        "cbc": {
          results: [
            "RBC: 4.16 x 10^12 /L",
            "Hgb: 13 g/dL",
            "Hct: 40%",
            "WBC: 5 x10^9 /L",
            "Plt: 230 x10^9 /L",
          ],
          flags: "-",
        },
        "fsh": {
          results: "10.6 IU/L",
          flags: "-",
        },
        "lh": {
          results: "14 IU/L",
          flags: "-",
        },
        "ldl_hdl_tg": {
          results: [
            "LDL: 60",
            "HDL: 80",
            "TG: 120",
          ],
          flags: [
            "LDL normal",
            "HDL elevated",
            "TG normal",
          ],
        },
      },
    }
  },
  "e1cd448b-d257-4b57-b13f-bd162bbedebb": {
    "unique_id": "e1cd448b-d257-4b57-b13f-bd162bbedebb",
    "name": "Charlie Efe",
    "pregnant": false,
    "breastfeeding": false,
    "days_post_partum": 0,
    "c_section": false,
    "planning_pregnancy": false,
    "gender": "female",
    "sex": "female",
    "date_of_birth": "1993-04-12",
    "ethnicity": "Black",
    "height": 70,
    "weight": 145,
    "first_menstruation": 12,
    "sexual_activity": true,
    "previous_abortion": false,
    "abortion_trimester": null,
    "symptoms_of_septic_abortion": null,
    "recent_blood_pressure": "125/80",
    "previous_birth_control": true,
    "previous_birth_control_methods": ['pill', 'shot'],
    "previous_birth_control_details": 2,
    "pill_usage_information": [
      {
        "start_date": "2020-06-21",
        "stop_date": "2020-08-01",
        "stop_reason": "Mood swings, nausea and vomiting",
        "brand": "yaz"
      }
    ],
    "patch_usage_information": [],
    "iud_usage_information": [],
    "implant_usage_information": [],
    "injection_usage_information": [
      {
        "start_date": "2020-08-01",
        "stop_date": "2021-06-01",
        "stop_reason": "Change in insurance and couldn't see doctor",
        "brand": "depo_provera"
      }
    ],
    "ring_usage_information": [],
    "current_medications": ['Thiamazole'],
    "current_supplements": [],
    "current_immunizations": ['COVID-19 Vaccine'],
    "allergies": ['Penicillin'],
    "birth_control_goals": ["pregnancy"],
    "menstrual_cramps_severity": 0,
    "menstrual_cramps_past_treatments": false,
    "menstrual_cramps_challenges": "",
    "acne_severity": 0,
    "acne_past_treatments": null,
    "acne_challenges": "",
    "hormonal_imbalance_condition": null,
    "hormonal_imbalance_treatment": null,
    "hormonal_imbalance_challenges": "",
    "irregular_periods_severity": null,
    "irregular_periods_consultation": null,
    "irregular_periods_challenges": "",
    "bariatric_surgery": "no",
    "atherosclerotic_cv_disease": false,
    "hypertension": "no",
    "hypertension_bp": "systolic_lte_130_diastolic_lte_80",
    "high_bp_pregnancy": null,
    "dvt_pe": "no",
    "thrombogenic_mutations": false,
    "superficial_venous_disorders": "no",
    "ischemic_heart_disease": false,
    "stroke": false,
    "valvular_heart_disease": "no",
    "valvular_heart_disease_complications": "",
    "peripartum_cardiomyopathy": "no",
    "peripartum_cardiomyopathy_duration": null,
    "rheumatic_diseases": [],
    "other_rheumatic_disease": "",
    "rheumatic_diseases_sle_follow_up": null,
    "rheumatic_diseases_ra_follow_up": null,
    "neurologic_conditions": ["headaches"],
    "neurologic_conditions_follow_up": null,
    "mood_disorders": [],
    "mood_disorders_undiagnosed": [],
    "other_mood_disorder": "",
    "reproductive_tract_infections": [],
    "cancer": null,
    "hiv": false,
    "infections": [],
    "endocrine_conditions": ["hyperthyroidism"],
    "diabetes_follow_up": null,
    "gi_conditions": [],
    "gi_conditions_gallbladder_follow_up": null,
    "gi_conditions_viral_hepatitis_follow_up": null,
    "gi_conditions_liver_tumors_follow_up": null,
    "cystic_fibrosis": false,
    "anemias": [],
    "solid_organ_transplantation": false,
    "solid_organ_transplantation_follow_up": null,
    "birth_control_concerns": ["convenience", "cost"],
    "family_history": {
      "cardio": false,
      "htn": false,
      "diabetes": false,
      "cancer": false,
      "stroke": false,
      "thyroid_disorders": true,
      "mental_health": true,
      "thrombosis": false,
    },
    "lifestyle_activity": "sedentary",
    "lifestyle_diet": "vegetarian",
    "lifestyle_smoking": false,
    "lifestyle_smoking_follow_up": null,
    "lifestyle_smoking_products_cigarettes_follow_up": null,
    "rec_drugs_list": [],
    "birth_control_preferences": "no_preferences",
    "lab_details": {
      "lab_results": {
        "dhea": {
          results: "300 µg/dL",
          flags: "-",
        },
        "testosterone": {
          results: "40 ng/dL",
          flags: "-",
        },
        "tsh_r_t4": {
          results: "2.76 mU/L",
          flags: ">2.686",
        },
        "amh": {
          results: "4.2 ng/ml",
          flags: "-",
        },
        "e2": {
          results: "351 pg/mL",
          flags: "E2 - elevated",
        },
        "hgc": {
          results: "0.00 mIU/mL",
          flags: "-",
        },
        "ast_alt": {
          results: "AST 33  ALT 31",
          flags: "-",
        },
        "prolactin": {
          results: "13 µg/L",
          flags: "-",
        },
        "glucose_hemoglobin_a1c": {
          results: "3.10%",
          flags: "-",
        },
        "cbc": {
          results: [
            "RBC: 4.16 x 10^12 /L",
            "Hgb: 13 g/dL",
            "Hct: 40%",
            "WBC: 5 x10^9 /L",
            "Plt: 230 x10^9 /L",
          ],
          flags: "-",
        },
        "fsh": {
          results: "18.4 IU/L",
          flags: "-",
        },
        "lh": {
          results: "20 IU/L",
          flags: "-",
        },
        "ldl_hdl_tg": {
          results: [
            "LDL: 120",
            "HDL: 40",
            "TG: 160",
          ],
          flags: [
            "LDL elevated",
            "HDL elevated",
            "TG elevated",
          ],
        },
      },
    }
  },
  "16e62542-badf-4a28-92c1-da030a5c79c2": {
    "unique_id": "16e62542-badf-4a28-92c1-da030a5c79c2",
    "name": "Larissa Foster",
    "pregnant": true,
    "breastfeeding": false,
    "days_post_partum": 0,
    "c_section": false,
    "planning_pregnancy": false,
    "gender": "nonbinary",
    "sex": "female",
    "date_of_birth": "1997-04-12",
    "ethnicity": "White",
    "height": 67,
    "weight": 160,
    "first_menstruation": 12,
    "sexual_activity": true,
    "previous_abortion": true,
    "abortion_trimester": "first",
    "symptoms_of_septic_abortion": false,
    "recent_blood_pressure": "126/82",
    "previous_birth_control": true,
    "previous_birth_control_methods": ['pill', 'IUD', 'male condom', 'pull-out method'],
    "previous_birth_control_details": 2,
    "pill_usage_information": [
      {
        "start_date": "2010-01-01",
        "stop_date": "2016-01-01",
        "stop_reason": "Forgot to refill and didn't have a doctor",
        "brand": "ortho_tri_cyclen"
      }
    ],
    "patch_usage_information": [],
    "iud_usage_information": [
      {
        "start_date": "2018-04-01",
        "stop_date": null,
        "stop_reason": null,
        "brand": "mirena_iud"
      }
    ],
    "implant_usage_information": [],
    "injection_usage_information": [],
    "ring_usage_information": [],

    "current_medications": [],
    "current_supplements": ['Vitamin C', 'one-a-day', 'melatonin'],
    "current_immunizations": ['COVID-19 vaccine'],
    "allergies": [],
    "birth_control_goals": ["acne"],
    "menstrual_cramps_severity": 0,
    "menstrual_cramps_past_treatments": false,
    "menstrual_cramps_challenges": "",
    "acne_severity": 7,
    "acne_past_treatments": true,
    "acne_challenges": "Reduce breakouts and scarring",
    "hormonal_imbalance_condition": null,
    "hormonal_imbalance_treatment": null,
    "hormonal_imbalance_challenges": "",
    "irregular_periods_severity": null,
    "irregular_periods_consultation": null,
    "irregular_periods_challenges": "",
    "bariatric_surgery": "no",
    "atherosclerotic_cv_disease": false,
    "hypertension": "no",
    "hypertension_bp": "systolic_lte_130_diastolic_lte_80",
    "high_bp_pregnancy": null,
    "dvt_pe": "no",
    "thrombogenic_mutations": false,
    "superficial_venous_disorders": "no",
    "ischemic_heart_disease": false,
    "stroke": false,
    "valvular_heart_disease": "no",
    "valvular_heart_disease_complications": "",
    "peripartum_cardiomyopathy": "no",
    "peripartum_cardiomyopathy_duration": null,
    "rheumatic_diseases": [],
    "other_rheumatic_disease": "",
    "rheumatic_diseases_sle_follow_up": null,
    "rheumatic_diseases_ra_follow_up": null,
    "neurologic_conditions": ["headaches"],
    "neurologic_conditions_follow_up": null,
    "mood_disorders": [],
    "mood_disorders_undiagnosed": ["seasonal_affective_disorder"],
    "other_mood_disorder": "",
    "reproductive_tract_infections": [],
    "cancer": null,
    "hiv": false,
    "infections": [],
    "endocrine_conditions": [],
    "diabetes_follow_up": null,
    "gi_conditions": [],
    "gi_conditions_gallbladder_follow_up": null,
    "gi_conditions_viral_hepatitis_follow_up": null,
    "gi_conditions_liver_tumors_follow_up": null,
    "cystic_fibrosis": false,
    "anemias": [],
    "solid_organ_transplantation": false,
    "solid_organ_transplantation_follow_up": null,
    "birth_control_concerns": [],
    "family_history": {
      "cardio": false,
      "htn": false,
      "diabetes": false,
      "cancer": false,
      "stroke": false,
      "thyroid_disorders": false,
      "mental_health": false,
      "thrombosis": false,
    },
    "lifestyle_activity": "vigorous",
    "lifestyle_diet": "pescatarian",
    "lifestyle_smoking": false,
    "lifestyle_smoking_follow_up": null,
    "lifestyle_smoking_products_cigarettes_follow_up": null,
    "rec_drugs_list": ["marijuana"],
    "birth_control_preferences": "no_preferences",
    "lab_details": {
      "lab_results": {
        "dhea": {
          results: "280 µg/dL",
          flags: "-",
        },
        "testosterone": {
          results: "43 ng/dL",
          flags: "-",
        },
        "tsh_r_t4": {
          results: "3.3 mU/L",
          flags: "-",
        },
        "amh": {
          results: "4.3 ng/ml",
          flags: "-",
        },
        "e2": {
          results: "152 pg/mL",
          flags: "-",
        },
        "hgc": {
          results: "<5 mIU/mL",
          flags: "-",
        },
        "ast_alt": {
          results: "AST 22  ALT 30",
          flags: "-",
        },
        "prolactin": {
          results: "16 µg/L",
          flags: "-",
        },
        "glucose_hemoglobin_a1c": {
          results: "4.10%",
          flags: "-",
        },
        "cbc": {
          results: [
            "RBC: 4.16 x 10^12 /L",
            "Hgb: 13 g/dL",
            "Hct: 40%",
            "WBC: 5 x10^9 /L",
            "Plt: 230 x10^9 /L",
          ],
          flags: "-",
        },
        "fsh": {
          results: "18.4 IU/L",
          flags: "-",
        },
        "lh": {
          results: "15 IU/L",
          flags: "-",
        },
        "ldl_hdl_tg": {
          results: [
            "LDL: 45",
            "HDL: 80",
            "TG: 90",
          ],
          flags: [
            "LDL normal ",
            "HDL normal",
            "TG normal",
          ],
        },
      },
    }
  },
  "7157824b-0029-4e1b-af7e-3d3657341b76": {
    "unique_id": "7157824b-0029-4e1b-af7e-3d3657341b76",
    "name": "Justina Villa",
    "pregnant": false,
    "breastfeeding": false,
    "days_post_partum": 0,
    "c_section": false,
    "planning_pregnancy": true,
    "gender": "female",
    "sex": "female",
    "date_of_birth": "1998-07-31",
    "ethnicity": "Latina",
    "height": 70,
    "weight": 155,
    "first_menstruation": 10,
    "sexual_activity": true,
    "previous_abortion": false,
    "abortion_trimester": null,
    "symptoms_of_septic_abortion": null,
    "recent_blood_pressure": "122/73",
    "previous_birth_control": true,
    "previous_birth_control_methods": ['pill', 'male condom', 'pull-out method'],
    "previous_birth_control_details": 2,
    "pill_usage_information": [
      {
        "start_date": "2021-03-15",
        "stop_date": null,
        "stop_reason": null,
        "brand": "Amethia"
      }
    ],
    "patch_usage_information": [],
    "iud_usage_information": [],
    "implant_usage_information": [],
    "injection_usage_information": [],
    "ring_usage_information": [],
    "current_medications": [],
    "current_supplements": ['Ashwaganda'],
    "current_immunizations": ['Flu shot', 'COVID-19 Vaccine', 'Chickenpox booster'],
    "allergies": ['Dogs', 'latex'],
    "birth_control_goals": ["pregnancy"],
    "menstrual_cramps_severity": 0,
    "menstrual_cramps_past_treatments": false,
    "menstrual_cramps_challenges": "",
    "acne_severity": 0,
    "acne_past_treatments": null,
    "acne_challenges": "",
    "hormonal_imbalance_condition": false,
    "hormonal_imbalance_treatment": false,
    "hormonal_imbalance_challenges": "",
    "irregular_periods_severity": null,
    "irregular_periods_consultation": null,
    "irregular_periods_challenges": "",
    "bariatric_surgery": "no",
    "atherosclerotic_cv_disease": false,
    "hypertension": "no",
    "hypertension_bp": "systolic_lte_130_diastolic_lte_80",
    "high_bp_pregnancy": null,
    "dvt_pe": "no",
    "thrombogenic_mutations": true,
    "superficial_venous_disorders": "no",
    "ischemic_heart_disease": false,
    "stroke": false,
    "valvular_heart_disease": "no",
    "valvular_heart_disease_complications": "",
    "peripartum_cardiomyopathy": "no",
    "peripartum_cardiomyopathy_duration": null,
    "rheumatic_diseases": [],
    "other_rheumatic_disease": "",
    "rheumatic_diseases_sle_follow_up": null,
    "rheumatic_diseases_ra_follow_up": null,
    "neurologic_conditions": [],
    "neurologic_conditions_follow_up": null,
    "mood_disorders": ["major_depressive_disorder"],
    "mood_disorders_undiagnosed": [],
    "other_mood_disorder": "",
    "reproductive_tract_infections": ["heavy_periods"],
    "cancer": null,
    "hiv": false,
    "infections": [],
    "endocrine_conditions": [],
    "diabetes_follow_up": null,
    "gi_conditions": [],
    "gi_conditions_gallbladder_follow_up": null,
    "gi_conditions_viral_hepatitis_follow_up": null,
    "gi_conditions_liver_tumors_follow_up": null,
    "cystic_fibrosis": false,
    "anemias": [],
    "solid_organ_transplantation": false,
    "solid_organ_transplantation_follow_up": null,
    "birth_control_concerns": ["effectiveness"],
    "family_history": {
      "cardio": false,
      "htn": false,
      "diabetes": false,
      "cancer": false,
      "stroke": false,
      "thyroid_disorders": false,
      "mental_health": true,
      "thrombosis": false,
    },
    "lifestyle_activity": "gentle",
    "lifestyle_diet": "omnivore",
    "lifestyle_smoking": false,
    "lifestyle_smoking_follow_up": null,
    "lifestyle_smoking_products_cigarettes_follow_up": null,
    "rec_drugs_list": [],
    "birth_control_preferences": "no_preferences",
    "lab_details": {
      "lab_results": {
        "dhea": {
          results: "70 µg/dL",
          flags: "-",
        },
        "testosterone": {
          results: "45 ng/dL",
          flags: "-",
        },
        "tsh_r_t4": {
          results: "4.8 mU/L",
          flags: "TSH - elevated and TSH >2.686",
        },
        "amh": {
          results: "4.8 ng/ml",
          flags: "-",
        },
        "e2": {
          results: "350 pg/mL",
          flags: "-",
        },
        "hgc": {
          results: "<5 mIU/mL",
          flags: "-",
        },
        "ast_alt": {
          results: "AST 30  ALT 20",
          flags: "-",
        },
        "prolactin": {
          results: "16 µg/L",
          flags: "-",
        },
        "glucose_hemoglobin_a1c": {
          results: "4.15%",
          flags: "-",
        },
        "cbc": {
          results: [
            "RBC: 4.16 x 10^12 /L",
            "Hgb: 13 g/dL",
            "Hct: 40%",
            "WBC: 5 x10^9 /L",
            "Plt: 230 x10^9 /L",
          ],
          flags: "-",
        },
        "fsh": {
          results: "18.0 IU/L",
          flags: "-",
        },
        "lh": {
          results: "19 IU/L",
          flags: "-",
        },
        "ldl_hdl_tg": {
          results: [
            "LDL: 50",
            "HDL: 80",
            "TG: 90",
          ],
          flags: [
            "LDL normal ",
            "HDL normal",
            "TG normal",
          ],
        },
      },
    }
  },
  "f5e3d12b-ef19-46d2-9a2f-247ae98a7506": {
    "unique_id": "f5e3d12b-ef19-46d2-9a2f-247ae98a7506",
    "name": "Vanessa Farley",
    "pregnant": false,
    "breastfeeding": false,
    "days_post_partum": 0,
    "c_section": false,
    "planning_pregnancy": true,
    "gender": "female",
    "sex": "female",
    "date_of_birth": "1999-06-12",
    "ethnicity": "Indian",
    "height": 68,
    "weight": 162,
    "first_menstruation": 12,
    "sexual_activity": true,
    "previous_abortion": false,
    "abortion_trimester": null,
    "symptoms_of_septic_abortion": null,
    "recent_blood_pressure": "113/76",
    "previous_birth_control": true,
    "previous_birth_control_methods": ['pill', 'male condom', 'pull-out method'],
    "previous_birth_control_details": 3,
    "pill_usage_information": [
      {
        "start_date": "2018-07-01",
        "stop_date": "2020-05-01",
        "stop_reason": "",
        "brand": "errin"
      }
    ],
    "patch_usage_information": [],
    "iud_usage_information": [],
    "implant_usage_information": [],
    "injection_usage_information": [],
    "ring_usage_information": [],
    "current_medications": [],
    "current_supplements": ['Multivitamin'],
    "current_immunizations": ['Flu shot', 'COVID-19 Vaccine'],
    "allergies": [],
    "birth_control_goals": ["irregular_periods", "pregnancy"],
    "menstrual_cramps_severity": 7,
    "menstrual_cramps_past_treatments": true,
    "menstrual_cramps_challenges": "Working up PCOS",
    "acne_severity": 0,
    "acne_past_treatments": null,
    "acne_challenges": "",
    "hormonal_imbalance_condition": false,
    "hormonal_imbalance_treatment": false,
    "hormonal_imbalance_challenges": "",
    "irregular_periods_severity": "completely_unpredictable",
    "irregular_periods_consultation": true,
    "irregular_periods_challenges": "They're very unpredictable both flow and timing",
    "bariatric_surgery": "no",
    "atherosclerotic_cv_disease": false,
    "hypertension": "no",
    "hypertension_bp": "systolic_lte_130_diastolic_lte_80",
    "high_bp_pregnancy": null,
    "dvt_pe": "no",
    "thrombogenic_mutations": false,
    "superficial_venous_disorders": "no",
    "ischemic_heart_disease": false,
    "stroke": false,
    "valvular_heart_disease": "no",
    "valvular_heart_disease_complications": "",
    "peripartum_cardiomyopathy": "no",
    "peripartum_cardiomyopathy_duration": null,
    "rheumatic_diseases": [],
    "other_rheumatic_disease": "",
    "rheumatic_diseases_sle_follow_up": null,
    "rheumatic_diseases_ra_follow_up": null,
    "neurologic_conditions": [],
    "neurologic_conditions_follow_up": null,
    "mood_disorders": ["anxiety", "post_traumatic_stress_disorder"],
    "mood_disorders_undiagnosed": [],
    "other_mood_disorder": "",
    "reproductive_tract_infections": [],
    "cancer": null,
    "hiv": false,
    "infections": [],
    "endocrine_conditions": [],
    "diabetes_follow_up": null,
    "gi_conditions": [],
    "gi_conditions_gallbladder_follow_up": null,
    "gi_conditions_viral_hepatitis_follow_up": null,
    "gi_conditions_liver_tumors_follow_up": null,
    "cystic_fibrosis": false,
    "anemias": [],
    "solid_organ_transplantation": false,
    "solid_organ_transplantation_follow_up": null,
    "birth_control_concerns": ["effectiveness"],
    "family_history": {
      "cardio": true,
      "htn": false,
      "diabetes": false,
      "cancer": false,
      "stroke": false,
      "thyroid_disorders": false,
      "mental_health": false,
      "thrombosis": false,
    },
    "lifestyle_activity": "moderate",
    "lifestyle_diet": "omnivore",
    "lifestyle_smoking": false,
    "lifestyle_smoking_follow_up": null,
    "lifestyle_smoking_products_cigarettes_follow_up": null,
    "rec_drugs_list": ["marijuana"],
    "birth_control_preferences": "no_preferences",
    "lab_details": {
      "lab_results": {
        "dhea": {
          results: "390 µg/dL",
          flags: "DHEA - elevated",
        },
        "testosterone": {
          results: "80 ng/dL",
          flags: "Testosterone - elevated",
        },
        "tsh_r_t4": {
          results: "4.2 mU/L",
          flags: "TSH >2.686",
        },
        "amh": {
          results: "7 ng/mL",
          flags: "AMH - elevated",
        },
        "e2": {
          results: "360 pg/mL",
          flags: "E2 - elevated",
        },
        "hgc": {
          results: "<5 mIU/mL",
          flags: "-",
        },
        "ast_alt": {
          results: "AST 30  ALT 29",
          flags: "-",
        },
        "prolactin": {
          results: "30 µg/L",
          flags: "Prolactin - Elevated",
        },
        "glucose_hemoglobin_a1c": {
          results: "5.40%",
          flags: "-",
        },
        "cbc": {
          results: [
            "RBC: 4.16 x 10^12 /L",
            "Hgb: 13 g/dL",
            "Hct: 40%",
            "WBC: 5 x10^9 /L",
            "Plt: 230 x10^9 /L",
          ],
          flags: "-",
        },
        "fsh": {
          results: "16.0 IU/L",
          flags: "-",
        },
        "lh": {
          results: "28 IU/L",
          flags: "LH - elevated",
        },
        "ldl_hdl_tg": {
          results: [
            "LDL: 90",
            "HDL: 40",
            "TG: 154",
          ],
          flags: [
            "LDL normal ",
            "HDL normal",
            "TG elevated",
          ],
        },
      },
    }
  },
}

const sample_recommendations = {
  "c7820ea5-92d2-451d-8a6a-95f80d357b76": {
    "name": "Kate",
    "age": "22",
    "gender": "Female",
    "location": "New York City",
    "goals": [
      "Reduce menstrual cramps",
      "Manage irregular periods",
      "Prevent pregnancy",
    ],
    medical_recommendations: [
      {
        m: "Option 1: Levonorgestrel Intrauterine Device (Mirena)",
        s: "Biomarker Analysis: LNG IUD primarily acts locally within the uterus with only a small amount of the released levonorgestrel, a type of progestin, absorbed into the bloodstream which has minimal to no systemic hormonal effect. Progestin can be used to regulate irregular menstrual cycles, reduce heavy menstrual bleeding, and alleviate menstrual cramps. LNG IUD does not contain estrogen which is beneficial to the patient as fluctuation in estrogen levels may trigger migraines with aura and irregular menstruation.",
      },
      {
        m: "Option 2: Progestin Implant (Nexplanon)",
        s: "Biomarker Analysis: Nexplanon has a progestin dose of 104 mg and does not contain estrogen which is beneficial to the patient as fluctuation in estrogen levels may trigger migraines with aura and irregular menstruation. Progestin can be used to regulate irregular menstrual cycles, reduce heavy menstrual bleeding, and alleviate menstrual cramps."
      },
      {
        m: "Option 3: Copper Intrauterine Device (ParaGard)",
        s: "Biomarker Analysis:  Copper IUD does not contain hormones like progestin or estrogen, instead, it releases copper ions into the uterine cavity. Non-hormonal contraception will be beneficial to prevent fluctuations in hormone levels thus reducing irregular menstruation, migraines, and mood swings."
      },
    ],
    side_effects: [
      "Mirena: Alterations of menstrual bleeding patterns, abdominal/pelvic pain, amenorrhea, headache/migraine, genital discharge, vulvovaginitis",
      "Nexplanon: Change in menstrual bleeding pattern, headache, vaginitis, weight increase, acne, breast pain, abdominal pain, and pharyngitis.",
      "ParaGard: Anemia, backache, dysmenorrhea, dyspareunia, expulsion (complete or partial), prolonged menstrual flow, menstrual spotting, pain and cramping, vaginitis",
    ],
    lifestyle_changes: [
      "Maintain a balanced diet rich in antioxidants, whole grains, and lean proteins to support hormonal balance and manage PCOS symptoms.",
      "Regular exercise, such as aerobic and strength training, can help regulate hormones and improve overall well-being.",
      "Omega-3 fatty acid supplements (1000 mg/day) may be considered to support skin health and reduce inflammation associated with acne.",
      "Avoid excessive consumption of saturated fats, salt, and caffeine which may influence menstrual cramps.",
    ],
    final_remarks: "Based on Kate's desire to reduce menstrual cramps, manage irregular periods, and prevent pregnancy, Levonorgestrel Intrauterine Device (Mirena) is the recommended option. Mirena is also low maintenance as a one time implant with a duration of 8 years. It has the potential to provide the desired outcomes while minimizing the risk of side effects. Regular monitoring of biomarkers and clinical follow-ups will be crucial to ensure Kate's well-being and the efficacy of the chosen birth control.",
  },
  "e1cd448b-d257-4b57-b13f-bd162bbedebb": {
    "name": "Charlie",
    "age": "30",
    "gender": "Female",
    "location": "New York City",
    "goals": [
      "Prevent Pregnancy",
    ],
    medical_recommendations: [
      {
        m: "Option 1: Progestin-Only Pill (Cerazette)",
        s: "Biomarker Analysis: With an estradiol level at 351 pg/mL is nearing the upper range could be triggering the headaches. Thus a progestin only pill would be beneficial. Estrogen increase thyroxine-binding globulin (TBG) levels which has a greater affinity to T4 and then TSH reacts to T4 levels. The TSH level of 2.76 mU/L puts the patient at higher risk of VTE according to research that shows TSH levels >2.686 puts patients at higher VTE risk. Considering the patient’s history of VTE, both of these factors increases her risk for VTE. ",
      },
      {
        m: "Option 2: Copper Intrauterine Device (ParaGard)",
        s: "Biomarker Analysis: Copper IUD does not contain hormones like progestin or estrogen, instead, it releases copper ions into the uterine cavity. Non-hormonal contraception is highly recommended in patients with high risk for recurrent VTE. Copper IUD would not contribute to hormone imbalances thus reducing estrogen fluctuation which will reduce symptomatic headaches."
      },
      {
        m: "Option 3: Progestin Implant (Nexplanon)",
        s: "Biomarker Analysis: Nexplanon has a progestin dose of 104 mg and does not contain estrogen which is beneficial to the patient as fluctuation in estrogen levels, currently elevated at 351 pg/mL may trigger headaches. "
      },
    ],
    side_effects: [
      "Cerazette: Anemia, backache, dysmenorrhea, dyspareunia, expulsion (complete or partial), prolonged menstrual flow, menstrual spotting, pain and cramping, vaginitis",
      "ParaGard: Anemia, backache, dysmenorrhea, dyspareunia, expulsion (complete or partial), prolonged menstrual flow, menstrual spotting, pain and cramping, vaginitis",
      "Nexplanon: Change in menstrual bleeding pattern, headache, vaginitis, weight increase, acne, breast pain, abdominal pain, and pharyngitis.",
    ],
    lifestyle_changes: [
      "Maintain a balanced diet rich in antioxidants, whole grains, and lean proteins to support hormonal balance ",
      "Regular exercise, such as aerobic and strength training, can help regulate hormones and improve overall well-being.",
      "Omega-3 fatty acid supplements (1000 mg/day) may be considered to support skin health and reduce inflammation associated with acne.",
      "To help with her vegetarian diet, supplements should be considered (ie protein, iron, vitamin B12, calcium, vitamin D3) ",
    ],
    final_remarks: "Based on Charlie's desire to prevent pregnancy, the progestin only pill is the recommended option. It has the potential to provide the desired outcomes while minimizing the risk of side effects. Regular monitoring of biomarkers and clinical follow-ups will be crucial to ensure Charlie's well-being and the efficacy of the chosen birth control.",
  },
  "16e62542-badf-4a28-92c1-da030a5c79c2": {
    "name": "Larissa",
    "age": "26",
    "gender": "Female",
    "location": "New York City",
    "goals": [
      "Treat acne",
    ],
    medical_recommendations: [
      {
        m: "Option 1: Yaz (Drospirenone/Ethinyl Estradiol)",
        s: "Biomarker Analysis: DHEA (280 µg/dL) and testosterone (43 ng/dL) levels are expected to decrease by 30%, leading to a 40% reduction in acne and 25% decrease in excess hair growth. This could also contribute to a 20% lower risk of mood fluctuations. ",
      },
      {
        m: "Option 2: Ortho Tri-Cyclen (Norgestimate/Ethinyl Estradiol)",
        s: "Biomarker Analysis: Ortho Tri-Cyclen is known for its potential to improve hormonal balance. It may have a variable impact on DHEA and testosterone levels (15-25% decrease), leading to a 25% improvement in acne and 15% reduction in excess hair growth. However, there is a 30% risk of mood fluctuations and 20% risk of mild weight fluctuation."
      },
      {
        m: "Option 3: Copper Intrauterine Device (ParaGard)",
        s: "Biomarker Analysis: Copper IUD does not contain hormones like progestin or estrogen, instead, it releases copper ions into the uterine cavity. Non-hormonal contraception is will not contribute to hormone fluctuation thus improving acne and without estrogen fluctuation Copper IUD will reduce symptomatic headaches."
      },
    ],
    side_effects: [
      "Yaz: Low risk of mood fluctuations, very low risk of weight fluctuation.",
      "Ortho Tri-Cyclen: Moderate risk of mood fluctuations, low risk of mild weight fluctuation.",
      "ParaGard: Anemia, backache, dysmenorrhea, dyspareunia, expulsion (complete or partial), prolonged menstrual flow, menstrual spotting, pain and cramping, vaginitis",
    ],
    lifestyle_changes: [
      "Maintain a balanced diet rich in antioxidants, whole grains, and lean proteins to support hormonal balance ",
      "Regular exercise, such as aerobic and strength training, can help regulate hormones and improve overall well-being.",
      "Omega-3 fatty acid supplements (1000 mg/day) may be considered to support skin health and reduce inflammation associated with acne.",
      "Avoid excessive consumption of refined sugars and dairy products, as they might exacerbate acne",
    ],
    final_remarks: "Based on Larissa's desire to manage acne, Yaz (Drospirenone/Ethinyl Estradiol) is the recommended option. It has the potential to provide the desired outcomes while minimizing the risk of side effects. Regular monitoring of biomarkers and clinical follow-ups will be crucial to ensure Larissa's well-being and the efficacy of the chosen birth control.",
  },
  "7157824b-0029-4e1b-af7e-3d3657341b76": {
    "name": "Justina",
    "age": "25",
    "gender": "Female",
    "location": "New York City",
    "goals": [
      "Prevent pregnancy",
    ],
    medical_recommendations: [
      {
        m: "Option 1:  Copper Intrauterine Device (ParaGard) ",
        s: "Biomarker Analysis: The highly recommended contraception for a patient with thrombogenic mutations is Copper IUD. Copper IUD does not contain hormones like progestin or estrogen, instead, it releases copper ions into the uterine cavity. TSH levels are elevated at 4.8 mU/L which may contribute to mood swings  or the patient’s major depressive disorder. Non-hormonal contraception won’t contribute to fluctuations in hormone levels thus reducing irregular menstruation and mood swings. ",
      },
      {
        m: "Option 2: Progestin Implant (Nexplanon)",
        s: "Biomarker Analysis: Nexplanon has a progestin dose of 104 mg and does not contain estrogen which is beneficial to the patient as her current estrogen levels is at the upper range at 350 pg/mL. Nexplanon is also known to reduce menstrual flow, menstruation, and menstrual cramps. "
      },
      {
        m: "Option 3:  Progestin-Only Pill (Cerazette)",
        s: "Biomarker Analysis: With an estradiol level at 350 pg/mL is nearing the upper range, progestin only pill is recommended. Estrogen increase thyroxine-binding globulin (TBG) levels which has a greater affinity to T4 and then TSH reacts to T4 levels. The elevated TSH level of 4.8 mU/L puts the patient at higher risk of VTE according to research that shows TSH levels >2.686 puts patients at higher VTE risk. Considering the patient’s history of thrombogenic mutations and elevated TSH, both of these factors increases her risk for VTE."
      },
    ],
    side_effects: [
      "ParaGard: Anemia, backache, dysmenorrhea, dyspareunia, expulsion (complete or partial), prolonged menstrual flow, menstrual spotting, pain and cramping, vaginitis",
      "Nexplanon: Change in menstrual bleeding pattern, headache, vaginitis, weight increase, acne, breast pain, abdominal pain, and pharyngitis.",
      "Cerazette: Anemia, backache, dysmenorrhea, dyspareunia, expulsion (complete or partial), prolonged menstrual flow, menstrual spotting, pain and cramping, vaginitis",
    ],
    lifestyle_changes: [
      "Maintain a balanced diet rich in antioxidants, whole grains, and lean proteins to support hormonal balance",
      "Regular exercise, such as aerobic and strength training, can help regulate hormones and improve overall well-being.",
      "Avoid excessive consumption of saturated fats, salt, and caffeine which may influence heavy periods",
    ],
    final_remarks: "Based on Justina's desire to prevent pregnancy, ParaGard is the recommended option as it has more than 99% effective in preventing pregnancy and is recommended for patients with thrombogenic mutations as combined oral contraceptives are contraindicated with this mutation. ParaGard may also reduce heavy periods. It has the potential to provide the desired outcomes while minimizing the risk of side effects. Regular monitoring of biomarkers and clinical follow-ups will be crucial to ensure Justina's well-being and the efficacy of the chosen birth control.",
  },
  "f5e3d12b-ef19-46d2-9a2f-247ae98a7506": {
    "name": "Vanessa",
    "age": "24",
    "gender": "Female",
    "location": "New York City",
    "goals": [
      "Manage irregular periods ",
      "Prevent pregnancy",
    ],
    medical_recommendations: [
      {
        m: "Option 1: Yaz (Drospirenone/Ethinyl Estradiol)",
        s: "Biomarker Analysis: Biomarker levels are elevated and consistent with PCOS which the patient is undergoing testing for. Yaz is known for its anti-androgenic effects due to drospirenone, which could help manage PCOS symptoms. DHEA level is currently elevated at 390 µg/dL and testosterone levels elevated at 80 ng/dL are expected to decrease by 30%, leading to a 40% reduction in acne and 25% decrease in excess hair growth. This could also contribute to a 20% lower risk of mood fluctuations. Clinical research supports the anti-androgenic effects of drospirenone and its positive impact on biomarkers associated with PCOS.",
      },
      {
        m: "Option 2: Ortho Tri-Cyclen (Norgestimate/Ethinyl Estradiol)",
        s: "Biomarker Analysis: Ortho Tri-Cyclen is known for its potential to improve hormonal balance in PCOS patients. It may have a variable impact on DHEA (390 µg/dL) and testosterone (80 ng/dL) levels (15-25% decrease), leading to a 25% improvement in acne and 15% reduction in excess hair growth. However, there is a 30% risk of mood fluctuations and 20% risk of mild weight fluctuation. Clinical research indicates the efficacy of Ortho Tri-Cyclen in managing PCOS symptoms."
      },
      {
        m: "Option 3: Copper Intrauterine Device (ParaGard)",
        s: "Biomarker Analysis: Elevated DHEA (390 µg/dL), testosterone (80 ng/dL), AMH (7 ng/mL), prolactin (30 µg/L), and LH (28 IU/L) are consistent with PCOS which is characterized by hormonal fluctuations. Copper IUD does not contain hormones like progestin or estrogen, instead, it releases copper ions into the uterine cavity. Non-hormonal contraception won’t contribute to fluctuations in hormone levels thus reducing irregular menstruation and mood swings. It is best suited for patients who prefer not to use hormonal methods."
      },
    ],
    side_effects: [
      "Yaz: Low risk of mood fluctuations, very low risk of weight fluctuation.",
      "Ortho Tri-Cyclen: Moderate risk of mood fluctuations, low risk of mild weight fluctuation.",
      "ParaGard: Anemia, backache, dysmenorrhea, dyspareunia, expulsion (complete or partial), prolonged menstrual flow, menstrual spotting, pain and cramping, vaginitis",
    ],
    lifestyle_changes: [
      "Maintain a balanced diet rich in antioxidants, whole grains, and lean proteins to support hormonal balance and manage PCOS symptoms.",
      "Regular exercise, such as aerobic and strength training, can help regulate hormones and improve overall well-being.",
      "Avoid excessive consumption of refined sugars and dairy products, as they might PCOS symptoms.",
    ],
    final_remarks: "Based on Vanessa's desire to manage irregular periods and prevent pregnancy while possibly having PCOS , Yaz (Drospirenone/Ethinyl Estradiol) is the recommended option. It has the potential to provide the desired outcomes while minimizing the risk of side effects. Regular monitoring of biomarkers and clinical follow-ups will be crucial to ensure Vanessa's well-being and the efficacy of the chosen birth control.",
  },
}

export function getAllIntakeData() {
  return sample_datasets;
}

// TODO: defined return value
export function getIntakeData(intake_id: string) {
  if (sample_datasets[intake_id as keyof typeof sample_datasets]) {
    return new IntakeData(sample_datasets[intake_id as keyof typeof sample_datasets]);
  }
  return null;
}

export function getMedicalRecommendation(intake_id: string) {
  if (sample_recommendations[intake_id as keyof typeof sample_recommendations]) {
    return sample_recommendations[intake_id as keyof typeof sample_recommendations];
  }
  return null;
}