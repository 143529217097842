import { useEffect } from "react";

const PageTitle = (props: any) => {
  useEffect(() => {
    document.title = `Celeste - ${props.title}` || "Celeste";
  }, [props.title]);
  return props.children;
};

export default PageTitle;
