import { useState } from "react";
import { Button, Spinner, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useFetch } from "../../../../helpers/clerk";
import { getBackendUrl } from "../../../../utils";
import { PlusIcon } from "../../../../components/Icons";
import { InfoSquare } from "react-bootstrap-icons";

export default function Patients() {
    const [fetching, setFetching] = useState(false);
    const [patients, setPatients] = useState([] as any); // TODO: probably shouldn't be 'any', but i'm not typing this out right now
    const backend_request = useFetch();


    if (!fetching) {
        setFetching(true);
        backend_request(getBackendUrl(`/api/patients`), {
            method: 'GET',
        }).then((response) => {
            if (response.ok) {
                (async function (response: Response) {
                    const response_body = await response.json();
                    setPatients(response_body);
                })(response);
            }
        });
    }

    return (
        <>

            <div className="mb-2 clearfix">
                <div className="float-start">
                    <h1>Patients</h1>
                </div>
                <div className="float-end">
                    <Link className="btn btn-primary font-white" to="/d/providers/dashboard/patients/create"><PlusIcon classNames={["aligned"]} /> Add new Patient</Link>
                </div>
            </div>

            <div className="alert alert-primary d-flex align-items-center" role="alert">
                <InfoSquare className="flex-shrink-0 me-4" size={32} />
                <div>
                    Welcome to the ExactRx Demo, we are excited to inform you that we will soon be expanding our capabilities to include a wider range of medications. This enhancement is part of our commitment to continually evolve and provide comprehensive support for medication selection and patient care. Your participation in this demo is highly valued, and we encourage you to share your experiences and suggestions. Your feedback is crucial in helping us refine and improve our services. Please provide your feedback through <a href="https://celestehealth.typeform.com/to/CxeE0nKV" className="alert-link" target="_blank">this link</a>.
                </div>
            </div>

            <Table className="rounded-corners table-hover">
                <thead>
                    <tr>
                        <td>Patient</td>
                        <td></td>
                        <td></td>
                    </tr>
                </thead>
                <tbody>
                    {!fetching && <tr><Spinner /></tr>}
                    {fetching && patients.length > 0 && patients.map((patient: any) => {
                        return (
                            <tr key={`patient-row-${patient['email']}`}>
                                <td>{patient['email']}</td>
                                <td><Link to={`/d/providers/dashboard/patients/${patient['uuid']}`}>View</Link></td>
                                <td><Link to={`/d/providers/dashboard/patients/${patient['uuid']}/recommendations`}>Medication Recommendations</Link></td>
                            </tr>
                        );
                    })}
                </tbody>
            </Table>
        </>
    );
}