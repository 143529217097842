// @ts-nocheck
import { useState } from "react";
import { Alert, Row, Spinner } from "react-bootstrap";
import { useLoaderData } from "react-router-dom";
import { useFetch } from "../../../../helpers/clerk";
import { getBackendUrl } from "../../../../utils";

export type LoaderData = {
    patient_uuid: number,
}

export async function loader({ params }: { params: { patient_uuid?: string } }) {
    return { patient_uuid: params.patient_uuid };
}

export default function Recommendations() {
    // TODO: This entire file is just a proof of concept.
    // For the next iteration, the view, create, and modify pages
    // should use the same file but the difference will be if a patient_uuid is passed in.
    const data = useLoaderData() as LoaderData;
    const [loaded, setLoaded] = useState(false);
    const [errors, setErrors] = useState("");
    const [recommendation, setRecommendation] = useState({} as any); // TODO: probably shouldn't be 'any', but i'm not typing this out right now
    const backend_request = useFetch();

    if (!loaded) {
        backend_request(getBackendUrl(`/api/patients/get/${data.patient_uuid}/recommendations`), {
            method: 'GET',
        }).then((response) => {
            if (response.ok) {
                (async function (response: Response) {
                    const response_body = await response.json();
                    setRecommendation(response_body['recommendations']);
                    setLoaded(true);
                })(response);
            }
            else {
                setErrors("An unexpected error has occured.");
            }
        });
    }

    return (
        <>
            <h1>Celeste Medication Recommendation</h1>
            {errors && (
                <Row>
                    <Alert key={'danger'} variant={'danger'}>
                        {errors}
                    </Alert>
                </Row>
            )}
            {!loaded && <Spinner />}
            {loaded && (
                <>
                    <div className="rounded-corners p-4 mb-4">
                        <h2>Patient Safety Profile:</h2>
                        <ul>
                            {recommendation['patient']['safety_concerns'].map((item, index) => (
                                <li key={index}>{item}</li>
                            ))}
                        </ul>
                        <h2>This recommendation also considers:</h2>
                        <ul>
                            {recommendation['patient']['lifestyle_concerns'].map((item, index) => (
                                <li key={index}>{item}</li>
                            ))}
                        </ul>
                        <h2>Medication Recommendations:</h2>
                        {recommendation['recommendations'].map((recommendation, index) => (
                            <div key={index}>
                                <h3>Option {index + 1}: {recommendation['formula']}</h3>
                                <ul>
                                    {recommendation['lifestyle_benefits'].map((detail, detailIndex) => (
                                        <li key={detailIndex}>{detail}</li>
                                    ))}
                                </ul>
                            </div>
                        ))}

                        <h2>Due to safety concerns, we do not recommend the following options:</h2>
                        <ul>
                            {recommendation['do_not_recommend'].map((item, index) => (
                                <li key={index}> {item}</li>
                            ))}
                        </ul>

                        <h2>Citations:</h2>
                        <ul>
                            {recommendation['citations'].map((item, index) => (
                                <li key={index}>{index + 1}: {item}</li>
                            ))}
                        </ul>

                    </div>

                </>
            )}
        </>
    );
}