import Row from 'react-bootstrap/Row';
import Column from 'react-bootstrap/Col';
import { Container } from 'react-bootstrap';
import { Link, redirect, useLoaderData } from 'react-router-dom';

type LoaderData = {
  logo: string
  name: string
}

export async function loader() : Promise<LoaderData|Response> {
  return redirect("/d");

  // TODO: Return to this + tests when we're done with the demo.

  // const backend_url = getBackendUrl();
  // if (!backend_url) {
  //   throw new Response("", {
  //     status: 500,
  //     statusText: "Server Connection Error",
  //   });
  // }
  // const response = await fetch(new URL('/api/v1/questionnaire_settings', backend_url));
  // const { logo, name } = await response.json();
  // return { logo, name };
}

export default function Welcome() {
  const data = useLoaderData() as LoaderData;

  return (
    <>
      <Row>
        <Column xl={2}>
        </Column>
        <Column xl={8} className='text-start'>
          <Container className='p-5'>
            <Row>
              <Column xl={12}>
                Hey there! This is the team at
              </Column>
            </Row>
            <Row>
              <Column xl={12}>
                <img src={data.logo} className='img-fluid' alt={`${data.name} logo`} data-testid="partner-logo" />
              </Column>
            </Row>
            <Row>
              <Column xl={12}>
                We're here to get you the best personalized birth control! First things first, let's do a quick read-through of the fine print.
              </Column>
            </Row>
          </Container>
        </Column>
        <Column xl={2}>
        </Column>
      </Row>
      <Row>
        <Column className='d-inline-flex justify-content-center'>
          <Link className='btn btn-primary btn-lg' to='/legal/1'>Let's Go</Link>
        </Column>
      </Row>
    </>
  )
}